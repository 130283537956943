import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'

const ThemeSwitch = withStyles({
  switchBase: {
    color: '#064473',
    '&$checked': {
      color: '#064473'
    },
    '&$checked + $track': {
      backgroundColor: '#064473'
    }
  },
  checked: {},
  track: {}
})(Switch)

const DisbleSwitch = withStyles({
  switchBase: {
    color: '#808080',
    cursor: 'not-allowed',
    '&$checked': {
      color: '#808080'
    },
    '&$checked + $track': {
      backgroundColor: '#808080'
    }
  },
  checked: {},
  track: {}
})(Switch)
export default function CustomizedSwitches (props) {
  const { isChecked, data } = props

  const handleChange = (event) => {
    props.handleChange(event, data)
  }
  return (
    <FormGroup>
      {
        data && data.IsDisabled
          ? <DisbleSwitch checked={isChecked} name="switch"/>

          : <ThemeSwitch checked={isChecked} onChange={handleChange} name="switch" />
      }
    </FormGroup>
  )
}
