import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Chip,
  Tooltip,
  IconButton,
  Link
} from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import CheckCircle from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import EmailIcon from '@material-ui/icons/Email'
import VisibilityIcon from '@material-ui/icons/Visibility'
import SearchFilter from '../search-filter/searchFilter'
import AddBoxIcon from '@material-ui/icons/AddBox'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import './table.less'
import Status from '../../constants/Status'
import ModalPopup from '../modal-popup/ModalPopup'
import { UploadFileConstant, InvestorConstant, UserConstant, ScheduledK1Constant } from '../../constants/index'
import Switch from '../switch-toggle/switch'
import { IsSectionVisible } from '../utils/helper'
import { SECTIONS } from '../../constants/appConstants'
import Input from '@material-ui/core/Input'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import FileUpload from '../file-upload/fileUpload'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black
  }
}))

function descendingComparator (a, b, orderBy) {
  if (orderBy === ScheduledK1Constant.Name) {
    return a.Name !== null ? a.Name.trim().localeCompare(b.Name, 'en', { sensitivity: 'base' }) : -1
  }
  if (orderBy === ScheduledK1Constant.FiscalYear) {
    return a.FiscalYear !== null ? a.FiscalYear.trim().localeCompare(b.FiscalYear, 'en', { sensitivity: 'base' }) : -1
  }
  if (orderBy === ScheduledK1Constant.K1ReferenceName) {
    return a.K1ReferenceName !== null ? a.K1ReferenceName.trim().localeCompare(b.K1ReferenceName, 'en', { sensitivity: 'base' }) : -1
  }
  if (orderBy === ScheduledK1Constant.DocumentName) {
    return a.DocumentName !== null ? a.DocumentName.trim().localeCompare(b.DocumentName, 'en', { sensitivity: 'base' }) : -1
  }
  if (orderBy === ScheduledK1Constant.FileName) {
    return a.FileName !== null ? a.FileName.trim().localeCompare(b.FileName, 'en', { sensitivity: 'base' }) : -1
  }
  if (orderBy === ScheduledK1Constant.LastProcessed) {
    const date1 = a.LastProcessed.split(' ')
    const formattedString1 = date1[0].slice(0, -1) + ' ' + date1[1]
    const date2 = b.LastProcessed.split(' ')
    const formattedString2 = date2[0].slice(0, -1) + ' ' + date2[1]
    return new Date(formattedString2).getTime() - new Date(formattedString1).getTime()
  }
  if (orderBy === UploadFileConstant.ProcessedAt) {
    const date1 = a.ProcessedAt.split(' ')
    const formattedString1 = date1[0].slice(0, -1) + ' ' + date1[1]
    const date2 = b.ProcessedAt.split(' ')
    const formattedString2 = date2[0].slice(0, -1) + ' ' + date2[1]
    return new Date(formattedString2).getTime() - new Date(formattedString1).getTime()
  }
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead (props) {
  const {
    classes,
    order,
    orderBy,
    rowCount,
    onRequestSort,
    headCells,
    headcellClass,
    header
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow className={classes.background}>
        {(headCells || []).map((headCell) => {
          return (
            (headCell.name === UploadFileConstant.Action || headCell.name === UploadFileConstant.Actions)
              ? <TableCell
              key={headCell.key}
              sortDirection={orderBy === headCell.key ? order : false}
              className={headcellClass || ''}
              style ={{ textAlign: 'left', paddingLeft: '30px' }}
            >
              <span className="text-white">{headCell.name}</span>
              </TableCell>
              : <TableCell
              key={headCell.key}
              sortDirection={orderBy === headCell.key ? order : false}
              className={headcellClass || ''}
            >
              {
                (headCell.name === UploadFileConstant.Actions ||
                 headCell.name === UploadFileConstant.SelectedInvestors || headCell.name === InvestorConstant.Email || headCell.name === InvestorConstant.Address ||
                 headCell.name === InvestorConstant.TotalUsers || headCell.name === InvestorConstant.IsActive || (UserConstant.pendingHeader === header && headCell.name === UserConstant.Investor) ||
                 headCell.name === UserConstant.CreatedOn || headCell.name === UserConstant.LastLogin || headCell.name === UserConstant.UserEmail || headCell.name === InvestorConstant.InvitedOn ||
                 headCell.name === InvestorConstant.IsDeleted || headCell.name === UserConstant.Relation || headCell.name === ScheduledK1Constant.DocumentTypeName ||
                 headCell.name === UserConstant.RequestOn)
                  ? <span className="text-white">{headCell.name}</span>
                  : <TableSortLabel
                  active={orderBy === headCell.key}
                  style={{ textTransform: 'capitalize' }}
                  direction={orderBy === headCell.key ? order : 'asc'}
                  onClick={createSortHandler(headCell.key)}
                  className="text-white"
                >
                    <span className="text-white">{headCell.name}</span>
                    {orderBy === headCell.key
                      ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                        )
                      : null}
                </TableSortLabel>

              }
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%'
  }
}))

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles()
  const { numSelected, header, investorList, statusList, bootstrapClasses, documentTypeList, requestTypeList } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <Typography
          className={classes.title + '  text-blue font-weight-bold'}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <div className="mt-2">
              {header} {(header === InvestorConstant.header || header === UserConstant.header)
                ? <Tooltip classes={bootstrapClasses} title = {header === InvestorConstant.header ? InvestorConstant.AddInvestor : UserConstant.AddUser}>
                    <IconButton style ={{ marginRight: '-13px', outline: 'none' }} onClick={() => { props.openPopup(true) }}>
                    <AddBoxIcon className ={props.classes.ActionStyle}/>
                    </IconButton>
                </Tooltip>
                : ''}
                {props.readExcel !== undefined
                  ? <FileUpload parent = 'Import' setDocument = {props.readExcel} bootstrapClasses = {bootstrapClasses} classes = {props.classes.ActionStyle}/>
                  : ''}
          </div>
          <div className="row mt-2">
            <SearchFilter header = {header} filterList = {props.filterList} investorList = {investorList} statusList = {statusList} documentTypeList = {documentTypeList} requestTypeList = {requestTypeList}/>
          </div>
        </Typography>
    </Toolbar>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '5px'
  },
  paper: {
    width: '100%',
    marginBottom: '5px',
    marginTop: '5px'
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  background: {
    backgroundColor: '#013d6c'
  },
  ActionStyle: {
    cursor: 'pointer',
    color: '#013d6c'
  },
  ApproveStyle: {
    cursor: 'pointer',
    color: '#008000'
  },
  DeleteStyle: {
    cursor: 'pointer',
    color: '#a23542'
  },
  DisableStyle: {
    cursor: 'not-allowed',
    color: '#808080'
  }
}))

export default function DenseTable (props) {
  const bootstrapClasses = useStylesBootstrap()
  const { header, columns, data, config = {} } = props
  const classes = useStyles()
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState(header === undefined ? ScheduledK1Constant.LastProcessed : header === UploadFileConstant.UploadHistory ? UploadFileConstant.ProcessedAt : '')
  const [open, setOpen] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(true)
  const [body, SetBody] = React.useState('')
  const [popupHeader, SetHeader] = React.useState('')
  const [selected, setSelected] = React.useState([])
  const [values, setValues] = React.useState({
    showHiddenValue: []
  })

  const [rowsPerPage, setRowsPerPage] = React.useState(
    config.PageSize ||
      (config !== {} &&
        config.minRows !== undefined &&
        data.length < config.minRows)
      ? data.length
      : 5
  )
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([
    5,
    10,
    25,
    50
  ])
  const [showPager, setShowPager] = React.useState(
    !(config !== {} && config.minRows !== undefined && data.length < config.minRows)
  )

  const handleClickShowHideValue = (index) => {
    values.showHiddenValue[index] = !values.showHiddenValue[index]
    setValues({ ...values, showHiddenValue: values.showHiddenValue })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const downloadPdf = (documentObj, props) => {
    props.downloadPdf(documentObj)
  }

  const OnHandelClick = (isOpen) => {
    setOpen(isOpen)
  }

  const SetContent = (content, header, isSetColor = false, classes = null) => {
    setOpen(true)
    isSetColor ? SetHeader(header) : SetHeader(header)
    const body = isSetColor
      ? (content || []).map((colValue, i) => (
      <Chip key= {i} style = {{ backgroundColor: '#d6e7f3f2', color: '#064473', margin: '3px', fontSize: '12px' }} label= {colValue} />
        ))
      : (<Table aria-label="simple table">
        <TableHead>
          <TableRow className={classes.background}>
            <TableCell className="text-white">User Name</TableCell>
            <TableCell className="text-white">Email</TableCell>
            <TableCell className="text-white">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {content.userData.map((colValue, i) => (
            <TableRow key={i}>
              <TableCell>{colValue.userName}</TableCell>
              <TableCell>{colValue.email}</TableCell>
              <TableCell>{colValue.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>)
    SetBody(body)
  }

  const handleSwitchChange = (e, data) => {
    props.handleChange(e, data)
  }

  const emptyRows = data !== undefined
    ? rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)
    : rowsPerPage
  const isSelected = (name) => {
    return header === undefined ? selected === (name.K1ClientDocumentId) : false
  }
  const setColor = (name) => {
    header === undefined ? setSelected(name.K1ClientDocumentId) : setSelected('')
  }
  const viewDocument = (colValue, rowValue, row) => {
    props.performAction(colValue, rowValue)
    setColor(row)
  }
  const filterList = (e) => {
    props.filterList(e)
    setPage(0)
  }
  return (
    <div className={classes.root + ' m-4' }>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar bootstrapClasses = {bootstrapClasses} classes={classes} openPopup = {props.openPopup} header = {header} filterList = {filterList} investorList = {props.investorList}
          statusList = {props.statusList} documentTypeList = {props.documentTypeList} requestTypeList = {props.requestTypeList}
          readExcel = {props.readExcel}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              rowCount={data !== undefined ? data.length : []}
              onRequestSort={handleRequestSort}
              headCells={columns}
              headcellClass={config.headcellClass}
              header = {header}
            />
            <TableBody>
              {showPager &&
                stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row)
                    return (
                      <TableRow
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                        className={
                         (index % 2 === 0
                           ? config.evenRowClass
                           : config.oddRowClass)
                        // + ' ' +
                          //  (row.Action !== undefined && row.Action.ParentName === 'InvestorList' && row.Action.Status === Status.Removed
                          //    ? 'disable-background-color'
                          //    : '')
                        }
                        key={'table_data' + index}
                      >
                        {(columns || []).map((col, i) => (
                          col.key === 'Action'
                            ? row[col.key].ParentName === 'ProcessingHistory'
                              ? <TableCell style={{ color: '#064473' }} key={'tableRowCell' + index + i + col.key}>
                                {
                                <>
                                  {(row[col.key].Action || []).map((colValue, i) => (
                                    colValue === 'Download'
                                      ? <Tooltip classes={bootstrapClasses} key = {i} title = {colValue}>
                                        <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} onClick={() => { downloadPdf(row[col.key], props) }}>
                                            <GetAppIcon className ={classes.ActionStyle} key = {i} />
                                        </IconButton>
                                        </Tooltip>
                                      : ''

                                  ))}
                                </>
                                }
                            </TableCell>
                              : (row[col.key].ParentName === 'InvestorList' || row[col.key].ParentName === 'UserList')
                                  ? <TableCell width="10%" style={{ color: '#064473' }} key={'tableRowCell' + index + i + col.key}>
                                {
                                <>
                                  {(row[col.key].Action || []).map((colValue, i) => (
                                    colValue === 'Edit' && row[col.key].Active && (row[col.key].Status !== Status.Removed && row[col.key].Status !== Status.Rejected)
                                      ? <Tooltip classes={bootstrapClasses} key = {i} title = {colValue}>
                                        <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} onClick={() => { props.performAction(colValue, row[col.key]) }}>
                                            <EditIcon className ={classes.ActionStyle} key = {i} />
                                        </IconButton>
                                        </Tooltip>
                                      : colValue === 'Edit' && !row[col.key].Active && (row[col.key].Status !== Status.Removed && row[col.key].Status !== Status.Rejected)
                                        ? <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} >
                                             <EditIcon className ={classes.DisableStyle} key = {i} />
                                         </IconButton>
                                        : colValue === 'SendEmail' && row[col.key].Active && (row[col.key].Status !== Status.Removed) && row[col.key].ParentName === 'InvestorList'
                                          ? <Tooltip classes={bootstrapClasses} key = {i} title="Send Email">
                                                <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} onClick={() => { props.performAction(colValue, row[col.key]) }}>
                                                  <EmailIcon className ={classes.ActionStyle} key = {i}/>
                                                </IconButton>
                                              </Tooltip>
                                          : colValue === 'Delete' && (row[col.key].Status !== Status.Removed)
                                            ? <Tooltip classes={bootstrapClasses} key = {i} title={colValue}>
                                              <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} onClick={() => { props.performAction(colValue, row[col.key]) }}>
                                                <DeleteIcon className ={classes.DeleteStyle} key = {i}/>
                                              </IconButton>
                                            </Tooltip>
                                            : colValue === 'Swap' && row[col.key].Active && (row[col.key].Status !== Status.Removed && row[col.key].Status !== Status.Rejected)
                                              ? <Tooltip classes={bootstrapClasses} key = {i} title = {'Swap Investor'}>
                                              <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} onClick={() => { props.performAction(colValue, row[col.key]) }}>
                                                  <SwapHorizIcon className ={classes.ActionStyle} key = {i} />
                                              </IconButton>
                                              </Tooltip>
                                              : colValue === 'Swap' && !row[col.key].Active && (row[col.key].Status !== Status.Removed && row[col.key].Status !== Status.Rejected)
                                                ? <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} >
                                                   <SwapHorizIcon className ={classes.DisableStyle} key = {i} />
                                               </IconButton>
                                                : ''

                                  ))}
                                </>
                                }
                            </TableCell>
                                  : <TableCell width={row[col.key].ParentName === 'PendingUserList' ? '10%' : '20%'} style={{ color: '#064473' }} key={'tableRowCell' + index + i + col.key}>
                                {(row[col.key].Action || []).map((colValue, i) => (

                                  colValue === 'View'
                                    ? <Tooltip classes={bootstrapClasses} key = {i} title={UploadFileConstant.View}>
                                            <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} onClick={() => { viewDocument(colValue, row[col.key], row) }}>
                                              <VisibilityIcon className ={classes.ActionStyle} key = {i} />
                                            </IconButton>
                                          </Tooltip>
                                    : colValue === 'Approve' && (row[col.key].Status === Status.Pending || row[col.key].RequestType === 'Uninvite') && IsSectionVisible(SECTIONS.SCHEDULED_K1, SECTIONS.APPROVE_K1)
                                      ? <Tooltip classes={bootstrapClasses} key = {i} title={colValue}>
                                            <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} onClick={() => { props.performAction(colValue, row[col.key]) }}>
                                            <CheckCircle key = {i} className ={classes.ApproveStyle} />
                                            </IconButton>
                                          </Tooltip>
                                      : colValue === 'Reject' && (row[col.key].Status === Status.Pending || row[col.key].RequestType === 'Uninvite') && IsSectionVisible(SECTIONS.SCHEDULED_K1, SECTIONS.REJECT_K1)
                                        ? <Tooltip classes={bootstrapClasses} key = {i} title={colValue}>
                                          <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} onClick={() => { props.performAction(colValue, row[col.key]) }}>
                                          <CancelIcon key = {i} className ={classes.DeleteStyle} />
                                          </IconButton>
                                       </Tooltip>
                                        : (colValue === 'Delete' && (row[col.key].Status === Status.Pending || row[col.key].Status === Status.Approved || row[col.key].Status === Status.Rejected || row[col.key].Status === Status.Unmatched) && IsSectionVisible(SECTIONS.SCHEDULED_K1, SECTIONS.DELETE_K1))
                                            ? <Tooltip classes={bootstrapClasses} key = {i} title={Status.Remove}>
                                        <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} onClick={() => { props.performAction(colValue, row[col.key]) }}>
                                          <DeleteIcon className ={classes.DeleteStyle} key = {i}/>
                                        </IconButton>
                                      </Tooltip>
                                            : colValue === 'Download'
                                              ? <Tooltip classes={bootstrapClasses} key = {i} title={colValue}>
                                        <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} onClick={() => { props.performAction(colValue, row[col.key]) }}>
                                          <GetAppIcon className ={classes.ActionStyle} key = {i}/>
                                        </IconButton>
                                      </Tooltip>
                                              : colValue === 'SendEmail' && row[col.key].Status === Status.Approved && IsSectionVisible(SECTIONS.SCHEDULED_K1, SECTIONS.SEND_EMAIL)
                                                ? <Tooltip classes={bootstrapClasses} key = {i} title="Send Email">
                                      <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} onClick={() => { props.performAction(colValue, row[col.key]) }}>
                                        <EmailIcon className ={classes.ActionStyle} key = {i}/>
                                      </IconButton>
                                    </Tooltip>
                                                : colValue === 'Match'
                                                  ? <Tooltip classes={bootstrapClasses} key = {i} title="Match Investor">
                                      <IconButton key = {i} aria-label={colValue} style ={{ marginRight: '-13px', outline: 'none' }} onClick={() => { props.performAction(colValue, row[col.key]) }}>
                                        <AssignmentIndIcon className ={classes.ActionStyle} key = {i}/>
                                      </IconButton>
                                    </Tooltip>
                                                  : ''
                                ))}
                          </TableCell>
                            : col.key === 'ProcessedAt' || col.key === 'LastProcessed' || col.key === 'CreatedDate' || col.key === 'LastLogin' || col.key === 'InvestorName' || col.key === 'K1ReferenceName'
                              ? col.type === 'Hidden' && row[col.key] !== undefined && row[col.key] !== null && row[col.key] !== ''
                                ? <TableCell width="20%" style={{ color: '#064473' }} key={'tableRowCell' + index + i + col.key}>
                              <div>
                              <Input
                                    type={values.showHiddenValue[index] ? 'text' : 'password'}
                                    class = 'tax-class1'
                                    value={row[col.key]}
                                    readOnly
                                    disableUnderline
                                    endAdornment={
                                      <Tooltip classes={bootstrapClasses} key = {i} title={values.showHiddenValue[index] ? ScheduledK1Constant.HideTaxId : ScheduledK1Constant.ViewTaxId}>
                                        <IconButton
                                          onClick={() => { handleClickShowHideValue(index) }}>
                                          {values.showHiddenValue[index] ? <VisibilityOffIcon className ={classes.ActionStyle}/> : <VisibilityIcon className ={classes.ActionStyle}/>}
                                        </IconButton>
                                        </Tooltip>
                                  }
                                  />
                                   {!values.showHiddenValue[index] &&
                                  <Input
                                  class = 'tax-class'
                                  type="text"
                                    value={row[col.key].substring(row[col.key].length - 4)}
                                    readOnly
                                    disableUnderline
                                  />
                                   }
                                </div>
                            </TableCell>
                                : <TableCell width="20%" style={{ color: '#064473' }} key={'tableRowCell' + index + i + col.key}>
                            {row[col.key] }
                          </TableCell>
                              : <TableCell style={{ color: '#064473' }} key={'tableRowCell' + index + i + col.key}>
                            {
                                col.key === 'Investor'
                                  ? row[col.key].length < 3
                                    ? <>
                                  {(row[col.key] || []).map((colValue, i) => (
                                    <div key= {i}>
                                      <Chip key= {i} style = {{ backgroundColor: '#d6e7f3f2', color: '#064473', margin: '3px', fontSize: '12px' }} label= {colValue} />
                                    </div>
                                  )) }
                                </>
                                    : <>
                                   <Link component="div" onClick={() => { SetContent(row[col.key], UploadFileConstant.SelectedInvestors, true) }} style = {{ cursor: 'pointer', textDecoration: 'none' }}>
                                    {
                                      (row[col.key] || []).map((colValue, i) => (
                                        i <= 1
                                          ? <div key= {i}>
                                            <Chip key= {i} style = {{ cursor: 'pointer', backgroundColor: '#d6e7f3f2', color: '#064473', margin: '3px', fontSize: '12px' }} label= {colValue} />
                                         </div>
                                          : ''
                                      ))
                                    } <div style = {{ margin: '6px', cursor: 'pointer', color: '#064473', textDecoration: 'underline' }}> {UploadFileConstant.MoreInvestors }</div>
                                  </Link>
                                  </>
                                  : col.key === 'TotalUsers'
                                    ? row[col.key].TotalUsers > 0
                                      ? <Link component="div" onClick={() => { SetContent(row[col.key], UploadFileConstant.UserName, false, classes) }} style = {{ cursor: 'pointer' }}>
                                        {
                                          row[col.key].TotalUsers
                                        }
                                      </Link>
                                      : row[col.key].TotalUsers
                                    : col.key === 'IsActive'
                                      ? <Switch data = {row[col.key]} isChecked = {row[col.key].IsActive} handleChange = {handleSwitchChange}/>
                                      : row[col.key]
                            }
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  })}
              {showPager === false &&
                stableSort(data, getComparator(order, orderBy)).map(
                  (row, index) => {
                    return (
                      <TableRow
                        className={
                          index % 2 === 0
                            ? config.evenRowClass
                            : config.oddRowClass
                        }
                        key={'pagerfalsetable_data' + index}
                      >
                        {(columns || []).map((col, i) => (
                          <TableCell
                            key={'pagerfalsetableRowCell' + index + i + col.key}
                          >
                            {col.renderComponent !== undefined
                              ? col.renderComponent(row, col.key)
                              : row[col.key]}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  }
                )}
              {data === [] ||
                (data.length === 0 && (
                  <TableRow key="emptyrow" style={{ height: dense ? 33 : 53 }}>
                    <TableCell key="emptyrowcell" colSpan={6} className='text-blue'>
                      No data available.
                    </TableCell>
                  </TableRow>
                ))}
              {/* {showPager && emptyRows > 0 && (
                <TableRow
                  key="emptyrow1"
                  style={{ height: (dense ? 33 : 53) * emptyRows }}
                >
                  <TableCell key="emptyrowcell1" colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {!config?.pagination && (
          <TablePagination
            style={{ color: '#064473', outline: 'none' }}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      <ModalPopup
        IsOpen = {open}
        Header = {popupHeader}
        Body = {body}
        OnHandelClick = {OnHandelClick}
        Close = {UploadFileConstant.Close}/>
    </div>
  )
}
