import * as actionType from "./../layout/actionTypes";

const initialState = {
	userData: null,
	isLoading: false
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionType.GET_USER_DATA:
			return { ...state, userData: action.payload };

		case actionType.SET_LOADER_SCREEN:
			return { ...state, isLoading: action.payload };

		default:
			return state;
	}
};

export default reducer;