export const SECTIONS = {
  INVESTOR_LIST: 1,
  UPLOAD_FILE: 2,
  SCHEDULED_K1: 3,
  USER_MANAGEMENT: 4,
  INVITE_USERS: 5,
  INVESTOR_LISTING: 6,
  ADD_INVESTOR: 7,
  SEARCH_INVESTOR: 8,
  UPLOAD_HISTORY_LISTING: 9,
  UPLOAD_K1: 10,
  SEARCH_K1_HISTORY: 11,
  SCHEDULED_K1_LISTING: 12,
  SEARCH_SCHEDULED_K1: 13,
  APPROVE_K1: 14,
  REJECT_K1: 15,
  DELETE_K1: 16,
  CONTACT_US: 18,
  USER_ACTIVITY: 19,
  SEND_EMAIL: 17
}

export const IDLE_TIME = 59 * 60 * 1000

export const EXCEL_FILE = '../resources/files/Excel File Format.xlsx'

export const TAX_ACCESS_TYPE = [
  { id: 1, value: 'Onwards' },
  { id: 2, value: 'Only' },
  { id: 3, value: 'Previous' }
]
