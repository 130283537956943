import { combineReducers } from 'redux'
import loaderReducer from './loader/reducer'
import userCounter from './layout/reducer'
import configuredUserReducer from './configuredUser/reducer'
import layoutReducer from './layout/reducer'
import showAllVersion from './search-filter/reducer'
import ErrorPage from './error-page/reducer'

const rootReducer = combineReducers({
  loaderConfig: loaderReducer,
  counterConfig: userCounter,
  configuredUser: configuredUserReducer,
  layoutConfig: layoutReducer,
  showAllVersionConfig: showAllVersion,
  errorConfig: ErrorPage
})

const initialState = rootReducer({}, {})

const combineReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = initialState
  }
  return rootReducer(state, action)
}

export default combineReducer
