import React, { Component } from 'react';
import { Status, UploadFileConstant, UserConstant } from '../../../common/constants/index';
import { apiGet,apiPostAsync, apiPutAsync } from "../../../common/component/utils/api-service";
import { API_URL } from '../../../common/constants/apiConfiguration';
import {
    Button,
    FormHelperText,
    Select,
    MenuItem,
    Checkbox,
    TextField,
    withStyles,
    Chip
  } from "@material-ui/core/";
import { ShowToast, toastType } from "../../../common/component/utils/toast-service";
import ConfirmDialog from '../../../common/component/dialog-box/ConfirmDialog';
import { getBackYearFromDate } from '../../../common/component/utils/date';
import Autocomplete from '../../../common/component/autocomplete/autocomplete'
import { TAX_ACCESS_TYPE } from '../../../common/constants/appConstants'

class InviteUser extends Component {
    constructor(props) {        
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            relation: '',
            investor: '',
            errors: {
              firstName: '',
              lastName: '',
              email: '',
              relation: '',
              investor: '',
              action: '',
              taxYear: '',
              userInvestorId: '',
              taxAccessType: ''
           },
           UserId: 0,
           relationList: [],
           isOpenConfirmationPanel: false,
           message: '',
           clientId: props.clientId,
           isUndo: false,
           UserIdValue: 0,
           action: '',
           actionList: [],
           taxYearList: getBackYearFromDate(5),
           taxYear: '',
           userList: [],
           investorList: [],
           userInvestorId: '',
          investorValue: [],
          investorName: [],
          taxAccessType: ''
        }
    }
    componentDidMount () {
        this.getMaster();
        this.getUserList();
        this.getInvestor();
      }
      getMaster() {
        const params = {
          isRelation: true
        }
        apiGet({ url: API_URL.masterData , params}).then((data) => {
        if(data && (data.relationData.success)) {
          this.setState({ relationList: data.relationData.data, actionList: UserConstant.ActionList  });
        }
        });        
     }

     getUserList() {
      const params = {
        clientId: this.state.clientId
      }
      apiGet({ url: API_URL.userList, params}).then((data) => {
        if(data && data.data !== undefined) {
        this.setState({ userList: data.data });
      }
      });        
   }

    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
          (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }
    getInvestor(value, action) {
      const params = {
        isInvestors: true,
        investorUserEmail: value,
        actionType: action
      }
      apiGet({ url: API_URL.masterData , params}).then((data) => {
        if(data && (data.client.success)) {      
          this.setState({ investorList: data.client.data });
        }
      });
   }
    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }
      onChangeHandle = (e, isPrevent = true) => {
        let email = this.state.email;
        let userInvestorId = this.state.userInvestorId;
        let investorName = this.state.investorName;
        let investorValue = this.state.investorValue;
        let name = '', value = '';
        if (isPrevent) {
            e.preventDefault();
            name = e.target.name;
            value = e.target.value;
        } else {    
            name = e.name;
            value = e.value;       
        }
        let errors = this.state.errors;
        switch (name) {
            case 'taxAccessType': 
            errors.taxAccessType = 
              value == ''
                ? UserConstant.AccessTypeError
                : '';
            break;
            case 'firstName': 
              errors.firstName = 
                value == ''
                  ? UserConstant.FirstNameError
                  : '';
              break;
            case 'lastName': 
              errors.lastName = 
                value == ''
                  ? UserConstant.LastNameError
                  : '';
              break;
            case 'email': 
              errors.email = 
                value == ''
                  ? UserConstant.EmailError
                  : value != '' && this.validateEmail(value) ? '' : UserConstant.ValidEmailAddress;
                  investorValue =  []
                  investorName = []
                  if(this.state.action !== '' && errors.email == '' && value != ''  &&  isPrevent) {
                    this.getInvestor(value, this.state.action);
                  }
              break;
            case 'relation': 
              errors.relation = 
                value == ''
                  ? UserConstant.RelationError
                  : '';
              break;
            case 'userInvestorId': 
              errors.userInvestorId = 
                value == ''
                  ? UserConstant.InvestorError
                  : '';
              break;
            case 'action': 
        //    errors = {};
              errors.action = 
                value == ''
                  ? UserConstant.ActionError
                  : '';
                  email= ''
                  userInvestorId = ''
                  investorValue =  []
                  investorName = []
                  if(value == 'Invite') {    
                    (isPrevent) ? this.getInvestor(null) : ''         
                    this.setState({email, userInvestorId,errors, [name]: value, investorValue, investorName})
                  } else {
                    this.setState({email, userInvestorId,errors, [name]: value, investorValue, investorName})
                  }
              break;

              case 'taxYear':
                errors.taxYear = 
                value == ''
                  ? this.state.action == 'Invite' ? UserConstant.ApplicableStartTaxYearError : UserConstant.ApplicableEndTaxYearError
                  : '';
                break;
            default:
              break;
          }
          name !== 'action' ? this.setState({errors, [name]: value ,investorValue,investorName}) : '';          
     }
     
clearField = () => {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      relation: '',
      investor: '',
      errors: {},
      action: '',
      taxYear: '',
      userInvestorId: '',
      investorValue: [],
      investorName: [],
      taxAccessType: ''
    });
  }
  handleSubmit = (e) => {
    e.preventDefault();  
    this.onChangeHandle(e.target.elements.action, false);
    this.onChangeHandle(e.target.elements.email, false);
    this.onChangeHandle(e.target.elements.taxYear, false);
    this.SelectInvestor(this.state.investorName, this.state.investorValue)
    if(this.state.action == 'Invite') {
    this.onChangeHandle(e.target.elements.firstName, false);
    this.onChangeHandle(e.target.elements.lastName, false);
    this.onChangeHandle(e.target.elements.relation, false);
    this.onChangeHandle(e.target.elements.taxAccessType, false);

      if(this.validateForm(this.state.errors)) {
        this.SaveUser();
      } 
    }
    else {
      if(this.validateForm(this.state.errors)) {
        this.UninviteUser();
      } 
    } 
  }

  SelectEmailAddress = (value) => {
    this.setState({email: value});
}

  SaveUser = () => {
    const {firstName, lastName,email, relation, investor,errors, clientId, action, taxYear, userInvestorId, investorName, investorList, taxAccessType } = this.state;
    const investorId = [];
    if (investorName.length == 1 && investorName.find(x=> x === 'All') !== undefined) {
      investorList.filter((item)=>{ 
        if (item.maskedName !== 'All') {
          investorId.push(item.clientId)
      }  });
    } else {
      for(let i=0; i< investorName.length; i++) {
        investorList.filter((item)=>{ 
            if (item.maskedName == investorName[i]) {
                investorId.push(item.clientId)
            }
        });
    }
  }
    const postBody = { taxAccessTypeId: parseInt(taxAccessType),firstName, lastName, email,relationship: relation, ClientId: investorId.join('|'), UserId: 0, ApproveStatus: Status.Pending, startTaxYear: parseInt(taxYear) };
    apiPostAsync({ url: API_URL.InviteUser, postBody }).then((data) => {
      if(data.success) {
        ShowToast(data.message,  toastType.SUCCESS, 5000);
        this.clearField();
        this.props.getInvitationHistory();
        this.props.getPendingUser();
      } else {
        if (data && data.data.EmailStatus == 0) {
          errors.email = UserConstant.EmailIdAlreadyTaken;
        }else {
          if (data && data.data.PendingUserStatus == 0) {
            errors.email = UserConstant.PendUserStateMessage;
          } 
          // else if (data && data.data.MultipleUser == 0) {
          //   this.setState({isOpenConfirmationPanel: true, message: UserConstant.UserExistWithDifferentInvestor, isUndo: true, UserIdValue: data.data.UserId})
          // } 
          else if (data && data.data.UserExistsStatus == 0) {
            this.setState({isOpenConfirmationPanel: true, message: UserConstant.UndoUser})
          }
        }
        this.setState({errors})
      }
    });
  }

  UninviteUser = () => {
    const {email, userInvestorId, errors, taxYear, investorName, investorList } = this.state;
    const investorId = [];
    if (investorName.length == 1 && investorName.find(x=> x === 'All') !== undefined) {
      investorList.filter((item)=>{ 
        if (item.maskedName !== 'All') {
          investorId.push(item.clientId)
      }    
    });
    } else {
      for(let i=0; i< investorName.length; i++) {
        investorList.filter((item)=>{ 
            if (item.maskedName == investorName[i]) {
                investorId.push(item.clientId)
            }
        });
      }
   }
    const postBody = { email, ClientId: investorId.join('|'), endTaxYear: parseInt(taxYear) };
    apiPostAsync({ url: API_URL.UninviteUser, postBody }).then((data) => {
      if(data.success) {
        ShowToast(data.message,  toastType.SUCCESS, 5000);
        this.clearField();
        this.props.getInvitationHistory();
        this.props.getPendingUser();
      } else {
        errors.email = data.message;
        this.setState({errors})
      }
    });
  }

  PerformAction = (value) => {
    const { isUndo } = this.state;
    if (value) {
      if (isUndo) {
        this.SaveClientUserMapping();
      } else {
        this.EnableUser();
      }
    } else {
      this.setState({isOpenConfirmationPanel: false, message: ""})
    }
  }
  SaveClientUserMapping = () => {
    this.setState({loading: true});
    const { UserIdValue,  userInvestorId, taxYear} = this.state;
    const params = {
      UserId: UserIdValue,
      clientId: userInvestorId,
      ApproveStatus: Status.Pending,
      startTaxYear: taxYear
    };
    apiPutAsync({ url: API_URL.SaveClientUserMapping,params }).then((data) => {
      if(data.success) {
        ShowToast(UserConstant.UserMapping,  toastType.SUCCESS, 5000);
        this.setState({loading: false, isOpenConfirmationPanel: false, message: ""});
        this.clearField();
        this.props.getInvitationHistory();
        this.props.getPendingUser();
      }
    });
  }
  EnableUser = () => {
    this.setState({loading: true});
    const { email } = this.state;
    const params = {
      email: email,
      status: Status.Pending
    };
    apiPutAsync({ url: API_URL.EnableUser,params }).then((data) => {
      if(data.success) {
        ShowToast(UserConstant.UserEnable,  toastType.SUCCESS, 5000);
        this.setState({loading: false, isOpenConfirmationPanel: false, message: ""});
        this.clearField();
        this.props.getInvitationHistory();
        this.props.getPendingUser();
      }
    });
  }

  SelectInvestor = (investorName, values, investorId) => {
    let errors = this.state.errors
    let investorList = this.state.investorList
    if(investorName === undefined || investorName.length <=0) {
      errors.userInvestorId = UserConstant.InvestorError
    } else {
      errors.userInvestorId = ''
      if(investorName.length > 1) {
        investorName = (investorName || []).filter(x => x !== 'All');
        values = (values || []).filter(x => x.maskedName !== 'All');
      }
      if (investorList.length > 1 && (investorList || []).filter(x => x.maskedName !== 'All').length === (investorName || []).length) {
        investorName = []
        investorName = ["All"];
        values = [];
        values = [{ maskedName: 'All' }]
      }
    }
    this.setState({investorName : investorName, investorValue: values, errors})
  }

  
    render() {   
        const {errors, taxAccessType, firstName,lastName, investorList, userInvestorId, email, relation,relationList, isOpenConfirmationPanel, message, action, actionList, taxYearList, taxYear, userList, investorValue} = this.state;
        if ((investorList || []).length > 1 && investorList.find(x => x.maskedName === 'All') === undefined) {
          investorList.unshift({ maskedName: 'All' });
        }
        return (
          <>
            <div className="m-4 border shadow-sm border-theme-color background-theme-color rounded">
                <form onSubmit={this.handleSubmit} noValidate>
                <div className="row m-2  mt-4 multi-select">
                <label className="col-md-2 col-sm-12 col-form-label text-blue font-weight-bold"> {UserConstant.Action} <span className="text-red">*</span></label>
                        <div className="col-md-4 col-sm-12">
                            <Select
                                displayEmpty
                                className="w-100 text-blue"
                                name = 'action'
                                value = {action}
                                onChange = {this.onChangeHandle}
                                error = {errors.action}
                            >
                                <MenuItem className="text-blue" value=""> {UserConstant.Action }</MenuItem>
                                {
                                    actionList.map(action => {
                                        return (
                                            <MenuItem className="text-blue" key = {action.name} value={action.name}> {action.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <FormHelperText className="text-red">
                                {errors.action}
                            </FormHelperText>
                        </div>
                        </div>
                        {this.state.action == 'Invite' &&   <>
                        <div className="row m-2  mt-4 multi-select">
                    <label className="col-md-2 col-sm-12 col-form-label text-blue font-weight-bold"> {UserConstant.FirstName} <span className="text-red">*</span></label>
                        <div className="col-md-4 col-sm-12">
                            <TextField
                                variant="outlined"
                                className = 'w-100'
                                name = 'firstName'
                                value = {firstName}
                                placeholder= {UserConstant.FirstName}
                                InputProps={{
                                    className: 'text-blue'
                                }}
                                onChange = {this.onChangeHandle}
                                error = {errors.firstName}
                            />
                            <FormHelperText className="text-red">
                                {errors.firstName}
                            </FormHelperText>
                        </div>
                        <label className="col-md-2 col-sm-12 col-form-label text-blue font-weight-bold"> {UserConstant.LastName} <span className="text-red">*</span></label>
                        <div className="col-md-4 col-sm-12">
                            <TextField
                                variant="outlined"
                                className = 'w-100'
                                name = 'lastName'
                                value = {lastName}
                                placeholder= {UserConstant.LastName}
                                InputProps={{
                                    className: 'text-blue'
                                }}
                                onChange = {this.onChangeHandle}
                                error = {errors.lastName}
                            />
                            <FormHelperText className="text-red">
                                {errors.lastName}
                            </FormHelperText>
                        </div>
                        </div>
    </>}
                    <div className="form-group row m-2 multi-select">
                        <label className="col-md-2 col-sm-12 col-form-label text-blue font-weight-bold"> {UserConstant.EmailAddress} <span className="text-red">*</span></label>
                        <div className="col-md-4 col-sm-12">
                            {this.state.action != 'Uninvite' ? <TextField
                                variant="outlined"
                                className = 'w-100'
                                name = 'email'
                                value = {email}
                                placeholder= {UserConstant.EmailAddress}
                                InputProps={{
                                    className: 'text-blue'
                                }}
                                onChange = {this.onChangeHandle}
                                error = {errors.email}
                            /> :
                            <Select
                            displayEmpty
                            className="w-100 text-blue"
                            name = 'email'
                            value = {email}
                            onChange = {this.onChangeHandle}
                            error = {errors.email}
                        >
                            <MenuItem className="text-blue" value=""> {UserConstant.EmailAddress }</MenuItem>
                            {
                                userList.map(user => {
                                    return (
                                        <MenuItem className="text-blue" key = {user.UserId} value={user.Email}> {user.Email}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                              }
                            <FormHelperText className="text-red">
                                {errors.email}
                            </FormHelperText>
                        </div>
                        <>
                        <label className="col-md-2 col-sm-12 col-form-label text-blue font-weight-bold"> {UploadFileConstant.SelectInvestor} <span className="text-red">*</span></label>
                          <div className="col-md-4 col-sm-12">
                            {/* <Select
                                displayEmpty
                                className='w-100 text-blue'
                                name = 'userInvestorId'
                                value = {userInvestorId}
                                onChange = {this.onChangeHandle}
                                error = {errors.userInvestorId}
                            >
                                <MenuItem className="text-blue" value=""> {UploadFileConstant.SelectInvestor }</MenuItem>
                                {
                                    investorList.map(client => {
                                        return (
                                            <MenuItem className="text-blue" key = {client.clientId} value={client.clientId}> {client.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>  */}
                            <Autocomplete value = {investorValue} tagLimits = {1} placeholder={UploadFileConstant.SelectInvestor} options = {investorList} selectedValue = {this.SelectInvestor}/>
                            <FormHelperText className="text-red">
                              {errors.userInvestorId}
                            </FormHelperText>
                          </div>
                        </>          
                        <label className="col-md-2 col-sm-12 col-form-label text-blue font-weight-bold"> {action == 'Invite' ? UserConstant.StartTaxYear : UserConstant.EndTaxYear} <span className="text-red">*</span></label>
                        <div className="col-md-4 col-sm-12">
                        <Select
                                displayEmpty
                                className="w-100 text-blue"
                                name = 'taxYear'
                                value = {taxYear}
                                onChange = {this.onChangeHandle}
                                error = { errors.taxYear}
                            >
                                <MenuItem className="text-blue" value=""> {action == 'Invite' ? UserConstant.StartTaxYear : UserConstant.EndTaxYear }</MenuItem>
                                {
                                    taxYearList.map(year => {
                                        return (
                                            <MenuItem className="text-blue" key = {year} value={year}> {year}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <FormHelperText className="text-red">
                                {errors.taxYear}
                            </FormHelperText>
                        </div>
                        {this.state.action == 'Invite' &&   <>
                        <label className="col-md-2 col-sm-12 col-form-label text-blue font-weight-bold"> {UserConstant.Relation} <span className="text-red">*</span></label>
                        <div className="col-md-4 col-sm-12">
                            <Select
                                displayEmpty
                                className="w-100 text-blue"
                                name = 'relation'
                                value = {relation}
                                onChange = {this.onChangeHandle}
                                error = {errors.relation}
                            >
                                <MenuItem className="text-blue" value=""> {UserConstant.Relation }</MenuItem>
                                {
                                    relationList.map(relation => {
                                        return (
                                            <MenuItem className="text-blue" key = {relation.Name} value={relation.Name}> {relation.Name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <FormHelperText className="text-red">
                                {errors.relation}
                            </FormHelperText>
                        </div> 
                        <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UserConstant.AccessType} <span className="text-red">*</span></label>
                        <div className="col-sm-4 col-xs-12">
                        <Select
                            displayEmpty
                            className={'w-100 text-blue'}
                            name = 'taxAccessType'
                            value = {taxAccessType}
                            onChange = {this.onChangeHandle}
                            error = {errors.taxAccessType}
                        >
                            <MenuItem className="text-blue" value=""> {UploadFileConstant.SelectAccessType }</MenuItem>
                            {
                                TAX_ACCESS_TYPE.map(accessType => {
                                    return (
                                        <MenuItem className="text-blue" key = {accessType.id} value={accessType.id}> {taxYear !== undefined && taxYear !== '' && taxYear !== null ? taxYear + ' and' : ''} {accessType.value}</MenuItem>
                                    )
                                })
                            }
                        </Select> 

                        <FormHelperText className="text-red">
                          {errors.taxAccessType}
                        </FormHelperText>
                      </div>
                        </>}                          
                    </div>
                 
                    <div className="form-group row m-2 mb-3">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-2">
                            <Button variant="contained" className="btn btn-sm btn-blue w-100" type="submit">
                                {UserConstant.SubmitRequest}
                            </Button>
                        </div>
                        <div className="col-sm-2">
                            <Button variant="contained" className="btn btn-sm btn-blue w-100" type="button" onClick={this.clearField}>
                                {UserConstant.Clear}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
            <ConfirmDialog
                IsOpen={isOpenConfirmationPanel}
                Message={message}
                OnHandelClick={this.PerformAction}
                AgreeText = "OK"
                DisAgreeText = "CANCEL"
            />
          </>
        );
    }
}

export default InviteUser;