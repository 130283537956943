import {Link, Redirect} from 'react-router-dom';
import React, { Component } from 'react'
// import ReCAPTCHA from 'react-google-recaptcha'
import { LoginModel } from '../../../common/constants/index'
import { 
  TextField,
  Button,
  FormHelperText
} from '@material-ui/core/'
import './signIn.less';
import { ShowToast, toastType } from "../../../common/component/utils/toast-service";
import { apiPostAsync } from "../../../common/component/utils/api-service"
import { validatePassword } from "../../../common/component/utils/generic";
import { API_HEADERS, API_URL } from '../../../common/constants/apiConfiguration';
import queryString from 'query-string';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      password: null,
      secretKey: null,
      errors: {
        userName: '',
        password: ''
      }
    };

    if(props.location != undefined
      && props.location.search != undefined) {
        let params = queryString.parse(props.location.search);

        if(params != null && params != undefined) {
          if(params.secretKey != null && params.secretKey != undefined) {
            this.state = {
              view: "password",
              userName: null,
              password: null,
              secretKey: params.secretKey
            };
          }
        }        
      }       
  }

  onChangeOfField = (e, isPrevent = true) => {
    let name = '', value = '';
    if (isPrevent) {
        e.preventDefault();
        name = e.target.name;
        value = e.target.value;
    } else {    
        name = e.name;
        value = e.value;       
    }
    let errors = this.state.errors;
    switch (name) {
        case 'userName': 
            errors.userName = 
            value == ''
              ? "Username is required."
              : '';     
          break;
        case 'password': 
          errors.password = 
            value == ''
              ? "Password is required."
              : !validatePassword(value) ? 
               "Password must be 8 to 12 alphanumeric characters and at-least one of the special characters in (!,@,#,$,%,^,&,*). "
              : '';
          break;
        default:
          break;
      }
      this.setState({errors, [name]: value});
  }

  
  validateForm = (errors) => {
    let valid = true;    
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }

  authenticate = (e) => {
    e.preventDefault();

    this.onChangeOfField(e.target.elements.userName, false);
    this.onChangeOfField(e.target.elements.password, false);

    if(!this.validateForm(this.state.errors)) {
      return
     } 
    var postBody = {
      email: this.state.userName,
      password: this.state.password,
      otp: "string",
    };
    apiPostAsync({
      url: API_URL.UserConfiguration.Authenticate,
      postBody: postBody,
    }).then((response) => {
      if (response.success == false) {
        // var GetLoginAttempt;
        // if (
        //   localStorage.getItem("LoginAttempt") == null ||
        //   localStorage.getItem("LoginAttempt") == NaN
        // ) {
        //   localStorage.setItem("LoginAttempt", "0");
        //   GetLoginAttempt = 0;
        // } else {
        //   GetLoginAttempt = localStorage.getItem("LoginAttempt");
        // }

        // GetLoginAttempt = parseInt(GetLoginAttempt) + 1;
        // localStorage.setItem("LoginAttempt", parseInt(GetLoginAttempt));
        // if (GetLoginAttempt > 3) {
        //   ShowToast(
        //     "You have exceeded login attempts. Please try resetting password.",
        //     toastType.ERROR,
        //     5000,
        //     200
        //   );          
        //   localStorage.setItem("LoginAttempt", 0);
        //   window.location = 'change-password'
        // }

        ShowToast(response.message, toastType.ERROR, 5000, 200);
      } else if (response.success == true) {
        localStorage.setItem('password', this.state.password);
        localStorage.setItem('email', this.state.userName);
        localStorage.setItem('IsLogin', true);
        window.location = 'otp/true'
        ShowToast(response.message, toastType.SUCCESS, 5000, 200);
      }
    });
  };
  
  render() {
    const {userName, password, errors} = this.state;
    return (
        <form  onSubmit={this.authenticate} noValidate>
        <div className="m-2">
            <label className = "col-md-12 p-0 text-white"> {LoginModel.userName} <span className="text-red">*</span> </label>
            <div className="col-md-12 p-0">
              <TextField 
                      type="text"
                      variant="outlined"
                      maxLength={50}
                      inputValue={userName}
                      onChange={this.onChangeOfField}
                      name="userName"
                      className = "input-style"
                      error = {errors.userName}
                  />
                   <FormHelperText className="text-red">
                    {errors.userName}
                  </FormHelperText>
            </div>
             
        </div>
      <div className="m-2">
            <label className = "col-md-12 p-0 text-white">{LoginModel.password} <span className="text-red">*</span></label>
            <div className="col-md-12 p-0">
              <TextField 
                    variant="outlined"
                    type="password"
                    maxLength={50}
                    inputValue={password}
                    onChange={this.onChangeOfField}
                    name="password"
                    className = "col-md-12 input-style"
                    error = {errors.password}
                />
                  <FormHelperText className="text-red">
                    {errors.password}
                  </FormHelperText>
            </div>           
      </div>
      <Link className="m-2" to='/change-password'>
          <label className="selected text-white" >
            {LoginModel.forgotPassword}
          </label>
      </Link>

      <div className="m-2 pb-1">
      <Button 
              className = "col-md-5 font-weight-bold text-white border-class" type="submit">              
            
             {LoginModel.loginIn}
            </Button>
           <span className='font-weight-bold text-white text-center'> or </span>
            <Button 
              className = "col-md-5 font-weight-bold text-white border-class"
              onClick={() => {
                localStorage.setItem("K1LU", "/k1-user");
                localStorage.setItem('IsLogin', true);
                window.location = "/k1-user";
              }}
            >
             {LoginModel.loginInStreamDomain}
            </Button>
      </div>
      </form>

    );
  }
}

export default SignIn;