import React, { Component } from 'react';
import AddFile from './add-file/addFile';
import Table from '../../common/component/table/table'
import {UploadFileConstant} from '../../common/constants/index';
import { apiGet, DownloadPdf } from "../../common/component/utils/api-service";
import { API_URL } from '../../common/constants/apiConfiguration';
import {convertDateToFormat} from '../../common/component/utils/date'
import DocumentTypes from '../../common/constants/DocumentTypes';
import moment from 'moment';

class UploadFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
          OriginalMasterDocument: [],
          FilteredMasterDocument: [],
          investorList: [],
          documentTypeList: []
        }
        this.getMasterDocuments =  this.getMasterDocuments.bind(this);
    }
    componentDidMount() {
      fetch('/config/file-upload-setting.txt')
          .then((res) => res.json())
          .then((data) => {
              this.setState({ fileSetting: data });
          });
      this.getMasterDocuments();
      this.getInvestorMaster();
      this.getDocumentTypes();
    }
    getDocumentTypes() {
      apiGet({ url: API_URL.GetDocumentTypes }).then((data) => {
        if(data) {
          this.setState({ documentTypeList: data.data });
        }
      });
    }
    getInvestorMaster() {
      const params = {
        isInvestors: true,
      }
      apiGet({ url: API_URL.masterData, params }).then((data) => {
        if(data && data.client.success) {
          this.setState({ investorList: data.client.data });
        }
      });
    }

    getMasterDocuments() {
      apiGet({ url: API_URL.K1MasterDocument }).then((data) => {
        if(data.success) {
          var masterDocumentData =  data.data;
          for (var i in  masterDocumentData) {
            masterDocumentData[i].ProcessedDate = masterDocumentData[i].ProcessedAt;
            var actionDate  = _.cloneDeep(new Date(masterDocumentData[i].ProcessedAt));
            masterDocumentData[i].ProcessedAt = actionDate.toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false})
                                                + ' ' + masterDocumentData[i].TimeZone;;
            masterDocumentData[i].Investor = masterDocumentData[i].Investor != undefined ? masterDocumentData[i].Investor.split("||") : [];
            masterDocumentData[i].Action = {K1MasterDocumentId: masterDocumentData[i].K1MasterDocumentId, ParentName: "ProcessingHistory", DocumentType: masterDocumentData[i].DocumentType, Name:masterDocumentData[i].Name, Action: ["Download"]}
          }
          this.setState({ OriginalMasterDocument: data.data, FilteredMasterDocument: masterDocumentData });
        }
      });
    }

    DownloadPdf(documentObj) {
      const params = {
        masterDocumentId: documentObj.K1MasterDocumentId,
        documentType: documentObj.DocumentType
      };
      const fileName = documentObj.DocumentType === DocumentTypes.ProcessedK1File ? documentObj.Name + '.pdf' : documentObj.Name;
      DownloadPdf({url: API_URL.DownloadK1MasterDocument,params, fileName}).then((data) => {
      });
    }
    FilterList = (param) => {
      const { OriginalMasterDocument} = this.state;
      var filteredList = OriginalMasterDocument;
       if (filteredList.length > 0 && param.startDate != "" && param.endDate != "") {
          filteredList = filteredList.filter((item) => {
          
          
            return   (moment(item.ProcessedDate)
            .format('MM/DD/YYYY')) >= moment(param.startDate)
            .format('MM/DD/YYYY') &&
            (moment(item.ProcessedDate)
            .format('MM/DD/YYYY')) <=  moment(param.endDate)
            .format('MM/DD/YYYY');
          });
       }
       if (filteredList.length > 0 && param.investorName != "" && param.investorName.length >= 0) {
         filteredList = filteredList.filter((item) => {
          for (let i = 0; i < param.investorName.length; i++) {
            if (item.Investor.includes(param.investorName[i])) {
              return item;
            }
          } 
        });
     }
     if (filteredList && filteredList.length > 0 && param.documentTypeValue != "") {
      filteredList = filteredList.filter((item) => {
        return item.DocumentType == param.documentTypeValue ;
      });
      }
       if (filteredList && filteredList.length > 0 && param.fiscalYear != "") {
        filteredList = filteredList.filter((item) => {
          return item.FiscalYear == param.fiscalYear ;
        });
     }
       this.setState({ FilteredMasterDocument: filteredList });
    }
    render() {
        const { FilteredMasterDocument, investorList, documentTypeList} = this.state;
     
        return(
            <>
             <AddFile investorList = {investorList} getMasterDocuments= {this.getMasterDocuments}></AddFile>
              <Table 
                statusList= {[]}
                header = {UploadFileConstant.UploadHistory} 
                columns={UploadFileConstant.MasterDocumentCells} 
                data={FilteredMasterDocument}
                downloadPdf = {this.DownloadPdf}
                filterList = {this.FilterList}
                investorList = {investorList}
                documentTypeList = {documentTypeList}
              />
            </>
        ); 
    }
}

export default UploadFile;