// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../resources/images/footer-image.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer-wrapper {\n  width: 100%;\n  height: 40px;\n  color: #ffffff;\n  position: fixed;\n  bottom: 0px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  display: flex;\n  flex-wrap: wrap;\n  font-size: 12px;\n  font-weight: 300;\n  z-index: 600;\n}\n.footer-wrapper .footer-left-div {\n  text-align: left;\n  width: 20%;\n  margin-left: auto;\n  margin-right: auto;\n}\n.footer-wrapper .footer-middle-div {\n  text-align: center;\n  width: 60%;\n  margin-left: auto;\n  margin-right: auto;\n  padding-top: 15px;\n  font-size: 13px;\n}\n.footer-wrapper .footer-right-div {\n  text-align: right;\n  width: 20%;\n  margin-left: auto;\n  margin-right: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/common/component/footer/Footer.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;EACA,WAAA;EACA,yDAAA;EACA,aAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AACF;AAZA;EAcI,gBAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAlBA;EAoBI,kBAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AACJ;AA1BA;EA4BI,iBAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;AACJ","sourcesContent":[".footer-wrapper {\n  width: 100%;\n  height: 40px;\n  color: #ffffff;\n  position: fixed;\n  bottom: 0px;\n  background-image: url(\"../../../resources/images/footer-image.jpg\");\n  display: flex;\n  flex-wrap: wrap;\n  font-size: 12px;\n  font-weight: 300;\n  z-index: 600;\n\n  .footer-left-div {\n    text-align: left;\n    width: 20%;\n    margin-left: auto;\n    margin-right: auto;\n  }\n  .footer-middle-div {\n    text-align: center;\n    width: 60%;\n    margin-left: auto;\n    margin-right: auto;\n    padding-top: 15px;\n    font-size: 13px;\n  }\n  .footer-right-div {\n    text-align: right;\n    width: 20%;\n    margin-left: auto;\n    margin-right: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
