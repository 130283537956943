import React from 'react'
import { css } from '@emotion/core'
import './loader.less'
import PropagateLoader from 'react-spinners/PropagateLoader'
import cx from 'classnames'

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999;
  display: block;
  margin: 0 auto;
  text-align: center;
`
export const LOADER_COLOR = '#033967'
export default function Loader ({
  loading,
  loaderColor = LOADER_COLOR,
  message = ''
}) {
  return (
    <div className={cx('sweet-loading', !loading && 'hide')}>
      {!message.length
        ? <PropagateLoader css={override}
          size={22}
          height={35}
          width={3}
          radius={2}
          margin={2}
          color={loaderColor}
          loading={loading}
        />
        : <div className="message">{message}</div>
        }

      <div className="loader-bg" />
    </div>
  )
}
