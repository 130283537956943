export const API_VERSION = 'v1'

export const CONTROLLER = {
  Investor: 'Investor',
  Document: 'Document',
  User: 'User',
  UserConfiguration: 'UserConfiguration'
}
export function getCurrentEnvironment () {
  let environment = 'dev'
  let isLocal = false
  let clientId = '11ff488c-388a-4251-9612-cc7dcb5b1535'
  if (window.location.href.indexOf('streamdatum-dev') > 0) {
    environment = 'dev'
  } else if (window.location.href.indexOf('streamdatum-test') > 0) {
    environment = 'test'
  } else if (window.location.href.indexOf('streamdatum-stage') > 0) {
    clientId = '992ea647-5a82-4880-85b9-35df3a0e4763'
    environment = 'stage'
  } else if (window.location.href.indexOf('streamdatum-k1') > 0) {
    clientId = 'ec550646-e6ad-4f92-913c-baa9b054b41d'
    environment = 'prod'
  } else if (window.location.href.indexOf('k1.streamrealty') > 0) {
    clientId = 'ec550646-e6ad-4f92-913c-baa9b054b41d'
    environment = 'prod'
  } else if (window.location.href.indexOf('localhost') > 0) {
    isLocal = true
  }

  return { environment: environment, isLocal: isLocal, clientId: clientId }
}

function getBaseUrl () {
  const env = getCurrentEnvironment()

  if (!env.isLocal) {
    if (env.environment === 'prod') { return 'https://streamdatum-k1-apis.azurewebsites.net/api' }
    return `https://streamdatum-${env.environment}-k1-apis.azurewebsites.net/api`
  } else {
    return 'http://localhost:50883/api'
  }
}

function createApiUrl (apiName) {
  return `${getBaseUrl()}/${API_VERSION}/${apiName}`
}

export const API_URL = {
  processK1Documents: createApiUrl(`${CONTROLLER.Document}/ProcessK1Documents`),
  K1MasterDocument: createApiUrl(`${CONTROLLER.Document}/GetK1MasterDocument`),
  DownloadK1MasterDocument: createApiUrl(`${CONTROLLER.Document}/DownloadK1MasterDocument`),
  GetClientK1DocumentList: createApiUrl(`${CONTROLLER.Document}/GetClientK1DocumentList`),
  GetDocumentTypes: createApiUrl(`${CONTROLLER.Document}/GetDocumentTypes`),
  ChangeClientK1DocumentStatus: createApiUrl(`${CONTROLLER.Document}/ChangeClientK1DocumentStatus`),
  DownloadK1ClientDocument: createApiUrl(`${CONTROLLER.Document}/DownloadK1ClientDocument`),
  SendClientK1Email: createApiUrl(`${CONTROLLER.Document}/SendClientK1Email`),
  masterData: createApiUrl(`${CONTROLLER.Investor}/GetMasterData`),
  userList: createApiUrl(`${CONTROLLER.Investor}/GetUserList`),
  GetClientTypes: createApiUrl(`${CONTROLLER.Investor}/GetClientTypes`),
  SaveInvestor: createApiUrl(`${CONTROLLER.Investor}/SaveInvestor`),
  GetInvestors: createApiUrl(`${CONTROLLER.Investor}/GetInvestors`),
  ArchiveInvestor: createApiUrl(`${CONTROLLER.Investor}/ArchiveInvestor`),
  ActivateDeactivateInvestor: createApiUrl(`${CONTROLLER.Investor}/ActivateDeactivateInvestor`),
  GetInvestorById: createApiUrl(`${CONTROLLER.Investor}/GetInvestorById`),
  GetUsers: createApiUrl(`${CONTROLLER.User}/GetUsers`),
  SaveUser: createApiUrl(`${CONTROLLER.User}/SaveUser`),
  InviteUser: createApiUrl(`${CONTROLLER.User}/InviteUser`),
  UninviteUser: createApiUrl(`${CONTROLLER.User}/UninviteUser`),
  ActivateDeactivateUser: createApiUrl(`${CONTROLLER.User}/ActivateDeactivateUser`),
  ArchiveUser: createApiUrl(`${CONTROLLER.User}/ArchiveUser`),
  GetUserById: createApiUrl(`${CONTROLLER.User}/GetUserById`),
  GetPendingUserList: createApiUrl(`${CONTROLLER.User}/GetPendingUserList`),
  ApproveRejectUser: createApiUrl(`${CONTROLLER.User}/ApproveRejectUser`),
  GetInvitationHistoryList: createApiUrl(`${CONTROLLER.Investor}/GetInvitationHistoryList`),
  EnableUser: createApiUrl(`${CONTROLLER.User}/EnableUser`),
  SaveClientUserMapping: createApiUrl(`${CONTROLLER.User}/SaveClientUserMapping`),
  SaveK1ClientDocuments: createApiUrl(`${CONTROLLER.Document}/SaveK1ClientDocuments`),
  SendEmailToInvestor: createApiUrl(`${CONTROLLER.Investor}/SendEmailToInvestor`),
  SwapInvestor: createApiUrl(`${CONTROLLER.Investor}/SwapInvestor`),
  UserConfiguration: {
    GetUserConfigurationDetails: createApiUrl(
      `${CONTROLLER.UserConfiguration}/GetUserConfigurationDetails`
    ),
    GetUserOrganizationDetails: createApiUrl(
      `${CONTROLLER.UserConfiguration}/GetUserOrganizationDetails`
    ),
    GetAccessGroups: createApiUrl(`${CONTROLLER.UserConfiguration}/GetAccessGroups`),
    Authenticate: createApiUrl(`${CONTROLLER.UserConfiguration}/Authenticate`),
    VerifyOTP: createApiUrl(`${CONTROLLER.UserConfiguration}/VerifyOTP`),
    ResendOTP: createApiUrl(`${CONTROLLER.UserConfiguration}/ResendOTP`),
    ForgotPassword: createApiUrl(`${CONTROLLER.UserConfiguration}/ForgotPassword`),
    GetInvestorUserDetailsByToken: createApiUrl(`${CONTROLLER.UserConfiguration}/GetInvestorUserDetailsByToken`),
    ContactUs: createApiUrl(`${CONTROLLER.UserConfiguration}/ContactUs`),
    SetSuccessfullyLoginAttempts: createApiUrl(`${CONTROLLER.UserConfiguration}/SetSuccessfullyLoginAttempts`)

  },
  UploadExcel: createApiUrl(`${CONTROLLER.Document}/UploadExcel`),
  MatchInvestor: createApiUrl(`${CONTROLLER.Document}/MatchInvestor`),
  GetUserActivity: createApiUrl(`${CONTROLLER.User}/GetUserActivity`)
}
export const API_HEADERS = {
  authName: 'Bearer',
  authToken: 'adal.idtoken',
  CID: 'CID',
  EMAIL: 'Email',
  GROUPNAME: 'GroupName'
}
