import React, { Component } from 'react';
import FileUpload from '../../../common/component/file-upload/fileUpload';
import { UploadFileConstant } from '../../../common/constants/index';
import { getBackYearFromDate } from '../../../common/component/utils/date';
import { apiGet,processK1Documents, SaveK1ClientDocuments } from "../../../common/component/utils/api-service";
import { API_URL } from '../../../common/constants/apiConfiguration';
import {
    Button,
    FormHelperText,
    Select,
    MenuItem,
    TextField,
    Chip
  } from "@material-ui/core/";
import './add-file.less';
import { ShowToast, toastType } from "../../../common/component/utils/toast-service";
import Autocomplete from '../../../common/component/autocomplete/autocomplete'
import {BlueCheckbox} from '../../../common/component/check-box/checkBox'
import DocumentTypes from '../../../common/constants/DocumentTypes';
  
class AddFile extends Component {
    constructor(props) {        
        super(props);
        this.state = {
            type: '',
            fileName: '',
            fileSetting: '',
            taxYearList: getBackYearFromDate(5),
            taxYear: '',
            documentTypeList: [],
            files: {},
            filePassword: '',
            isPasswordProtected: true,
            errors: {
                type: '',
                fileName: '',
                filePassword: '',
                taxYear: ''
            },
            investorName: [],
            investorValue: [],
            documentTypeName: '',
        }
    }
    componentDidMount() {
        fetch('/config/file-upload-setting.txt')
            .then((res) => res.json())
            .then((data) => {
                this.setState({ fileSetting: data });
            });
        this.getDocumentTypes();
    }
    getDocumentTypes() {
        apiGet({ url: API_URL.GetDocumentTypes }).then((data) => {
			if(data) {
				this.setState({ documentTypeList: data.data });
			}
		});
    }
    SelectInvestor = (investorName, values, investorId) => {
        this.setState({investorName : investorName, investorValue: values})
    }
    setDocumentState = (key) => {
        const {documentTypeName} = this.state
        if (key != null) {
            if (documentTypeName === DocumentTypes.MasterK1File) {
                this.setState({ files: key, fileName: key[0].name });
            } else {
                this.setState({ files: key, fileName: key.length === 1 ? key[0].name : key.length + ' files selected' });
            }
        } else {
            this.setState({ files: {}, fileName: '' });
        }
    }
    onChangeHandle = (e, isPrevent = true) => {
        let name = '', value = '';
        if (isPrevent) {
            e.preventDefault();
            name = e.target.name;
            value = e.target.value;
        } else {    
            name = e.name;
            value = e.value;       
        }
        let errors = this.state.errors;
        let documentTypeName = ''
        const {documentTypeList, investorName, investorValue, isPasswordProtected} = this.state
        switch (name) {
            case 'type': 
              errors.type = 
                value == ''
                  ? UploadFileConstant.TypeMessage
                  : '';
              errors.fileName = 
                value == ''
                  ? ''
                  : '';
             if ( value !== '') {
                this.setState({fileName: '', files: {}})
                var valueData = documentTypeList.find(x=> x.DocumentTypeId === value);
                if (valueData) {
                   documentTypeName = valueData.Name;
                   let investorName1 = investorName; let investorValue1 = investorValue; let isPasswordProtected1=isPasswordProtected;
                   documentTypeName === DocumentTypes.MasterK1File ? '' : investorName1 = []; investorValue1 = [];
                   documentTypeName === DocumentTypes.MasterK1File ? isPasswordProtected1 = true : isPasswordProtected1 = false;
                   documentTypeName === DocumentTypes.ProcessedK1File ?  errors.filePassword = '' : ''
                   this.setState({documentTypeName: documentTypeName,investorName:investorName1,investorValue: investorValue1, isPasswordProtected:isPasswordProtected1})
                }
             } else {
                this.setState({documentTypeName: '',investorName:[],investorValue: []})
             }
              break;
            case 'fileName': 
              errors.fileName = 
                value == ''
                  ? UploadFileConstant.K1MasterFileError
                  : '';
              break;
            case 'filePassword': 
                errors.filePassword = 
                    value == ''
                        ? UploadFileConstant.FilePasswordErrorMessage
                        : '';
              break;
            case 'taxYear': 
              errors.taxYear = 
                  value == ''
                      ? UploadFileConstant.TaxYearMessage
                      : '';
            break;
            default:
              break;
          }
          name == 'type' &&  value == '' ? this.setState({fileName: '', files: {}}) : '';
          this.setState({errors, [name]: value});
    }
    onClick = (e) => {
        let errors = this.state.errors;
        errors.filePassword = '';
        this.setState({errors, [e.target.name]: e.target.checked, filePassword: '' });
    }
    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
          (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    handleSubmit = (e) => {
        e.preventDefault();  
        const { type, isPasswordProtected, documentTypeName, fileName } = this.state;    
        fileName === '' ? this.onChangeHandle(e.target.elements.type, false) : '';
        type != '' ?  this.onChangeHandle(e.target.elements.fileName, false) : '';
        isPasswordProtected ? this.onChangeHandle(e.target.elements.filePassword, false) : '';
        this.onChangeHandle(e.target.elements.taxYear, false)
        if(this.validateForm(this.state.errors)) {
            documentTypeName === DocumentTypes.MasterK1File ? this.UploadMasterDocument() : this.UploadClientDocument()
        } 
    }
    
    UploadMasterDocument = (e) => {
        const { taxYear, files, fileSetting, filePassword,investorName, type } = this.state;
        const { investorList } = this.props;
        const investorId = [];
        for(let i=0; i< investorName.length; i++) {
            investorList.filter((item)=>{ 
                 if (item.maskedName == investorName[i]) {
                    investorId.push(item.clientId)
                 }
            });
        }
        processK1Documents(API_URL.processK1Documents, {files, taxYear,filePassword,investorId,type}).then((data) => {
          if (data.success) {
            ShowToast(fileSetting.FileUploadSuccess,  toastType.SUCCESS, 5000);
            this.ResetForm();
            this.props.getMasterDocuments();
          } else {
            if (data.message.includes(UploadFileConstant.PdfHeaderNotFound))
            {
                ShowToast(UploadFileConstant.FileNotValid,  toastType.ERROR, 5000);
            } else {
                ShowToast(data.message,  toastType.ERROR, 5000);  
            }
          }
        });
    };

    UploadClientDocument = (e) => {
        const { taxYear, files, fileSetting, filePassword,type } = this.state;
        SaveK1ClientDocuments(API_URL.SaveK1ClientDocuments, {files, taxYear,filePassword,type}).then((data) => {
          if (data.success) {
            ShowToast(fileSetting.FileUploadSuccess,  toastType.SUCCESS, 5000);
            this.ResetForm();
            this.props.getMasterDocuments();
          } else {
            if (data.message.includes(UploadFileConstant.PdfHeaderNotFound))
            {
                ShowToast(UploadFileConstant.FileNotValid,  toastType.ERROR, 5000);
            } else {
                ShowToast(data.message,  toastType.ERROR, 5000);  
            }
          }
        });
    };
    ResetForm = () => {
        this.setState({
            type: '',
            taxYear: '',
            files: {},
            fileName: '',
            filePassword: '',
            errors: {},
            investorName: [],
            investorValue: [],
            isPasswordProtected: true,
            documentTypeName: '',
        })
    }
    render() {   
        const { investorList } = this.props;
        const { type,fileName, fileSetting, errors, taxYearList, taxYear, filePassword, isPasswordProtected, 
                documentTypeList, investorName,investorValue, documentTypeName } = this.state;
        let inputStyle = {
        };
        !isPasswordProtected ? inputStyle = { backgroundColor: "#eaeef1"  }  : inputStyle;

        return (
            <div className="m-4 border shadow-sm border-theme-color background-theme-color rounded">
                <form onSubmit={this.handleSubmit} noValidate>
                <div className="row m-2  mt-4 multi-select">
                        <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UploadFileConstant.Type} <span className="text-red">*</span></label>
                        <div className="col-sm-3 col-xs-12">
                            <Select
                                displayEmpty
                                className="w-100 text-blue"
                                name = 'type'
                                value = {type}
                                onChange = {this.onChangeHandle}
                                error = {errors.type}
                            >
                                <MenuItem className="text-blue" value=""> {UploadFileConstant.SelectType }</MenuItem>
                                {
                                    documentTypeList.map(documentType => {
                                        return (
                                            <MenuItem className="text-blue" key = {documentType.DocumentTypeId} value={documentType.DocumentTypeId}> {documentType.Name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <FormHelperText className="text-red">
                                {errors.type}
                            </FormHelperText>
                        </div>
                        <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UploadFileConstant.MasterK1File} <span className="text-red">*</span></label>
                        <div className="col-sm-3 col-xs-12">
                            <TextField 
                                placeholder = {UploadFileConstant.K1FilePlaceholder}
                                variant="outlined"
                                type="text"
                                maxLength={100}
                                value={fileName}
                                name="fileName"
                                InputProps={{
                                    readOnly: true, 
                                    style: { backgroundColor: "#eaeef1"  },  
                                    className: "text-blue"               
                                }}
                                className = "col-sm-12 col-xs-12 input-style"
                                error = {errors.fileName}
                            />
                            <FormHelperText className="text-red">
                                {errors.fileName}
                            </FormHelperText>
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <FileUpload parent = 'AddFile' documentTypeName = {documentTypeName} fileSetting={fileSetting} disabled = {type == ''} setDocument = {this.setDocumentState}/>
                        </div> 
                    </div>
                    <div className="form-group row m-2 multi-select">
                        <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UploadFileConstant.IsPasswordProtected}</label>
                        <div className="col-sm-3 col-xs-12">
                            <BlueCheckbox disabled = {documentTypeName === DocumentTypes.ProcessedK1File} name="isPasswordProtected" checked ={isPasswordProtected} onChange= {this.onClick}/>                           
                        </div>
                        <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UploadFileConstant.FilePassword} {isPasswordProtected ? <span className="text-red">*</span> : ''}</label> 
                        <div className="col-sm-3 col-xs-12">
                            <TextField 
                                placeholder = {UploadFileConstant.FilePassword}
                                variant="outlined"
                                type="password"
                                maxLength={4}
                                value={filePassword}
                                name="filePassword"
                                onChange = {this.onChangeHandle}
                                InputProps={{
                                    className: "text-blue"               
                                }}
                                onInput = {(e) =>{
                                    e.target.value= e.target.value <= 4 ? e.target.value : e.target.value.substring(0, 4)
                                }}
                                className = "col-sm-12 col-xs-12 input-style text-blue"
                                disabled = {!isPasswordProtected}
                                readOnly = {!isPasswordProtected}
                                style={inputStyle}
                                error = {errors.filePassword}
                            />
                            <FormHelperText className="text-red">
                                {errors.filePassword}
                            </FormHelperText>
                        </div> 
                    </div>
                  
                    <div className="form-group row m-2">
                        <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UploadFileConstant.TaxYear} <span className="text-red">*</span></label>
                        <div className="col-sm-3 col-xs-12">
                            <Select
                                displayEmpty
                                className="w-100 text-blue"
                                name = 'taxYear'
                                value = {taxYear}
                                onChange = {this.onChangeHandle}
                                error = { errors.taxYear}
                            >
                                <MenuItem className="text-blue" value=""> {UploadFileConstant.SelectTaxYear }</MenuItem>
                                {
                                    taxYearList.map(year => {
                                        return (
                                            <MenuItem className="text-blue" key = {year} value={year}> {year}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <FormHelperText className="text-red">
                                {errors.taxYear}
                            </FormHelperText>
                        </div>
                    </div>
                    { documentTypeName === DocumentTypes.MasterK1File ? <>
                    <div className="form-group row m-2">
                        <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UploadFileConstant.SelectInvestor}</label>
                        <div className="col-sm-6 col-xs-12">
                            <Autocomplete value = {investorValue} tagLimits = {1} placeholder={UploadFileConstant.SelectInvestor} options = {investorList} selectedValue = {this.SelectInvestor}/>
                            <FormHelperText className='text-blue'><span style={{fontWeight: 'bold'}}>{UploadFileConstant.Note}</span>{UploadFileConstant.InvestorNote}</FormHelperText>

                        </div>
                    </div>
                    <div className="form-group row m-2">
                        <div className="col-sm-12 col-xs-12">
                        {
                            investorName.map((investor, i ) => {
                            return (
                                <Chip key= {i} style = {{backgroundColor: '#d6e7f3f2', margin: '5px', color: '#064473'}}  label= {investor} />                        )
                            })
                        }
                        </div>
                    </div></> : '' }
                    <div className="form-group row m-2 mb-3">
                        <div className="col-sm-2 col-xs-2"></div>
                        <div className="col-sm-2 col-xs-3">
                            <Button variant="contained" className="btn btn-sm btn-blue w-100" type="submit">
                                {UploadFileConstant.ProcessK1}
                            </Button>
                        </div>
                        <div className="col-sm-2 col-xs-3">
                            <Button variant="contained" className="btn btn-sm btn-blue w-100" type="button" onClick={this.ResetForm}>
                                {UploadFileConstant.Clear}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default AddFile;