import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './container/app'
import './styles/main.less'
import { Provider } from 'react-redux'
import store from './store/appStore'
import Favicon from 'react-favicon'


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Favicon url="/resources/images/favicon.ico" />
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
)
