import Axios from "axios";
import { API_URL, API_HEADERS } from "../../constants/apiConfiguration";
import { apiGetAsync } from "../../component/utils/api-service";
import * as actionType from "../../../store/layout/actionTypes";

export const getUserData = (query) => (dispatch) => {
    apiGetAsync({
    url: API_URL.UserConfiguration.GetUserOrganizationDetails,
  }).then(({ data: response }) => {
    var cloudOrg = (response || [])[0] || {};
    localStorage.setItem(API_HEADERS.CID, cloudOrg.cloudOrgID);
    apiGetAsync({
      url: API_URL.UserConfiguration.GetUserConfigurationDetails,
    }).then(({ data: response }) => {
      dispatch({
        type: actionType.GET_USER_DATA,
        payload: response,
      });
    });
  });
};

export const setLoadingScreen = (isLoading) => (dispatch) => {
  return dispatch({
    type: actionType.SET_LOADER_SCREEN,
    payload: isLoading,
  });
};
