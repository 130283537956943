import React, { Component } from 'react'
import { Status, UserConstant } from '../../../common/constants/index'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextareaAutosize,
  withStyles,  
  FormHelperText,
  Checkbox
} from '@material-ui/core'
import Loader from '../loader/loader'
import { apiGet } from "../../component/utils/api-service";
import { ShowToast, toastType } from "../../component/utils/toast-service";
import { API_URL } from '../../../common/constants/apiConfiguration'
import {BlueCheckbox} from '../../../common/component/check-box/checkBox'

class ModalPopupAdditionalComment extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fullWidth: true,
      maxWidth: 'sm',
      additionalComment: '',
      loading: false,
      isSendApproveConfirmation: false,
      errors: {
        additionalComment: ''
      }
    }
  }
  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.handleChange(e.target.elements.additionalComment, false)
    if (this.validateForm(this.state.errors)) {
        this.ApproveRejectUser();
        this.clearField();
    }
  }

  ApproveRejectUser = () => {
    const {actionType, data} = this.props;
    const {additionalComment, isSendApproveConfirmation} = this.state;
    const params = {
        UserId: data.UserId,
        action: actionType == "Approve" ? Status.Approved : actionType,
        InvestorId: data.ClientId,
        message: additionalComment,
        isSendApprovedMailToInvestor: isSendApproveConfirmation,
        requestType: data.RequestType
    }
    apiGet({ url: API_URL.ApproveRejectUser , params}).then((data1) => {
        if (data1.success) {
          if (actionType == "Approve") {
            ShowToast(UserConstant.ApproveSuccess,  toastType.SUCCESS, 5000);
          } else {
            ShowToast(UserConstant.RejectSuccess,  toastType.SUCCESS, 5000);
          }
          this.props.getPendingUser();
          this.props.getUsers();
        }
    });
  }
  clearField = () => {
    this.props.openPopup(false);
    this.setState({additionalComment: '', isSendApproveConfirmation: false, errors: {}})
  }
  handleChange = (e, isPrevent = true) => {
    let name = '', value = '';
    const {actionType, data} = this.props;

    if (isPrevent) {
        e.preventDefault();
        name = e.target.name;
        value = e.target.value;
    } else {    
        name = e.name;
        value = e.value;       
    }
    let errors = this.state.errors;
    if (actionType == 'Reject') {
        switch (name) {
            case 'additionalComment': 
              errors.additionalComment = 
                value == ''
                  ? UserConstant.AdditionalCommentError
                  : '';
              break;
              default:
                break;
        }
    }
    
    this.setState({errors, [name]: value})
  }
  onClick = (e) => {
    this.setState({[e.target.name]: e.target.checked });
}
  render () {
    const {
      fullWidth, maxWidth, additionalComment, loading, errors
    } = this.state
    const { IsOpen, actionType,isSendApproveConfirmation, data } = this.props
    return (<>
    <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        disableBackdropClick
        open={IsOpen}
        aria-labelledby="customized-dialog-title"
        onClose={() => this.props.openPopup(false)}
    >
       <Loader loading = {loading} />
       <DialogTitle id="customized-dialog-title" className="text-blue font-weight-bold" onClose={() => this.props.openPopup(false)}>
         <span className="text-blue font-weight-bold">{actionType} {data.UserName}?</span>
        </DialogTitle>
        <form onSubmit={this.handleSubmit} noValidate>
        <DialogContent dividers>
            <DialogContentText className="text-blue"> 
            {actionType == 'Approve' ? 
            <>
            <div className="row m-1">
              <div className="col-md-1 col-sm-12">
                <BlueCheckbox name="isSendApproveConfirmation" checked ={isSendApproveConfirmation} onChange= {this.onClick}/>                           
                
               </div>
              <label className="col-md-8 col-sm-12 col-form-label text-blue font-weight-bold"> {UserConstant.SendApprovalConfirmationToInvestor}</label>
           
              
            </div> </>: '' }
            <div className="row m-1">
              <label className="col-md-12 col-sm-12 col-form-label text-blue font-weight-bold"> {UserConstant.AdditionalComment}</label>
            </div>
            <div className="row m-1">
              <div className="col-md-10 col-sm-12">
                    <TextareaAutosize
                        rowsMin ={4}
                        variant="outlined"
                        className = 'w-100'
                        name = 'additionalComment'
                        value = {additionalComment}
                        InputProps={{
                          className: 'text-blue'
                        }}
                        onChange = {this.handleChange}
                    />
                 <FormHelperText className="text-red">
                      {errors.additionalComment}
                  </FormHelperText>
               </div>
            </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className="btn btn-sm btn-blue" type="submit">
                {actionType == 'Approve' ? UserConstant.Approve : UserConstant.Reject}
            </Button>
                  
            <Button className="btn btn-sm btn-blue" onClick={() => { this.clearField() }} type="button">
                 {UserConstant.Cancel}
            </Button>
        </DialogActions>
        </form>
    </Dialog>
    </>)
  }
}

export default ModalPopupAdditionalComment
