import React, { Component } from 'react'
import Table from '../../common/component/table/table'
import { apiGet } from "../../common/component/utils/api-service"
import { API_URL } from '../../common/constants/apiConfiguration'
import ConfirmDialog from '../../common/component/dialog-box/ConfirmDialog'
import {Status, UserConstant} from '../../common/constants/index'
import { ShowToast, toastType } from "../../common/component/utils/toast-service";
import AddEditUser from './add-edit/add-edit-user'
import ModalPopupAdditionalComment from '../../common/component/modal-popup-additional-comment/ModalPopupAdditionalComment'
import store from '../../store/appStore'
import * as layoutActionType from '../../store/layout/actionTypes'

class Users extends Component {
  constructor (props) {
    super(props)
    this.state = {
      FilteredPendingUsers:[],
      OriginalPendingUsers: [],
      FilteredUsers: [],
      OriginalUsers: [],
      isOpenConfirmationPanel: false,
      IsOpen: false,
      message: '',
      actionType: '',
      UserId: 0,
      UserName: '',
      IsAdditionalCommentOpen: false, 
      data: '',
      statusList: [],
      investorList: [],
      relationList: [],
      userNameValue: '',
      investorName: '',
      status: ''
    }
  }

  componentDidMount () {
    this.GetUsers();
    this.GetPendingUser();
    this.getMaster();
  }
  getMaster() {
    const params = {
      isInvestors: true,
      isRelation: true,
      isStatus: true
    }
    apiGet({ url: API_URL.masterData , params}).then((data) => {
    if(data && (data.status.success)) {
      const status = data.status.data.filter((item) => {
        if (item.Name !== Status.Pending && item.Name !== Status.Completed && item.Name !== Status.Unmatched)
         return item;
     });
      this.setState({ statusList: status, investorList: data.client.data, relationList: data.relationData.data });
    }
    });
 }
  GetPendingUser = () => {
    apiGet({ url: API_URL.GetPendingUserList }).then((data) => {
        if (data.success) {
            var masterUserData =  data.data;
            for (var i in  masterUserData) {
              masterUserData[i].Action = {UserId: masterUserData[i].UserId, UserName: masterUserData[i].Name, ParentName: "PendingUserList", Action: ["Approve", "Reject"],
                                              Active:masterUserData[i].IsActive, Status:masterUserData[i].ApproveStatus, ClientId: masterUserData[i].ClientId, RequestType: masterUserData[i].RequestType}
              masterUserData[i].IsActive = {IsActive:masterUserData[i].IsActive, UserId:masterUserData[i].UserId, UserName: masterUserData[i].Name, ClientId: masterUserData[i].ClientId}
              var modifiedDate  = new Date(masterUserData[i].ModifiedDate);
              masterUserData[i].ModifiedDate = modifiedDate.toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false})
                                                   + ' ' + masterUserData[i].TimeZone;
              masterUserData[i].Investor = masterUserData[i].Investor != undefined ?  masterUserData[i].Investor.split('|'): [];
              masterUserData[i].InvestorId = masterUserData[i].ClientId;
            }
            store.dispatch({type: layoutActionType.SET_COUNT, count: masterUserData.length})
            this.setState({ OriginalPendingUsers: data.data, FilteredPendingUsers: masterUserData });      
        }
      });
  }
  GetUsers = () => {
    const { userNameValue,  investorName, status} = this.state
    apiGet({ url: API_URL.GetUsers }).then((data) => {
        if (data.success) {
            var masterUserData =  data.data;
            for (var i in  masterUserData) {
              masterUserData[i].Action = {UserId: masterUserData[i].UserId, UserName: masterUserData[i].Name, ParentName: "UserList", Action: ["Edit", "Delete"],
                                              Active:masterUserData[i].IsActive, Status:masterUserData[i].Status.trim(), ClientId: masterUserData[i].ClientId}
              const isDisabled = (masterUserData[i].Status.trim() === Status.Removed || masterUserData[i].Status.trim() === Status.Rejected) ? true : false;
              masterUserData[i].IsActive = {IsActive:masterUserData[i].IsActive, UserId:masterUserData[i].UserId, UserName: masterUserData[i].Name , IsDisabled:isDisabled, ClientId: masterUserData[i].ClientId }
              var createdDate  = new Date(masterUserData[i].CreatedDate);
              masterUserData[i].CreatedDate = createdDate.toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false})
                                                   + ' ' + masterUserData[i].TimeZone;
              masterUserData[i].InvestorName = masterUserData[i].InvestorName; // masterUserData[i].Investor != undefined ? masterUserData[i].Investor.split(", ") : [];
              masterUserData[i].ClientId = masterUserData[i].ClientId;

              if (masterUserData[i].LastLogin != null) {
                var lastLogin  = new Date(masterUserData[i].LastLogin);
                masterUserData[i].LastLogin = lastLogin.toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false})
                                                     + ' ' + masterUserData[i].TimeZone;
              }               
            }
            const param = { userName: userNameValue,  investorName, status}
            const filteredList = this.FilterListUsingParam(param, masterUserData);
            this.setState({ OriginalUsers: data.data, FilteredUsers: filteredList });      
        }
      });
  }

  TakeAction = (actionType, data) => {
    if (actionType == 'Approve' || actionType == 'Reject') {
      this.setState({IsAdditionalCommentOpen: true, data: data, actionType: actionType});
    } else if (actionType == 'Edit' ) {
      this.setState({IsOpen: true, UserId: data.UserId, actionType: actionType, ClientId: data.ClientId});
    } else if (actionType == 'Delete') {
      const message  = UserConstant.DeleteUserMessage + ' "' + data.UserName + '"?'
      this.setState({isOpenConfirmationPanel: true, message: message, 
        actionType: actionType, UserId: data.UserId, UserName: data.UserName, ClientId: data.ClientId});
    } 
  }
  ArchiveUser = () => {
    const {UserId, UserName, ClientId } = this.state;
    const params = {
        UserId: UserId,
        ClientId: ClientId
    };
    apiGet({ url: API_URL.ArchiveUser,params }).then((data) => {
      if(data.success) {
        this.GetUsers();
        ShowToast(UserName + UserConstant.UserDeleteSuccess ,  toastType.SUCCESS, 5000);
        this.setState({isOpenConfirmationPanel: false, message: "", actionType: '', K1ClientDocumentId: '', UserName: ''});          
      }
    });
  }
  PerformAction = (value) => {
    const {actionType, UserName, UserId } = this.state;
    if (value && actionType === 'Delete' ) {
      this.ArchiveUser() ;
    } else if (value && (actionType === 'Activate' || actionType ===  'Deactivate')){
      const message =  UserName + (actionType ===  'Deactivate' ? 
                                UserConstant.DeactivateSuccess : UserConstant.ActivateSuccess)
      this.ActivateDeactivateUser(UserId, actionType, message)
    } else {
      this.setState({isOpenConfirmationPanel: false, message: "", actionType: '', UserId: 0, UserName: ''})
    }
  }
  OpenAddEditPop = ( isOpen) => {
      this.setState({IsOpen: isOpen, UserId: 0 })
  }
  ActivateOrDeactivate = (e, data) => {
    console.log(data)
    if (!e.target.checked) {
      const message  = UserConstant.Confirmation + ' "' + data.UserName + '" inactive?'
       this.setState({isOpenConfirmationPanel: true, message: message, 
        actionType: 'Deactivate', UserId: data.UserId, UserName: data.UserName, ClientId: data.ClientId});
    } else {
     const message  = UserConstant.Confirmation + ' "' + data.UserName + '" active?'  
     this.setState({isOpenConfirmationPanel: true, message: message, 
      actionType: 'Activate', UserId: data.UserId, UserName: data.UserName, ClientId: data.ClientId});
    }
  }
  ActivateDeactivateUser = (userId, action, message) => {
    const params = {
      UserId: userId,
      action: action,
      ClientId: this.state.ClientId
    };
    apiGet({ url: API_URL.ActivateDeactivateUser,params }).then((data) => {
      if(data.success) {
        this.GetUsers();
        this.setState({isOpenConfirmationPanel: false, message: "", actionType: '', UserId: '', UserName: ''})
        ShowToast(message ,  toastType.SUCCESS, 5000);
      }
    });
  }
  FilterListUsingParam = (param, originalList) => {
    var filteredList = originalList;
   
    if (filteredList.length > 0 && param.userName != "" && param.userName.length >= 0) {
      filteredList = filteredList.filter((item) => {
        if (item.Name != null && item.Name.trim().toLowerCase().includes(param.userName.trim().toLowerCase()))
         return item;
     });
    }
    if (filteredList.length > 0 && param.investorName != "" && param.investorName.length >= 0) {
        filteredList = filteredList.filter((item) => {
          if (param.investorName.includes(item.InvestorName.trim())) {
            return item;
          }
      });
    }
     if (filteredList.length > 0 && param.status !== '') {
       filteredList = filteredList.filter((item) => {
         if (item.Status == param.status)
            return item;
       });
     }
     return filteredList;
  }
  FilterList = (param) => { 
    const { OriginalUsers } = this.state;
    const filteredList = this.FilterListUsingParam(param, OriginalUsers);
    this.setState({ FilteredUsers: filteredList , userNameValue: param.userName,  investorName: param.investorName, status: param.status });
  }
  OpenAdditionalComment = (open) => {
    this.setState({IsAdditionalCommentOpen: open});
  }
  render () {
    const { FilteredUsers, FilteredPendingUsers, IsOpen, isOpenConfirmationPanel, message, UserId, actionType,
            IsAdditionalCommentOpen,data, investorList, relationList, statusList, ClientId } = this.state
    return (
            <>
             <Table
                openPopup = {this.OpenAddEditPop}
                header = {UserConstant.pendingHeader}
                investorList = {[]}
                statusList= {[]}
                columns={UserConstant.PendingUserCells}
                data={FilteredPendingUsers}
                performAction = {this.TakeAction}
                filterList = {this.FilterList}
                handleChange = {this.ActivateOrDeactivate}
              />
              <Table
                openPopup = {this.OpenAddEditPop}
                header = {UserConstant.header}
                investorList = {investorList.filter(x=>x.maskedName !== 'All')}
                statusList= {statusList}
                columns={UserConstant.UserCells}
                data={FilteredUsers}
                performAction = {this.TakeAction}
                filterList = {this.FilterList}
                handleChange = {this.ActivateOrDeactivate}
              />
              <AddEditUser investorList = {investorList} relationList = {relationList} actionType = {actionType} getUsers = {this.GetUsers} UserId = {UserId} IsOpen = {IsOpen}  openPopup = {this.OpenAddEditPop} ClientId = {ClientId}/>
              <ConfirmDialog
                  IsOpen={isOpenConfirmationPanel}
                  Message={message}
                  OnHandelClick={this.PerformAction}
                  AgreeText = "OK"
                  DisAgreeText = "CANCEL"
              />
              <ModalPopupAdditionalComment 
                actionType = {actionType} 
                data = {data}  
                IsOpen = {IsAdditionalCommentOpen}  
                openPopup = {this.OpenAdditionalComment}
                getUsers = {this.GetUsers}
                getPendingUser = {this.GetPendingUser}
                />
            </>
    )
  }
}

export default Users
