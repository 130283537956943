import React from 'react'
import './style.less'
import { FaBuilding } from 'react-icons/fa'
import { authContext } from '../../configurations/adalConfig'
import { NavLink } from 'react-router-dom'
import { API_HEADERS } from '../../constants/apiConfiguration'

export default class ErrorPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      errorCode: props.details.code,
      heading: props.details.heading,
      message: props.details.message,
      goToHomeEnabled: props.details.goToHomeEnabled
    }
  }
  handleLogOut = () => {
    authContext.logOut();
    localStorage.removeItem[API_HEADERS.authToken];
    localStorage.removeItem(API_HEADERS.EMAIL);
    localStorage.removeItem(API_HEADERS.GROUPNAME);
    window.location = "/"; 
  };

  render () {
    const { errorCode, heading, message, goToHomeEnabled } = this.state
    return (
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <FaBuilding size="140px" width="5em" />
          </div>
          <h1>{errorCode}</h1>
          <h2>{heading}</h2>
          <p>{message}</p>
          {goToHomeEnabled && errorCode === 401
            ? (
            <NavLink to={'/login'} onClick={this.handleLogOut}> <a
              href="#"
              onClick={this.handleLogOut}
            >
                Sign In
            </a>
            </NavLink>
              )
            : (
              <NavLink to={'/login'} onClick={this.handleLogOut}> 
                <a href="#">Back to homepage</a>
              </NavLink>
              )}
        </div>
      </div>
    )
  }
}
