export default {

  pageNotFound: {
    heading: 'Oops! Page Not Found',
    message: 'Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable',
    code: 404,
    goToHomeEnabled: true
  },
  serverError: {
    heading: 'Oops! Something went wrong',
    message: 'Sorry something went wrong at server side, either refresh the page or try after some time. If issue still persist please contact your site administrator.',
    code: 500,
    goToHomeEnabled: true
  },
  unAuthorizedAccess: {
    heading: 'Unable to Access',
    message: 'Sorry it seems you are not able to access portal/resources, please contact site administrator.',
    code: 403,
    goToHomeEnabled: false
  },
  sessionTimeOut: {
    heading: 'Session Timeout',
    message: 'Sorry it seems your session is no more active, please sign in again.',
    code: 401,
    goToHomeEnabled: true
  }
}
