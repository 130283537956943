export default {
  InvestorCells: [
    { key: 'Name', name: 'Name' },
    { key: 'K1ReferenceName', name: 'Tax ID', type: 'Hidden' },
    { key: 'Email', name: 'Email' },
    { key: 'InvestorType', name: 'Investor Type' },
    { key: 'LastLogin', name: 'Last Login' },
    { key: 'Address', name: 'Address' },
    { key: 'TotalUsers', name: 'User Count' },
    { key: 'IsActive', name: 'Active' },
    { key: 'Deleted', name: 'Is Deleted' },
    { key: 'Action', name: 'Actions' }
  ],
  InvitationCells: [
    { key: 'Name', name: 'Name' },
    { key: 'Relation', name: 'Relation' },
    { key: 'Email', name: 'Email' },
    { key: 'TaxYear', name: 'Applicable Tax Year' },
    { key: 'InvitedOn', name: 'Requested On' },
    { key: 'RequestType', name: 'Request Type' }
  ],
  InvitedOn: 'Invited On',
  header: 'Investors',
  InvitationHeader: 'Invitation History',
  AddInvestor: 'Add Investor',
  EditInvestor: 'Edit Investor',
  SwapInvestor: 'Swap Investor',
  MatchInvestor: 'Match Investor',
  Save: 'Save',
  Swap: 'Swap',
  Update: 'Update',
  Cancel: 'Cancel',
  InvestorName: 'Investor Name',
  MasterFileName: 'Master File Name',
  EmailAddress: 'Email Address',
  StreetAddress1: 'Address Line 1',
  StreetAddress2: 'Address Line 2',
  Town: 'City',
  State: 'State',
  Zipcode: 'Zipcode',
  ClientType: 'Client Type',
  Year: 'Year',
  IsAllK1Swap: 'Is Swap All K-1',
  InvestorNameError: 'Investor name is required.',
  EmailError: 'Email address is required.',
  StreetAddress1Error: 'Address line 1 is required.',
  TownError: 'City is required.',
  StateError: 'State is required.',
  ZipcodeError: 'Zipcode is required.',
  ClientTypeError: 'Client type is required.',
  ValidEmailAddress: 'Please enter valid email address.',
  Status: 'Status',
  ActiveDropDown: [
    { Id: 0, Value: 'Inactive' },
    { Id: 1, Value: 'Active' }
  ],
  Email: 'Email',
  Address: 'Address',
  TotalUsers: 'User Count',
  IsActive: 'Active',
  K1referenceName: 'Tax ID',
  ReferenceNameError: 'Tax ID is required.',
  ReferenceNameValidError: 'Tax ID is not valid.',
  FirstName: 'First Name',
  LastName: 'Last Name',
  InvestorTypeError: 'Investor type is required.',
  YearError: 'Year is required',
  FirstNameError: 'First name is required.',
  LastNameError: 'Last name is required.',
  EmailIdAlreadyTaken: 'Email Id already present in the system.',
  EmailIdSameTaken: 'Kindly change Email Id. Changing value of other field(s) is optional.',
  K1ReferenceAlreadyTaken: 'Tax ID already present in the system.',
  Yes: 'Yes',
  No: 'No',
  InvestorNote: 'Tax ID is used to process K-1 files, please make sure it is in the correct format.',
  IsDeleted: 'Is Deleted',
  ShowAllVersion: 'Show All Version',
  InvestorType: 'Investor type',
  Name: 'Legal Entity Name',
  NameError: 'Legal entity name is required.'
}
