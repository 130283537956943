import React from 'react'
import './toastMsg.less'
import { ToastContainer } from 'react-toastify'

export default function ToastMessage ({
  position = 'top-center',
  autoClose = 6000,
  hideProgressBar = true
}) {
  return (
        <ToastContainer
            position={position}
            autoClose={autoClose}
            hideProgressBar={hideProgressBar}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
       />
  )
}
