import * as actionType from './actionTypes'

const initialState = {
  showAll: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_SHOW_ALL: {
      return {
        ...state,
        showAll: action.showAll
      }
    }
    default:
      return state
  }
}

export default reducer
