import React from 'react'
import { toast } from 'react-toastify'
import { MdError } from 'react-icons/md'
import { TiWarning } from 'react-icons/ti'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { FaInfoCircle } from 'react-icons/fa'

const iconSize = '1.5em'

function GetTostMsgComponent (message, title, toastType) {
  return (
  <div className="row m-0">
     {toastType && (<div className="col-sm-1 no-padding">{toastType === toast.TYPE.ERROR && <MdError size={iconSize} />}
     {toastType === toast.TYPE.SUCCESS && (<IoMdCheckmarkCircle size={iconSize} />)}
     {toastType === toast.TYPE.INFO && <FaInfoCircle size={iconSize} />}
     {toastType === toast.TYPE.WARNING && <TiWarning size={iconSize} />}
     {toastType === toast.TYPE.DEFAULT && <FaInfoCircle size={iconSize} />}
  </div>)}
    {title && <div className="col-sm-11 toast-title no-padding">{title}</div>}
    {toastType && title ? <div className="col-sm-2">&nbsp;</div> : ''}
    <div className="col-sm-11 toast-sub-message"> {message}</div>
    </div>
  )
}

export function ShowToast (
  message,
  toastType,
  autoclose = 1000,
  messageId = 0,
  title = ''
) {
  if (message) {
    if (!toast.isActive(messageId)) {
      switch (toastType) {
        case toast.TYPE.ERROR:
          toast.error(() => GetTostMsgComponent(message, title, toastType), {
            autoClose: autoclose,
            toastId: messageId
          })
          break
        case toast.TYPE.SUCCESS:
          toast.success(() => GetTostMsgComponent(message, title, toastType), {
            autoClose: autoclose,
            toastId: messageId
          })
          break
        case toast.TYPE.WARNING:
          toast.warn(() => GetTostMsgComponent(message, title, toastType), {
            autoClose: autoclose,
            toastId: messageId
          })
          break
        case toast.TYPE.INFO:
          toast.info(() => GetTostMsgComponent(message, title, toastType), {
            autoClose: autoclose,
            toastId: messageId
          })
          break
        case toast.TYPE.DEFAULT:
        default:
          toast(() => GetTostMsgComponent(message, title, toastType), {
            autoClose: autoclose,
            toastId: messageId
          })
          break
      }
    }
  }
}

export const toastType = toast.TYPE
