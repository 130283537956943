import React, { Component } from 'react';
import Table from '../../common/component/table/table'
import {ScheduledK1Constant} from '../../common/constants/index';
import { apiGet, apiPostAsync, DownloadPdf, ViewPdf } from "../../common/component/utils/api-service";
import { API_URL, API_HEADERS } from '../../common/constants/apiConfiguration';
import ConfirmDialog from '../../common/component/dialog-box/ConfirmDialog';
import { ShowToast, toastType } from "../../common/component/utils/toast-service";
import {convertDateToFormat} from '../../common/component/utils/date'
import { connect } from 'react-redux'
import Status from '../../common/constants/Status'
import AddEditInvestor from '../investors/add-edit/addEditInvestor';
import { IsAdmin } from '../../common/component/utils/helper';
class ScheduledK1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FilteredUserUsageData : [],
            OriginalUserUsageData : [],
            investorList: [],
            statusList: [],
            isOpenConfirmationPanel: false,
            message: '',
            actionType: '',
            K1ClientDocumentId: '',
            investorName: '',
            selectedFilePreview: '',
            startDate: '',
            endDate: '',
            masterFileName: '',
            investorNameValue: '',
            fiscalYear: '',
            status: '',
            showCheckBox: false,
            isRevised: false,
            k1ClientData: {}
        }
    }

    componentDidMount() {
      this.getClientDocuments();
      this.getMaster();
    }

    getMaster() {
        const params = {
          isInvestors: true,
          isStatus: true
        }
        apiGet({ url: API_URL.masterData , params}).then((data) => {
        if(data && (data.client.success || data.status.success)) {
          const status = data.status.data.filter((item) => {
            if (item.Name !== Status.Completed)
             return item;
         });
          this.setState({ investorList: data.client.data, statusList: status });
        }
      });
    }
    getClientDocuments = () => {
      const { showAll }  = this.props;
      const {selectedFilePreview, startDate, endDate,masterFileName,investorNameValue,fiscalYear,status} = this.state;
      apiGet({ url: API_URL.GetClientK1DocumentList }).then((data) => {
        if(data.success) {
          var clientDocumentData =  data.data;
          for (var i in  clientDocumentData) {
            var actionDate  = new Date(clientDocumentData[i].LastProcessed);
            clientDocumentData[i].LastProcessed = actionDate.toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false})
                                                  + ' ' + clientDocumentData[i].TimeZone;
            const actions =  ["View", "Approve","Reject","Delete", "Download", "SendEmail"];
            if (IsAdmin()) {
              actions.push("Match")
            }
                                       
            clientDocumentData[i].Action = {K1ClientDocumentId: clientDocumentData[i].K1ClientDocumentId, investorName:clientDocumentData[i].InvestorName,
                                            Status:clientDocumentData[i].ApproveStatus,  ParentName: "ScheduledK1", Action: actions,
                                          FileName: clientDocumentData[i].FileName, ClientId: clientDocumentData[i].ClientId}
            clientDocumentData[i].FileName = clientDocumentData[i].FileName == null ? 'NA' : clientDocumentData[i].FileName;
            clientDocumentData[i].DocumentName = clientDocumentData[i].DocumentName == null ? 'NA' : clientDocumentData[i].DocumentName;
            clientDocumentData[i].IsRevised = clientDocumentData[i].IsRevised ? ScheduledK1Constant.Revised: ScheduledK1Constant.Original; 
          }

          const param = {selectedFilePreview, startDate, endDate,masterFileName,investorName: investorNameValue,fiscalYear,status, showAll: showAll.showAll}
          const filteredList = this.FilterListUsingParam(param, clientDocumentData)
          this.setState({ OriginalUserUsageData: data.data, FilteredUserUsageData: filteredList });
        }
      });
    }
    
    FilterListUsingParam = (param, OriginalUserUsageData) => {
      var filteredList = OriginalUserUsageData;
    
      if (filteredList.length > 0 && param.masterFileName != "") {
       filteredList = filteredList.filter((item) => {
         if (item.DocumentName != null && item.DocumentName.trim().toLowerCase().includes(param.masterFileName.trim().toLowerCase()))
          return item;
      });
     }
      if (filteredList.length > 0 && param.investorName != null && param.investorName != undefined && param.investorName != "" && param.investorName.length >= 0) {
        filteredList = filteredList.filter((item) => {
         for (let i = 0; i < param.investorName.length; i++) {
           if (item.MaskedName != undefined && item.MaskedName != null && param.investorName[i].includes(item.MaskedName)) {
             return item;
           }
         } 
       });
    }
      if (filteredList && filteredList.length > 0 && param.fiscalYear != "") {
       filteredList = filteredList.filter((item) => {
         return item.FiscalYear == param.fiscalYear ;
       });
    }
    if (filteredList.length > 0 && param.status !== '') {
     filteredList = filteredList.filter((item) => {
       if (item.ApproveStatus == param.status)
          return item;
     });
   }
    if (filteredList.length > 0 && param.showRevised) {
      filteredList = filteredList.filter((item) => {
        if (item.IsRevised == ScheduledK1Constant.Revised)
           return item;
      });
     }
   
  
    return filteredList
    }
    FilterList = (param) => {
      const { OriginalUserUsageData } = this.state;
      const filteredList = this.FilterListUsingParam(param, OriginalUserUsageData)
      this.setState({ FilteredUserUsageData: filteredList, startDate: param.startDate, endDate: param.endDate, masterFileName: param.masterFileName,
        investorNameValue: param.investorName, fiscalYear: param.fiscalYear, status: param.status, selectedFilePreview: ''});    
    }

    TakeAction = (actionType, data) => {
      if (actionType == 'Match') {
        this.setState({IsOpen: true,
                       actionType: actionType, K1ClientDocumentId: data.K1ClientDocumentId, k1ClientData: data});
    } else if (actionType == 'Approve') {
        const Investor  =  data.investorName != null ? ' "' + data.investorName + '"?' : null;
        const message = ScheduledK1Constant.ApproveK1Message + Investor;
        this.setState({isOpenConfirmationPanel: true, message: message, 
                       actionType: actionType, K1ClientDocumentId: data.K1ClientDocumentId, investorName: data.investorName, showCheckBox: true});
    } else if (actionType == 'Delete' || actionType == 'Reject') {
          const Investor  =  data.investorName != null ? ' "' + data.investorName + '"?' : null;
          const message = actionType == 'Delete'  ? (Investor != null ? ScheduledK1Constant.DeleteInvestorK1Message + Investor : ScheduledK1Constant.DeleteK1Message)  : ScheduledK1Constant.RejectK1Message + Investor;
          this.setState({isOpenConfirmationPanel: true, message: message, 
                         actionType: actionType, K1ClientDocumentId: data.K1ClientDocumentId, investorName: data.investorName});
      } else if (actionType == 'Download') {
        this.DownloadPdf(data);
      } else if (actionType == 'View') {
        this.ViewPdf(data.K1ClientDocumentId, data.FileName)
      } else if (actionType == 'SendEmail') {
        this.SendClientK1Email(data);
      }
    }
    PerformAction = (value) => {
      if (value) {
        this.ChangeClientK1DocumentStatus();
      } else {
        this.setState({isOpenConfirmationPanel: false, message: "", actionType: '', K1ClientDocumentId: '', investorName: '', showCheckBox: false, isRevised: false})
      }
    }
    DownloadPdf(documentObj) {
      const params = {
        clientK1DocumentId: documentObj.K1ClientDocumentId,
      };
      DownloadPdf({url: API_URL.DownloadK1ClientDocument,params, fileName: documentObj.FileName +'.pdf'}).then((data) => {
      });
    }

    ViewPdf(k1ClientDocumentId, fileName) {
      const params = {
        clientK1DocumentId: k1ClientDocumentId,
      };
      ViewPdf({url: API_URL.DownloadK1ClientDocument,params}).then((data) => {
         this.setState({selectedFilePreview: data, fileName});
      });
    }
    SendClientK1Email(documentObj) {
      const params = {
        clientK1DocumentId: documentObj.K1ClientDocumentId,
      };
      apiGet({url: API_URL.SendClientK1Email,params}).then((data) => {
        if(data.success) { 
          ShowToast(data.message,  toastType.SUCCESS, 5000);
        } else {
          ShowToast(data.message,  toastType.ERROR, 5000);
        }
      });
    }
    ChangeClientK1DocumentStatus = () => {
      const {K1ClientDocumentId, actionType, investorName, isRevised } = this.state;
      const params = {
        K1ClientDocumentId: K1ClientDocumentId,
        ActionType: actionType,
        isRevised: isRevised
      };
      this.setState({isOpenConfirmationPanel: false});          

      apiGet({ url: API_URL.ChangeClientK1DocumentStatus,params }).then((data) => {
        if(data.success) {
          this.getClientDocuments();
          const message = actionType == 'Approve' ? ScheduledK1Constant.ApproveSuccess + investorName + '.'
                        : actionType == 'Delete'  ? (investorName != null && investorName != undefined ? ScheduledK1Constant.DeleteForSuccess + investorName : ScheduledK1Constant.DeleteSuccess) + '.' : ScheduledK1Constant.RejectSuccess + investorName  + '.';
          ShowToast(message,  toastType.SUCCESS, 5000);
          this.setState({isOpenConfirmationPanel: false, message: "", actionType: '', K1ClientDocumentId: '', investorName: '', showCheckBox: false, isRevised: false});          
        }
      });
    }

    GetColumns = () => {
      if(localStorage.getItem(API_HEADERS.GROUPNAME).toLowerCase().includes('investor')) {
        return ScheduledK1Constant.ClientInvestorUserDocumentCells;
      }

      return ScheduledK1Constant.ClientDocumentCells;
    }

    onClick = (e) => {
      this.setState({[e.target.name]: e.target.checked });
    }

    OpenAddEditPop = ( isOpen) => {
      this.setState({IsOpen: isOpen, ClientId: 0 })
    }
    OnHandelClick = (isCancel, investorId, fileName) => {
      if (isCancel) {
        const {K1ClientDocumentId } = this.state;
        const params = {
          ClientK1DocumentId: K1ClientDocumentId,
          clientId: investorId,
          FileName: fileName
         };
        this.setState({IsOpen: false});          
  
        apiPostAsync({ url: API_URL.MatchInvestor,postBody: params }).then((data) => {
          if(data.success) {
            this.getClientDocuments();
             ShowToast('Investor Matched Successfully.',  toastType.SUCCESS, 5000);
            this.setState({IsOpen: false, message: "", actionType: '', K1ClientDocumentId: '', investorName: '', showCheckBox: false, isRevised: false});          
          }
        });
      } else {
        this.setState({IsOpen: false, message: "", actionType: '', K1ClientDocumentId: '', investorName: '', showCheckBox: false, isRevised: false, k1ClientData: {}});          
      }
     
    }
    render() {

        const { fileName, FilteredUserUsageData, investorList, isOpenConfirmationPanel, message, selectedFilePreview, statusList, showCheckBox, IsOpen, ClientId, k1ClientData} = this.state;
        return(
            <>
              <Table 
                investorList = {investorList}
                statusList= {statusList}
                columns={this.GetColumns()} 
                data={FilteredUserUsageData}
                performAction = {this.TakeAction}
                filterList = {this.FilterList}
              />
              <AddEditInvestor investorList = {investorList}  actionType = {'View'} data = {k1ClientData} IsOpen = {IsOpen}  openPopup = {this.OpenAddEditPop} OnHandelClick = {this.OnHandelClick}/>

              {selectedFilePreview != '' ? (
                    <iframe
                      id="title"
                      src={selectedFilePreview}
                      width="96%"
                      height="900px"
                      className="pdfPreview m-4"
                      title = {fileName}
                    />
                  ) : (
                    <div className="no-data-found-block  text-blue">Please click on View K-1 for preview.</div>
                )}
               <ConfirmDialog
                  IsOpen={isOpenConfirmationPanel}
                  Message={message}
                  OnHandelClick={this.PerformAction}
                  AgreeText = "OK"
                  DisAgreeText = "CANCEL"
                  showCheckBox = {showCheckBox}
                  onClick = {this.onClick}
              />
            </>
        ); 
    }
}

const mapStateToProps = (state) => {
  
  return {
    showAll: state.showAllVersionConfig
  }
}

export default
connect(
  mapStateToProps
)(ScheduledK1)

