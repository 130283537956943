import * as actionType from './actionTypes'

const getIsAuthenticated=()=>{
  return (localStorage.getItem('adal.idtoken')=="" || 
  localStorage.getItem('adal.idtoken')==null ||localStorage.getItem('adal.idtoken')==undefined)?false:true;
}

const getIsUserLogin=()=>{
  return (localStorage.getItem('K1LU')=="" || 
  localStorage.getItem('K1LU')==null ||localStorage.getItem('K1LU')==undefined)?false:true;
}

const initialState = {
  userCount: 0,
  isAuthenticated:getIsAuthenticated(),
  IsUserLayoutType:getIsUserLogin()
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_COUNT: {
      return {
        ...state,
        userCount: action.count
      }
    }
    default:
      return state
  }
}

export default reducer
