import axios from 'axios'
import { API_HEADERS } from '../../constants/apiConfiguration'
import { saveAs } from 'file-saver'
import * as loaderActionType from '../../../store/loader/actionTypes'
import * as errorActionType from '../../../store/error-page/actionTypes'

import store from '../../../store/appStore'
import { ShowToast, toastType } from './toast-service'
import commonLabels from '../../constants/message'

function getApiHeader (isContentTypeForm = false) {
  if (isContentTypeForm) {
    return {
      'Content-Type': 'multipart/form-data',
      datatype: 'json',
      authorization: `${API_HEADERS.authName} ${localStorage.getItem(
        API_HEADERS.authToken
      )}`,
      cloudOrgId: localStorage.getItem(API_HEADERS.CID),
      CID: localStorage.getItem(API_HEADERS.CID),
      Email: localStorage.getItem(API_HEADERS.EMAIL)
    }
  } else {
    return {
      authorization: `${API_HEADERS.authName} ${localStorage.getItem(
      API_HEADERS.authToken
    )}`,
      cloudOrgId: localStorage.getItem(API_HEADERS.CID),
      CID: localStorage.getItem(API_HEADERS.CID),
      Email: localStorage.getItem(API_HEADERS.EMAIL)
    }
  }
}

axios.interceptors.request.use(
  (config) => {
    store.dispatch({
      type: loaderActionType.SET_VISIBILITY,
      payload: true
    })
    return config
  },
  (error) => {
    return Promise.reject(error.response)
  }
)
axios.interceptors.response.use(
  (response) => {
    store.dispatch({
      type: loaderActionType.SET_VISIBILITY,
      payload: false
    })
    return response
  },
  (error) => {
    store.dispatch({
      type: loaderActionType.SET_VISIBILITY,
      payload: false
    })
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch({
          type: errorActionType.SET_ERROR_PAGE,
          payload: commonLabels.sessionTimeOut
        })
      } else if (error.response.status === 403) {
        if (
          error.response.data.isValidImUser === false ||
          error.response.data.hasAccessToSection === false
        ) {
          store.dispatch({
            type: errorActionType.SET_ERROR_PAGE,
            payload: commonLabels.unAuthorizedAccess
          })
        }
      }
    }
    errorHandler(error.response.data, error.response.status)
    return Promise.reject(error.response)
  }
)
function errorHandler (response, code) {
  if (!response) return
  const errorMsg1 = response.message ? response.message : ''
  const errorMsg2 = response.error && response.error.message ? response.error.message : ''

  ShowToast(errorMsg1 || errorMsg2, toastType.ERROR, false, code)
}
export function apiGetAsync ({ url, params }) {
  return axios
    .get(url, {
      headers: getApiHeader(),
      ...{ params }
    })
    .then(response => {
      if (response !== undefined && response.data !== undefined && response.data.success !== undefined && response.data.success === false && response.data.message !== undefined) {
        ShowToast(response.data.message, toastType.ERROR, false)
      }
      return response.data
    })
    .catch(({ error }) => {
      return error
    })
}

export function apiPutAsync ({ url, params, postBody }) {
  const headers = getApiHeader()
  return axios
    .put(url, postBody, {
      headers,
      ...{ params }
    })
    .then(response => {
      return response.data
    })
    .catch(({ error }) => {
      return error
    })
}
export function apiGet ({ url, params }) {
  return axios
    .get(url, {
      headers: getApiHeader(),
      ...{ params }
    })
    .then(response => {
      return response.data
    })
    .catch(({ error }) => {
      return error
    })
}

export function processK1Documents (url, data) {
  if (data && data.files.length > 0) {
    const f = new FormData()
    f.append('MasterFile', data.files[0])
    f.append('FiscalYear', data.taxYear)
    f.append('FilePassword', data.filePassword)
    f.append('ClientKeyValue', data.investorId.length > 0 ? JSON.stringify(data.investorId) : data.investorId)
    f.append('DocumentTypeId', parseInt(data.type))

    return axios.post(url, f, {
      headers: getApiHeader(true)
    })
      .then(response => {
        return response.data
      })
      .catch(({ error }) => {
        return error
      })
  }
}

export function uploadExcel (url, data) {
  if (data && data.files.length > 0) {
    const f = new FormData()
    f.append('FormFile', data.files[0])
    f.append('FileName', data.files[0].name)

    return axios.post(url, f, {
      headers: getApiHeader(true)
    })
      .then(response => {
        return response.data
      })
      .catch(({ error }) => {
        return error
      })
  }
}

export function SaveK1ClientDocuments (url, data) {
  if (data && data.files.length > 0) {
    const f = new FormData()
    for (let i = 0; i < data.files.length; i++) {
      f.append('MasterFile', data.files[i])
    }
    f.append('FiscalYear', data.taxYear)
    f.append('FilePassword', data.filePassword)
    f.append('DocumentTypeId', parseInt(data.type))
    f.append('InvestorName', data.investorName)

    return axios.post(url, f, {
      headers: getApiHeader(true)
    })
      .then(response => {
        return response.data
      })
      .catch(({ error }) => {
        return error
      })
  }
}

export function DownloadPdf ({ url, params, fileName }) {
  return axios
    .get(url, {
      method: 'GET',
      responseType: 'blob', // Force to receive data in a Blob Format
      headers: getApiHeader(),
      ...{ params }
    })
    .then(response => {
      const blob = new Blob([response.data])
      saveAs(blob, fileName)
      return response.data
    })
    .catch(({ error }) => {
      return error
    })
}

export function ViewPdf ({ url, params }) {
  return axios
    .get(url, {
      method: 'GET',
      responseType: 'blob', // Force to receive data in a Blob Format
      headers: getApiHeader(),
      ...{ params }
    })
    .then(response => {
      return URL.createObjectURL(response.data)
    })
    .catch(({ error }) => {
      return error
    })
}

export function apiPostAsync ({ url, postBody, params }) {
  const headers = getApiHeader()
  return axios
    .post(url, postBody, {
      headers
    })
    .then(({ data, status }) => {
      return data
    })
    .catch(({ error }) => {
      return error
    })
}
