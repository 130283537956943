import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { BlueCheckbox } from '../check-box/checkBox'
import { UploadFileConstant } from '../../constants'

export default function ConfirmDialog ({ IsOpen, Message, OnHandelClick, AgreeText = 'Agree', DisAgreeText = 'Disagree', header = 'Confirm', hideDisAgree, showCheckBox = false, onClick }) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <div>

      <Dialog
        fullScreen={fullScreen}
        open={IsOpen}
        onClose={() => OnHandelClick(false)}
        aria-labelledby="responsive-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="responsive-dialog-title" className="text-blue">{header}</DialogTitle>
        <DialogContent>
          <DialogContentText className="text-blue">
            <div className="col-md-12 col-sm-12">
              {Message}
            </div>
           {showCheckBox
             ? <>
              <div className="col-md-12 col-sm-12" style= {{ marginLeft: '-13px' }}>
                  <BlueCheckbox name="isRevised" onChange= {(e) => { onClick(e) }}/> Is Revised
              </div>
              <span style={{fontWeight: 'bold', marginLeft: '12px'}}>{UploadFileConstant.Note}</span>Please select Is Revised in case of Amended K-1
              </>
             : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { OnHandelClick(true) }} autoFocus className="btn btn-sm btn-blue">
            {AgreeText}
          </Button>
                   {hideDisAgree !== true && <Button autoFocus onClick={() => OnHandelClick(false)} className="btn btn-sm btn-blue">
            {DisAgreeText}
          </Button>
         }
        </DialogActions>
      </Dialog>
    </div>
  )
}
