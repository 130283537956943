import React from 'react'
import './Footer.less'

class Footer extends React.Component {
  render () {
    const Year = new Date().getFullYear()
    return (
      <>
        <div className="footer-wrapper">
          <div className="footer-left-div"></div>
          <div className="footer-middle-div">
            © {Year} Stream Realty Partners, LP |{' '}
            <a
              href="https://streamrealty.com/privacy/"
              style={{ color: '#FFFFFF', textDecoration: 'none' }}
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
          <div className="footer-right-div"></div>
        </div>
      </>
    )
  }
}

export default Footer
