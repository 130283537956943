import React, { useState, useRef } from 'react'
import { UploadFileConstant } from '../../constants/index'
import { ShowToast, toastType } from '../utils/toast-service'
import { Button, IconButton, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core/'
import DocumentTypes from '../../constants/DocumentTypes'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { EXCEL_FILE } from '../../constants/appConstants'

const FileUpload = (props) => {
  const { fileSetting, disabled, documentTypeName, parent, bootstrapClasses, classes } = props
  const inputFile = useRef(null)

  const handleFileUpload = e => {
    const { files } = e.target
    if (files && files.length) {
      if (documentTypeName === DocumentTypes.MasterK1File) {
        const filename = files[0].name

        const parts = filename.split('.')
        const fileType = parts[parts.length - 1]
        if (fileType.toLowerCase() === fileSetting.AllowedExtensions) {
          props.setDocument(files)
        } else {
          props.setDocument(null)
          ShowToast(fileType + fileSetting.ValidExtension, toastType.ERROR, 5000)
        }
      } else {
        for (let i = 0; i < files.length; i++) {
          const filename = files[i].name

          const parts = filename.split('.')
          const fileType = parts[parts.length - 1]
          if (fileType.toLowerCase() !== fileSetting.AllowedExtensions) {
            props.setDocument(null)
            ShowToast(fileSetting.OnlyPdf, toastType.ERROR, 5000)
            return
          }
        }
        props.setDocument(files)
      }
    }
  }
  const handleExcelFile = e => {
    const { files } = e.target
    if (files && files.length) {
      const filename = files[0].name

      const parts = filename.split('.')
      const fileType = parts[parts.length - 1]
      const excelFileExtension = ['xls', 'xlsx']
      if (excelFileExtension.includes(fileType.toLowerCase())) {
        props.setDocument(files)
      } else {
        ShowToast('File format is not supported. Please upload xls or xlsx file.', toastType.ERROR, 5000)
      }
    }
  }
  const onButtonClick = () => {
    inputFile.current.click()
  }
  const downloadFile = () => {
    fetch(EXCEL_FILE).then(response => {
      response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'Excel File Format.xlsx'
        a.click()
      })
    })
  }
  return (
    <>
      {parent === 'AddFile'
        ? documentTypeName === DocumentTypes.MasterK1File
          ? <input
          className={'text-blue'}
          style={{ display: 'none' }}
          ref={inputFile}
          onChange={handleFileUpload}
          onClick={(event) => {
            event.target.value = null
          }}
          type="file"
          accept="application/pdf"
        />
          : <input
          multiple
          className={'text-blue'}
          style={{ display: 'none' }}
          ref={inputFile}
          onChange={handleFileUpload}
          onClick={(event) => {
            event.target.value = null
          }}
          type="file"
          accept="application/pdf"
        />
        : <input
        className={'text-blue'}
        style={{ display: 'none' }}
        ref={inputFile}
        onChange={handleExcelFile}
        onClick={(event) => {
          event.target.value = null
        }}
        type="file"
        accept=".xlsx, .xls"
        />}
    {parent === 'AddFile'
      ? <Button variant="contained" className={'btn btn-sm btn-blue mb-2' + (disabled ? ' not-allowed' : '')} disabled = {disabled} onClick={onButtonClick}>
        {UploadFileConstant.Browse}
      </Button>
      : <>
      <Tooltip classes={bootstrapClasses} title = 'Import Excel'>
        <IconButton style ={{ marginRight: '-13px', outline: 'none' }} onClick={onButtonClick}>
            <CloudUploadIcon className ={classes}/>
        </IconButton>
      </Tooltip>
      <Tooltip classes={bootstrapClasses} title = 'Sample Excel Format'>
        <IconButton style ={{ marginRight: '-13px', outline: 'none' }} onClick={downloadFile}>
          <CloudDownloadIcon className ={classes}/>
        </IconButton>
      </Tooltip>
      </>
    }
    </>
  )
}

export default FileUpload
