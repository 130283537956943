import * as actionType from './actionTypes'
const initialState = {
  isInErrorState: false,
  commonLabel: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_ERROR_PAGE:
      return {
        ...state,
        isInErrorState: true,
        commonLabel: action.payload
      }
    case actionType.CLEAR_ERROR_PAGE:
      return {
        ...state,
        isInErrorState: false,
        commonLabel: action.payload
      }
    default:
      return state
  }
}

export default reducer
