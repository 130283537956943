import moment from 'moment'

function getBackYearFromDate (numberOfBackYear, date = new Date()) {
  const formattedDate = moment(date)
    .format('YYYY-MM-DD')
    .split('-')
  let formattedYear = parseInt(formattedDate[0])
  const year = []
  for (let i = 1; i <= numberOfBackYear; i++) {
    year.push(formattedYear)
    formattedYear = (formattedYear - 1)
  }
  return year
}

function getBackAndForwardYearFromDate (numberOfBackYear, numberOfForwardYear, date = new Date()) {
  const formattedDate = moment(date)
    .format('YYYY-MM-DD')
    .split('-')
  let formattedYear = parseInt(formattedDate[0])
  const year = []

  for (let i = 1; i <= numberOfForwardYear; i++) {
    year.push(formattedYear++)
  }

  formattedYear = parseInt(formattedDate[0]) - 1
  for (let i = 1; i <= numberOfBackYear; i++) {
    year.push(formattedYear)
    formattedYear = (formattedYear - 1)
  }

  return year.sort((a, b) => b - a);
}

export {
  getBackYearFromDate,
  getDate,
  convertDateToFormat,
  getDateFromString,
  getBackAndForwardYearFromDate
}

// Add current timezone offset
function getDate (_date) {
  const date = new Date(_date)
  date.setMinutes(date.getTimezoneOffset())
  return date
}

function convertDateToFormat (today) {
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
  const yyyy = today.getFullYear()
  today = mm + '/' + dd + '/' + yyyy
  return today
}

function getDateFromString (date) {
  return new Date(date)
}
