import React from "react";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import './buttonTab.less';
import Badge from '@material-ui/core/Badge'
import store from '../../../store/appStore'
import * as searchFilterActionType from '../../../store/search-filter/actionTypes'

export default class ButtonTab extends React.Component {
	constructor(props) {
		super(props);
 		this.state = {
			selectedTab: props.selectedTab,
			children: props.srp_tab[0].children
		};
	}

	setTabSelection = (key, children) => {
		store.dispatch({ type: searchFilterActionType.SET_SHOW_ALL, showAll: false })
		this.setState({ selectedTab: key , children: children});
	}

	render() {
		const { srp_tab, pendingUserCount } = this.props;
		const { children, selectedTab } = this.state;
		return (
			<>
				<ButtonGroup variant="text" aria-label="text primary button group">
					{
						srp_tab.map(btn => {
							return (
										btn.label === 'Users Management' ? 
										<Badge 
										    key={`btn_${btn.label}`} 
											anchorOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}

											badgeContent={pendingUserCount} color="error">
											<Button 
												key={`btn_${btn.label}`} 
												onClick={() => this.setTabSelection(btn.id, btn.children)} 
												className={"btn-rounded-top"+ (btn.id == selectedTab ? " selected" : " selector")}
											>
												<div className="btn-label">{btn.label}</div>
											</Button>
										</Badge>
									: 
										<Button 
											key={`btn_${btn.label}`} 
											onClick={() => this.setTabSelection(btn.id, btn.children)} 
											className={"btn-rounded-top"+ (btn.id == selectedTab ? " selected" : " selector")}
										>
											<div className="btn-label">{btn.label}</div>
										</Button>
							)
						})
					}
				</ButtonGroup>
				<div className="col-12 border border-theme-color box-theme-color rounded">
                 	{children}
            	</div>
			</>
		);
	}
}
