import React, { Component } from 'react'
import { connect } from 'react-redux'
import ButtonTab from '../../common/component/button-tab/buttonTab'
import UploadFile from '../upload-file/uploadFile'
import ScheduledK1 from '../scheduled-k1/scheduledK1'
import ToastMessage from '../../common/component/toastMsg/toastMsg'
import Header from '../../common/component/header/header'
import Footer from '../../common/component/footer/Footer'
import FullPageLoader from '../loader/fullPage-loader'
import Investor from '../investors/investor-list'
import Users from '../user/user-list'
import './layout.less'
import { apiGet } from '../../common/component/utils/api-service'
import { API_HEADERS, API_URL } from '../../common/constants/apiConfiguration'
import InviteHistoryList from '../invite-user/invite-user-list'
import * as layoutActionType from '../../store/layout/actionTypes'
import store from '../../store/appStore'
import { getUserData } from "../../common/component/user/actions";
import { IsSectionVisible } from "../../common/component/utils/helper";
import { SECTIONS } from "../../common/constants/appConstants";
import WorkSpace from "../../common/views/workspace/index";
import Login from '../login/index'
import { routes } from '../../common/constants/routes'
import IdleSessionTimer from "../../common/component/idleTimer/idleTimer";
import ErrorPage from '../../common/component/errorPage/errorPage'
import ContactUs from '../login/contact-us/contactUs'
import { UserUsage } from '../user-usage/user-usage'
class Layout extends Component {
  constructor (props) {
    super(props)    

    this.state = {
      selectedTab: 1,
      srpTab: [],
      userData: undefined,
      userCount: -1
    }
  }

  componentDidMount () {
    if ((this.props.userData == null || this.props.userData == undefined)
      && localStorage.getItem(API_HEADERS.authToken) !== null 
      && localStorage.getItem(API_HEADERS.authToken) !== undefined) {
    this.props.getUserData();
    this.loginLogSuccessfully();

    }

    this.setTabs();
  }

  loginLogSuccessfully() {
      const isLogin = localStorage.getItem('IsLogin');
      // debugger
      if (isLogin === 'true' || isLogin === true) {
        apiGet({ url: API_URL.UserConfiguration.SetSuccessfullyLoginAttempts }).then((data) => {
          if (data.success) {
              localStorage.removeItem('IsLogin');
            }
          });
      }
  }

  setTabs() {
    
    const tabArray = []
    if (IsSectionVisible(SECTIONS.INVESTOR_LIST)) {
      tabArray.push({ label: 'Investors List', id: 1, children: <Investor /> })
    }

    if(IsSectionVisible(SECTIONS.USER_MANAGEMENT)) {
    tabArray.push({ label: 'Users Management', id: 2, children: <Users /> })
    
    if(this.state.userCount == undefined || this.state.userCount <= 0)
    this.GetPendingUser();
    }
    if (IsSectionVisible(SECTIONS.UPLOAD_FILE)) {
      tabArray.push({ label: 'Upload File', id: 3, children: <UploadFile /> })
    }

    if (IsSectionVisible(SECTIONS.SCHEDULED_K1)) {
      tabArray.push({ label: 'Schedule K-1', id: 4, children: <ScheduledK1 /> })
    }

    if (IsSectionVisible(SECTIONS.INVITE_USERS)) {
      tabArray.push({ label: 'Share Access', id: 5, children: <InviteHistoryList getPendingUser={this.GetPendingUser} /> })
    }
    if (IsSectionVisible(SECTIONS.CONTACT_US)) {
      tabArray.push({label: 'Contact Us', id: 6, children: <ContactUs/>})
    }
    if (IsSectionVisible(SECTIONS.USER_ACTIVITY)) {
      tabArray.push({label: 'User Activity', id: 7, children: <UserUsage/>})
    }

    this.setState({ srpTab: tabArray, selectedTab: tabArray.length> 0 ? tabArray[0].id : 0 });  
  }

UNSAFE_componentWillReceiveProps(newprops, props) {
  if(newprops.userData != props.userData) {
    this.setState({userData: newprops.userData});
    this.setState({userCount: newprops.userCount});
    this.setTabs();
  }
}

  GetPendingUser = () => {
    apiGet({ url: API_URL.GetPendingUserList }).then((data) => {
        if (data.success) {
            store.dispatch({type: layoutActionType.SET_COUNT, count: data.data.length})
          }
        });
    }
  render () {

    const { selectedTab, srpTab, pendingUserCount } = this.state
    const { layout, requestCount, userCount, errorConfig } = this.props
    return (
        <>
          <FullPageLoader requestCount={requestCount} />
          <ToastMessage/>
          {!errorConfig.isInErrorState && layout.isAuthenticated == true ?
          (<div className="layout-header-container">
             <Header />
          </div>
          ) : null}
      {errorConfig.isInErrorState ?  (
                    <ErrorPage details={errorConfig.commonLabel} />
                  ) : null} 
{!errorConfig.isInErrorState && layout.isAuthenticated == true && this.state.srpTab.length > 0 && this.props.userData != null && this.props.userData != undefined?
          (<div className="layout-body-wrapper">
            <div className="layout-selected-route-container">
              <div className="row">
              {/* <WorkSpace
                    defaultPage={routes.layout}
                    userData={this.props.userData}
                  /> */}
                <ButtonTab pendingUserCount= {userCount.userCount} setTabSelection={this.setTabSelection} selectedTab={selectedTab} srp_tab={srpTab}>
                </ButtonTab>
              </div>
            </div>
        </div>
        ) : (
          <>{!errorConfig.isInErrorState && layout.isAuthenticated != true && <Login />}</>
        )}

{!errorConfig.isInErrorState && layout.isAuthenticated == true ?
        (<div>
          <Footer />
        </div>
        ) : null}
        <IdleSessionTimer />
        </>

    )
  }
}
const mapStateToProps = (state) => {
  const {
    loaderConfig: { requestCount },
  } = state
  return {
    requestCount,
    userCount: state.counterConfig,
    layout: state.layoutConfig, 
    userData: state.configuredUser.userData,
    showAll: state.showAllVersionConfig,
    errorConfig: state.errorConfig   
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: (param) => dispatch(getUserData(param)),
  };
};

export default
connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)
