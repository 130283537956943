import React, { Component } from 'react'
import { InvestorConstant, UploadFileConstant } from '../../../common/constants/index'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  FormHelperText,
  Select,
  MenuItem
} from '@material-ui/core'
import { apiGetAsync, apiPostAsync, apiGet } from '../../../common/component/utils/api-service'
import { API_URL } from '../../../common/constants/apiConfiguration'
import { ShowToast, toastType } from "../../../common/component/utils/toast-service";
import Loader from '../../../common/component/loader/loader'
import Radio from '../../../common/component/radio/radio'
import InvestorType from '../../../common/constants/investor/InvestorType'
import InputMask from 'react-input-mask';
import _ from 'lodash'
import { BlueCheckbox } from '../../../common/component/check-box/checkBox'
import { getBackYearFromDate } from '../../../common/component/utils/date'

class SwapInvestor extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fullWidth: true,
      maxWidth: 'md',
      bName: '',
      firstName: '',
      lastName: '',
      k1referenceName: '',
      email: '',
      clientType: '',
      streetAddress1: '',
      streetAddress2: '',
      year: '',
      town: '',
      state: '',
      zipcode: '',
      isAllK1Swap: false,
      errors: {
        name: '',
        email: '',
        clientType: '',
        streetAddress1: '',
        town: '',
        state: '',
        zipcode: '',
        year: ''
     },
     existingEmail: '',
     isSubmitted: false,
     clientTypeList: [],
     ClientId: 0,
     loading: false,
     investorType: '',
     investorTypeList: [],
     investorTypeName: '',
     investorId: '',
     taxYearList: getBackYearFromDate(5)
    }
  }

  componentDidMount () {
    this.getClientType();
    this.getMaster();
  }
 
  formatTaxId = (s) => {
    const val_without_dashes = s.replace(/-/g, "");
    // debugger
    if (val_without_dashes.trim().length === 9) {
      if (this.state.investorTypeName === InvestorType.Business) {
        s = val_without_dashes.substring(0, 2) + '-' + val_without_dashes.substring(2, 9);
          return s;
      } else {
        s = val_without_dashes.substring(0, 3) + '-' + val_without_dashes.substring(3, 5)    + '-' + val_without_dashes.substring(5, 9);
          return s;
      }
    }
  }

  getMaster = () =>{
    const params = {
      isInvestorType: true
    }
    apiGet({ url: API_URL.masterData , params}).then((data) => {
    if(data && (data.investorType.success)) {      
      this.setState({ investorTypeList: data.investorType.data });
    }
    });
  }

  componentWillReceiveProps(newprops) {
      console.log(newprops.ClientId, newprops.actionType, newprops)
    if(newprops.ClientId!=this.state.ClientId) {
      this.setState({ClientId: newprops.ClientId});
      if( newprops.ClientId > 0 && newprops.actionType === 'Swap')
        this.GetInvestorById(newprops.ClientId);
    }
    if (newprops.data) {
      const investorId = (newprops.data||{}).ClientId > 0 ? (newprops.data||{}).ClientId  : ''
      if (investorId > 0) {
        this.setState({fileName: (newprops.data||{}).FileName}, () => { this.GetInvestorById(investorId, true); });
      } else {
        this.setState({fileName: (newprops.data||{}).FileName});
      }
    }
  }

  GetInvestorById = (clientId, isSet = false) => {
    this.setState({loading: true})
    const params = {
      ClientId: clientId
    };
    apiGetAsync({ url: API_URL.GetInvestorById,params }).then((data) => {
      if(data.success) {
        const value = data.data
        var valueData = this.state.investorTypeList.find(x=> x.InvestorTypeId === value.InvestorTypeId);
        const investorTypeName = valueData ? valueData.Name: Name

        this.setState({
            investorId: isSet ? clientId : '',
            investorTypeName: investorTypeName,
            investorType: value.InvestorTypeId, 
            bName: InvestorType.Business == investorTypeName ? value.Name : '',
            firstName: value.FirstName,
            lastName: value.LastName,
            email: value.Email,
            existingEmail: value.Email,
            streetAddress1: value.StreetAddress1,
            streetAddress2: value.StreetAddress2,
            town: value.City,
            state: value.State,
            zipcode: value.ZipCode,
            clientType: value.ClientType,
            k1referenceName: value.K1ReferenceName,
            loading: false
         });       
      }
    });
  }

  getClientType = () => {
    apiGetAsync({ url: API_URL.GetClientTypes }).then((data) => {
			if(data && data.success) {
				this.setState({ clientTypeList: data.data });
			}
		});
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  onClick = (e) => {
    let errors = this.state.errors;
    if (e.target.checked) {
      errors.year = '';
    }
    this.setState({[e.target.name]: e.target.checked, errors, year: '' });
  }

  onChangeHandle = (e, isPrevent = true, isTarget = false) => {
    const {investorTypeList, firstName, lastName, investorType, bName, taxYearList} = this.state
    let name = '', value = '';
    if (isPrevent) {
        e.preventDefault();
        name = e.target.name;
        value = name === 'investorType' ? parseInt(e.target.value) : e.target.value;
    } else {          
        name = isTarget ? 'investorType' : e.name;
        value = isTarget ? investorType : e.value; 
      }
    let errors = this.state.errors;
    switch (name) {
        case 'fileName': 
          errors.fileName = 
            value.trim() == ''
              ? 'File Name is required.'
              : '';
        case 'bName': 
          errors.bName = 
            value.trim() == ''
              ? InvestorConstant.NameError
              : '';
        break;  
        case 'investorType' : 
                errors.investorType = 
                value == ''
                  ? InvestorConstant.InvestorTypeError
                  : '';
                if (value != '' && value > 0 ) {
                  var valueData = investorTypeList.find(x=> x.InvestorTypeId === value);
                  let fName = firstName; let lName = lastName; let tName = bName;
                  if (valueData.Name === InvestorType.Individual) {
                      tName = ''
                  } else {
                      fName = '', lName = ''
                  }
                  this.setState({investorTypeName: valueData.Name,firstName: fName, lastName:lName, bName: tName})
                }
              break;
        case 'firstName': 
          errors.firstName = 
            value.trim() == ''
              ? InvestorConstant.FirstNameError
              : '';
          break;
        case 'lastName': 
          errors.lastName = 
            value.trim() == ''
              ? InvestorConstant.LastNameError
              : '';
          break;
        case 'k1referenceName': 
            errors.k1referenceName = 
            value == ''
              ? InvestorConstant.ReferenceNameError
              : !this.validateTaxId(value)
              ? InvestorConstant.ReferenceNameValidError : '';
            break;
        case 'email': 
          errors.email = 
            value == ''
              ? InvestorConstant.EmailError
              : value != '' && this.validateEmail(value) ? '' : InvestorConstant.ValidEmailAddress;
          break;
        case 'clientType': 
          errors.clientType = 
            value == ''
              ? InvestorConstant.ClientTypeError
              : '';
          break;
        case 'streetAddress1': 
          errors.streetAddress1 = 
              value == ''
                ? InvestorConstant.StreetAddress1Error
                : '';
          break;
        case 'town': 
          errors.town = 
              value == ''
                  ? InvestorConstant.TownError
                  : '';
          break;
        case 'state': 
          errors.state = 
            value == ''
                ? InvestorConstant.StateError
                : '';
          break;
        case 'zipcode': 
          errors.zipcode = 
            value == ''
                ? InvestorConstant.ZipcodeError
                : '';
          break;
        case 'year' : 
          errors.year = 
          value == '' && this.state.isAllK1Swap === false
            ? InvestorConstant.InvestorTypeError
            : '';
          if (value != '' && value > 0 ) {
            this.setState({year: value})
          }
        break;
        default:
          break;
      }
      console.log(name, value)
      this.setState({errors, [name]: value, isSubmitted: true});
 }

 validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}

clearField = () => {
  this.setState({
    firstName: '',
    lastName: '',
    k1referenceName: '',
    email: '',
    clientType: '',
    streetAddress1: '',
    streetAddress2: '',
    town: '',
    state: '',
    zipcode: '',
    errors: {},
    isSubmitted: false,
    investorType: '',
    investorTypeName: '',
    bName: '',
    investorId: '',
    year: '',
    isAllK1Swap: false,
    existingEmail: ''
  })
  this.props.openPopup(false)
}
validateTaxId = (value) => {
  const val_without_dashes = value.replace(/-/g, "");
  return val_without_dashes.trim().length === 9;
}
handleSubmit = (e) => {
  const {investorTypeName, existingEmail} = this.state
  e.preventDefault();

  if (investorTypeName === InvestorType.Individual) {
    this.onChangeHandle(e.target.elements.firstName, false,);
    this.onChangeHandle(e.target.elements.lastName, false);
  }
  if (investorTypeName === InvestorType.Business) {
    this.onChangeHandle(e.target.elements.bName, false);
  }
  this.onChangeHandle(e.target.elements.k1referenceName, false);
  this.onChangeHandle(e.target.elements.email, false);
  this.onChangeHandle(e.target.elements.clientType, false);
  this.onChangeHandle(e.target.elements.streetAddress1, false)
  this.onChangeHandle(e.target.elements.town, false);
  this.onChangeHandle(e.target.elements.zipcode, false);
  this.onChangeHandle(e.target.elements.state, false);
  if (this.state.isAllK1Swap === false) {
    this.onChangeHandle(e.target.elements.year, false);
  }
  if(this.validateForm(this.state.errors)) {
    if (existingEmail.toLowerCase().trim() === this.state.email.toLowerCase().trim()) {
      const errors = this.state.errors;
      errors.email = InvestorConstant.EmailIdSameTaken;
      this.setState({errors, isSubmitted: true})
    } else {
      this.SwapInvestor();
    }
  } 
}
SwapInvestor = () => {
  this.setState({loading: true})
  const {firstName, lastName,k1referenceName, email, clientType, streetAddress1, streetAddress2, town, state, zipcode,errors, ClientId, investorType, bName, investorTypeName, year, isAllK1Swap } = this.state;
  const validClient  = ClientId >= 0 ? ClientId : 0
  const postBody = { firstName, lastName,k1referenceName: this.formatTaxId(k1referenceName), email, clientType, streetAddress1, streetAddress2, town, state, zipcode, ClientId: validClient,
                     investorType, name: investorTypeName === InvestorType.Business ? bName : firstName + ' ' + lastName, year: (isAllK1Swap === true ? 0 : parseInt(year)), IsAllK1: isAllK1Swap};
  apiPostAsync({ url: API_URL.SwapInvestor, postBody }).then((data) => {
    this.setState({loading: false});
    if(data.success) {
      ShowToast(data.message,  toastType.SUCCESS, 5000);
      this.clearField();
      this.props.getInvestors();
    } else {
      if (data && data.data.EmailStatus <= 0) {
        errors.email = InvestorConstant.EmailIdAlreadyTaken;
      }
      else if (data && data.data.EmailStatus == -1) {
        errors.email = InvestorConstant.EmailIdAlreadyTaken;
      }
      this.setState({errors, isSubmitted: true})
    }
  });
}

onChangeHandleInvestor = (e) => {
  if (e.target.value > 0) {
    const errors =  this.state.errors;
    errors.investor = '';
    this.setState({errors}, ()=> this.GetInvestorById(e.target.value, true));
  } else {
    const errors =  this.state.errors;
    errors.investor = 'Investor is required';
    this.setState({errors, investorId: ''});
  }
}

render () {
    const { fullWidth, maxWidth, errors, isSubmitted, clientTypeList,firstName,lastName,k1referenceName, email, clientType,
            streetAddress1, streetAddress2, town, state, zipcode, ClientId, loading, investorType, investorTypeList, investorTypeName, bName, investorId,fileName, taxYearList, year, isAllK1Swap } = this.state
    const { IsOpen, k1ClientData } = this.props
    return (<>
    <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        disableBackdropClick
        open={IsOpen}
        aria-labelledby="customized-dialog-title"
        onClose={() => this.props.openPopup(false)}
    >
       <Loader loading = {loading} />
       <DialogTitle id="customized-dialog-title" className="text-blue font-weight-bold" onClose={() => this.props.openPopup(false)}>
          <span className="text-blue font-weight-bold">{InvestorConstant.SwapInvestor}</span>
        </DialogTitle>
        
        <form onSubmit={this.handleSubmit} noValidate>
        <DialogContent dividers>
            <DialogContentText className="text-blue">
            <div className="row m-2">
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {InvestorConstant.InvestorType} <span className="text-red">*</span></label>
                  <div className="col-sm-4 col-xs-12">
                    {investorTypeList.find(x=> x.InvestorTypeId === investorType)?.Name}
                  </div>
            </div>
            {investorTypeName === InvestorType.Individual ?
            <div className="row m-2">
              <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {InvestorConstant.FirstName} <span className="text-red">*</span></label>
              <div className="col-sm-4 col-xs-12">
                  <TextField
                      variant="outlined"
                      className = 'w-100'
                      name = 'firstName'
                      value = {firstName}
                      placeholder= {InvestorConstant.FirstName}
                      InputProps={{
                        className: 'text-blue'
                      }}
                      onChange = {this.onChangeHandle}
                      error = {errors.firstName}
                  />
                  <FormHelperText className="text-red">
                      {errors.firstName}
                  </FormHelperText>
                </div>
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {InvestorConstant.LastName} <span className="text-red">*</span></label>
                <div className="col-sm-4 col-xs-12">
                  <TextField
                      variant="outlined"
                      className = 'w-100'
                      name = 'lastName'
                      value = {lastName}
                      placeholder= {InvestorConstant.LastName}
                      InputProps={{
                        className: 'text-blue'
                      }}
                      onChange = {this.onChangeHandle}
                      error = {errors.lastName}
                  />
                  <FormHelperText className="text-red">
                      {errors.lastName}
                  </FormHelperText>
                </div>
              </div>
               : '' }
               { investorTypeName === InvestorType.Business ?
                 <div className="row m-2">
                 <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {InvestorConstant.Name} <span className="text-red">*</span></label>
                 <div className="col-sm-10 col-xs-12">
                     <TextField
                         variant="outlined"
                         className = 'w-100'
                         name = 'bName'
                         value = {bName}
                         placeholder= {InvestorConstant.Name}
                         InputProps={{
                           className: 'text-blue'
                         }}
                         onChange = {this.onChangeHandle}
                         error = {errors.bName}
                     />
                     <FormHelperText className="text-red">
                         {errors.bName}
                     </FormHelperText>  
                   </div>
                   </div>
               : ''}
              <div className="row m-2">
              <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {InvestorConstant.K1referenceName} <span className="text-red">*</span></label>
              <div className="col-sm-4 col-xs-12">
              <InputMask
                    variant="outlined"
                    className = 'w-100'
                    disabled = {true}
                    name = 'k1referenceName'
                    mask= {investorTypeName === InvestorType.Business ? "99-9999999" : "999-99-9999" }
                    value={k1referenceName}
                    maskChar=" "
                    onChange = {this.onChangeHandle}
                  >
                    {() => <TextField
                      variant="outlined"
                      className = 'w-100'
                      disabled = {true}
                      name = 'k1referenceName'
                      error={errors.k1referenceName}
                      InputProps={{
                        className: 'text-blue'
                      }} />}
                  </InputMask>
                  <FormHelperText className="text-red">
                      {errors.k1referenceName}
                  </FormHelperText>
                  <FormHelperText className='text-blue'><span style={{fontWeight: 'bold'}}>{UploadFileConstant.Note}</span>{InvestorConstant.InvestorNote}</FormHelperText>
                </div>
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {InvestorConstant.EmailAddress}<span className="text-red">*</span></label>
                <div className="col-sm-4 col-xs-12">
                  <TextField
                      variant="outlined"
                      className = 'w-100'
                      name = 'email'
                      value = {email}
                      placeholder= {InvestorConstant.EmailAddress}
                      InputProps={{
                        className: 'text-blue'
                      }}
                      onChange = {this.onChangeHandle}
                      error = {errors.email}
                  />
                  <FormHelperText className="text-red">
                      {errors.email}
                  </FormHelperText>
                </div>
              </div>
              <div className="row m-2">
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {InvestorConstant.ClientType} <span className="text-red">*</span></label>                
                <div className="col-sm-4 col-xs-12">
                  <Select
                      displayEmpty
                      className="w-100 text-blue"
                      name = 'clientType'
                      value = {clientType}
                      onChange = {this.onChangeHandle}
                      error = {errors.clientType}
                  >
                      <MenuItem className="text-blue" value=""> {InvestorConstant.ClientType }</MenuItem>
                      {
                          clientTypeList.map(client => {
                              return (
                                  <MenuItem className="text-blue" key = {client} value={client}> {client}</MenuItem>
                              )
                          })
                      }
                  </Select>
                  <FormHelperText className="text-red">
                    {errors.clientType}
                  </FormHelperText>
                </div>
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold w-100">{InvestorConstant.StreetAddress1}<span className="text-red">*</span></label>
                <div className="col-sm-4 col-xs-12">
                  <TextField
                      variant="outlined"
                      className = 'w-100'
                      name = 'streetAddress1'
                      value = {streetAddress1}
                      placeholder= {InvestorConstant.StreetAddress1}
                      InputProps={{
                        className: 'text-blue'
                      }}
                      onChange = {this.onChangeHandle}
                      error = {errors.streetAddress1}
                  />
                  <FormHelperText className="text-red">
                      {errors.streetAddress1}
                  </FormHelperText>
                </div>
              </div>
              <div className="row m-2">
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {InvestorConstant.StreetAddress2}</label>
                <div className="col-sm-4 col-xs-12">
                    <TextField
                        variant="outlined"
                        className = 'w-100'
                        name = 'streetAddress2'
                        value = {streetAddress2}
                        placeholder= {InvestorConstant.StreetAddress2}
                        InputProps={{
                          className: 'text-blue'
                        }}
                        onChange = {this.onChangeHandle}
                    />
                </div>
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {InvestorConstant.Town} <span className="text-red">*</span></label>                  <div className="col-sm-4 col-xs-12">
                    <TextField
                        variant="outlined"
                        className = 'w-100'
                        name = 'town'
                        value = {town}
                        placeholder= {InvestorConstant.Town}
                        InputProps={{
                          className: 'text-blue'
                        }}
                        onChange = {this.onChangeHandle}
                        error = {errors.town}
                    />
                    <FormHelperText className="text-red">
                        {errors.town}
                    </FormHelperText>
                  </div>
              </div>
              <div className="row m-2">
                  <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {InvestorConstant.State} <span className="text-red">*</span></label>
                  <div className="col-sm-4 col-xs-12">
                    <TextField
                        variant="outlined"
                        className = 'w-100'
                        name = 'state'
                        value = {state}
                        placeholder= {InvestorConstant.State}
                        InputProps={{
                          className: 'text-blue'
                        }}
                        onChange = {this.onChangeHandle}
                        error = {errors.state}
                    />
                    <FormHelperText className="text-red">
                        {errors.state}
                    </FormHelperText>
                  </div>
                  <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {InvestorConstant.Zipcode} <span className="text-red">*</span></label>
                   <div className="col-sm-4 col-xs-12">
                    <TextField
                        variant="outlined"
                        className = 'w-100'
                        name = 'zipcode'
                        value = {zipcode}
                        placeholder= {InvestorConstant.Zipcode}
                        InputProps={{
                          className: 'text-blue'
                        }}
                        onInput = {(e) =>{
                          e.target.value= e.target.value <= 6 ? e.target.value : e.target.value.substring(0, 6)
                        }}
                        onChange = {this.onChangeHandle}
                        error = {errors.zipcode}
                    />
                    <FormHelperText className="text-red">
                      {errors.zipcode}
                  </FormHelperText> 
                  </div>
              </div>
              <div className="row m-2">
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {InvestorConstant.IsAllK1Swap}</label>                
                <div className="col-sm-4 col-xs-12">
                    <BlueCheckbox name="isAllK1Swap" checked ={isAllK1Swap} onChange= {this.onClick}/>                           
                </div>
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {InvestorConstant.Year} {isAllK1Swap === false && <span className="text-red">*</span>}</label>                
                <div className="col-sm-4 col-xs-12">
                  <Select
                      disabled = {isAllK1Swap === true}
                      displayEmpty
                      className="w-100 text-blue"
                      name = 'year'
                      value = {year}
                      onChange = {this.onChangeHandle}
                      error = {errors.year}
                  >
                      <MenuItem className="text-blue" value=""> {InvestorConstant.Year }</MenuItem>
                      {
                        taxYearList.map(year => {
                        return (
                            <MenuItem className="text-blue" key = {year} value={year}> {year}</MenuItem>
                            )
                        })
                      }
                  </Select>
                  <FormHelperText className="text-red">
                    {errors.year}
                  </FormHelperText>
                </div>
              </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className="btn btn-sm btn-blue" type="submit">
                {InvestorConstant.Swap}
            </Button>
            <Button className="btn btn-sm btn-blue" onClick={() => { this.clearField() }} type="button">
                 {InvestorConstant.Cancel}
            </Button>
        </DialogActions>
        </form>       
    </Dialog>
    </>)
  }
}

export default SwapInvestor
