export default {
  Type: 'Document Type',
  SelectType: 'Select Document Type',
  TypeMessage: 'Document type is required',
  MasterK1File: 'K-1 File',
  K1FilePlaceholder: 'Select K-1 File',
  IsPasswordProtected: 'Is Password Protected',
  FilePassword: 'File Password',
  Browse: 'Browse',
  SelectInvestor: 'Select Investment',
  SelectAccessType: 'Select Access Type',
  TaxYear: 'Tax Year',
  SelectTaxYear: 'Select Tax Year',
  ProcessK1: 'Process K-1',
  Clear: 'Clear',
  UploadHistory: 'Upload History',
  ProcessedAt: 'ProcessedAt',
  MasterDocumentCells: [
    { key: 'FiscalYear', name: 'Tax Year' },
    { key: 'Name', name: 'Name' },
    { key: 'DocumentType', name: 'Document Type' },
    { key: 'ProcessedAt', name: 'Processed At' },
    { key: 'Investor', name: 'Selected Investors' },
    { key: 'ApproveStatus', name: 'Status' },
    { key: 'Action', name: 'Action' }
  ],
  K1MasterFileError: 'K-1 file is required.',
  FilePasswordErrorMessage: 'File password is required.',
  TaxYearMessage: 'Tax year is required.',
  InvestorMessage: 'Please Select Investment.',
  Search: 'Search',
  SelectedInvestors: 'Selected Investors',
  SelectedInvestor: 'Select Investment',
  Close: 'Close',
  Actions: 'Actions',
  Action: 'Action',
  Status: 'Status',
  MoreInvestors: 'More Investors',
  UserName: 'Users',
  View: 'View K-1',
  SelectStatus: 'Select Status',
  SelectRequestType: 'Request Type',
  InvestorNote: 'Please note that file will be processed for all the investors if no investor is selected.',
  Note: 'Note: ',
  FileNotValid: 'File is corrupted, please upload valid file.',
  PdfHeaderNotFound: 'PDF header not found.',
  InvestorError: 'Investor is required.'
}
