import React, { Component } from 'react'
import { LoginModel, UserConstant } from '../../../common/constants/index'
import { 
  TextField,
  Link,
  Button,
  FormHelperText
} from '@material-ui/core/'
import { ShowToast, toastType } from "../../../common/component/utils/toast-service";
import { validatePassword } from "../../../common/component/utils/generic";
import { API_HEADERS, API_URL } from '../../../common/constants/apiConfiguration';
import './change-password.less'
import K1Logo from '../../../resources/images/K1Logo.png'
import { apiGetAsync, apiPostAsync } from "../../../common/component/utils/api-service";
import store from '../../../store/appStore'
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      confirmPassword: '',
      secretKey: props.match.params.token,
      errors: {
        email: '',
        password: '',
        confirmPassword: ''
      }
    };
  }
  componentDidMount() {
    this.loadDetails()
  }
  loadDetails = () => {
    if (this.state.secretKey != null && this.state.secretKey != undefined) {

    const params = {
        secretToken: this.state.secretKey
        };
    apiGetAsync({
      url: API_URL.UserConfiguration.GetInvestorUserDetailsByToken, params
    }).then((response) => {
      if (response.success == true && response.data != null) {
        this.setState({email: response.data.Email});
      }
    });
    }
  };
  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }
  authenticate = (e) => {
    e.preventDefault();

    this.onChangeOfField(e.target.elements.email, false);
    this.onChangeOfField(e.target.elements.password, false);
    this.onChangeOfField(e.target.elements.confirmPassword, false);

    
    if(!this.validateForm(this.state.errors)) {
      return
     } 
    const {email, password,confirmPassword, secretKey} = this.state;
    
    var postBody = {
      email: email,
      password: password,
      type: "RESET",
    };
    apiPostAsync({
      url: API_URL.UserConfiguration.ForgotPassword,
      postBody: postBody,
    }).then((response) => {
      if (response.success == false) {
        ShowToast(response.message, toastType.ERROR, 5000, 200);
      } else if (response.success == true) {
        localStorage.setItem('password', password);
        localStorage.setItem('email', email);
        window.location = secretKey !== undefined ?  'otp/false/' + secretKey : 'otp/false'
      }
    });
  };

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  onChangeOfField = (e, isPrevent = true) => {
    let name = '', value = '';
    if (isPrevent) {
        e.preventDefault();
        name = e.target.name;
        value = e.target.value;
    } else {    
        name = e.name;
        value = e.value;       
    }
    let errors = this.state.errors;
    switch (name) {
        case 'email': 
        errors.email = 
        value == ''
          ? UserConstant.EmailError
          : value != '' && this.validateEmail(value) ? '' : UserConstant.ValidEmailAddress;    
          break;
        case 'password': 
          errors.password = 
            value == ''
              ? "New password is required."
              : !validatePassword(value) ? 
               "New password must be 8 to 12 alphanumeric characters and at-least one of the special characters in (!,@,#,$,%,^,&,*). "
              : '';
          break;
         case 'confirmPassword': 
           errors.confirmPassword = 
              value == ''
                ? "Confirm new password is required."
                : value !== this.state.password ? 'New password and confirm new password must be same.' : '';     
            break;
        default:
          break;
      }
      this.setState({errors, [name]: value});
  }

  back = (e) => {
    window.location = '/login'
  }
  render() {
    const {email, password, confirmPassword, secretKey, errors} = this.state;
    let inputStyle = {
    };
    secretKey !== '' ? inputStyle = { backgroundColor: "#eaeef1"  }  : inputStyle;
    return (
      <form onSubmit={this.authenticate} noValidate>
       <div className="m-2">
          <label className = "col-md-12  p-0 text-white"> {LoginModel.email} <span className="text-red">*</span></label>
          <div className="col-md-12  p-0">
            {secretKey != undefined ?
            <TextField 
                    type="text"
                    variant="outlined"
                    maxLength={50}
                    value = {email}
                    onChange={this.onChangeOfField}
                    name="email"
                    className = "input-style"
                    disabled = {secretKey !== undefined}
                    readOnly = {secretKey !== undefined}
                    InputProps={{
                      className: "text-blue",
                  }}
                  error = {errors.email}
                  style={inputStyle}
                /> : <TextField 
                    type="text"
                    variant="outlined"
                    maxLength={50}
                    value = {email}
                    onChange={this.onChangeOfField}
                    name="email"
                    className = "input-style"
                    InputProps={{
                      className: "text-blue"               
                  }}
                  error = {errors.email}

                />}
                 <FormHelperText className="text-red">
                    {errors.email}
                  </FormHelperText>
          </div>
        </div>
        <div className="m-2">
            <label className = "col-md-12  p-0 text-white"> {'New Password'} <span className="text-red">*</span></label>
            <div className="col-md-12  p-0">
              <TextField 
                      type="password"
                      variant="outlined"
                      maxLength={50}
                      inputValue={password}
                      onChange={this.onChangeOfField}
                      name="password"
                      className = "input-style"
                      InputProps={{
                        className: "text-blue"               
                    }}
                    error = {errors.password}

                  />
                  <FormHelperText className="text-red">
                    {errors.password}
                  </FormHelperText>
            </div>
             
        </div>
        <div className="m-2">
            <label className = "col-md-12  p-0 text-white"> {'Confirm New Password'} <span className="text-red">*</span></label>
            <div className="col-md-12  p-0">
              <TextField 
                      type="password"
                      variant="outlined"
                      maxLength={50}
                      inputValue={confirmPassword}
                      onChange={this.onChangeOfField}
                      name="confirmPassword"
                      className = "input-style"
                      InputProps={{
                        className: "text-blue"               
                    }}
                    error = {errors.confirmPassword}
                  />
                <FormHelperText className="text-red">
                    {errors.confirmPassword}
                </FormHelperText>
            </div>
        </div>
        <div className="m-2  pb-4">
            <Button
                className = "col-md-5 font-weight-bold text-white border-class"
                type = 'submit'
              >
              {LoginModel.submit}
            </Button>
            {secretKey !== undefined ? '' : 
            <Button
                className = "ml-2 col-md-5 font-weight-bold text-white border-class"
                onClick={() => {
                  this.back();
                }}
              >
              {LoginModel.back}
            </Button> }
        </div>
    </form>

    );
  }
}
export default ChangePassword

