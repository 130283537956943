import React, { Component } from 'react'
import Table from '../../common/component/table/table'
import { InvestorConstant, Status } from '../../common/constants/index'
import AddEditInvestor from './add-edit/addEditInvestor'
import { apiGet, uploadExcel } from "../../common/component/utils/api-service"
import { API_URL } from '../../common/constants/apiConfiguration'
import ConfirmDialog from '../../common/component/dialog-box/ConfirmDialog'
import {ScheduledK1Constant} from '../../common/constants/index'
import { ShowToast, toastType } from "../../common/component/utils/toast-service";
import Workbook from "react-excel-workbook";
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import SwapInvestor from './swap-investor'

const uploadColumns = [
  {
  key: 'investorTypeName',
  name: 'Investor Type'
  },
  {
    key: 'firstName',
    name: 'First Name'
  },
  {
    key: 'lastName',
    name: 'Last Name'
  },
  {
    key: 'name',
    name: 'Legal Entity Name'
  },
  {
    key: 'k1ReferenceName',
    name: 'Tax Id'
  },
  {
    key: 'email',
    name: 'Email'
  },
  {
    key: 'clientType',
    name: 'Client Type'
  },
  {
    key: 'streetAddress1',
    name: 'Address Line 1'
  },
  {
    key: 'streetAddress2',
    name: 'Address Line 2'
  },
  {
    key: 'town',
    name: 'City'
  },
  {
    key: 'state',
    name: 'State'
  },
  {
    key: 'zipcode',
    name: 'Zip Code'
  },
  {
    key: 'reason',
    name: 'Reason'
  }
];
class Investor extends Component {
  constructor (props) {
    super(props)
    this.state = {
      FilteredInvestors: [],
      OriginalInvestors: [],
      isOpenConfirmationPanel: false,
      IsOpen: false,
      message: '',
      actionType: '',
      ClientId: 0,
      InvestorName: '',
      investorNameValue: '',
      k1referenceName: '',
      active: '',
      email: '',
      investorTypeList: [],
      status: '',
      invalidRecord: [],
      openUploadFileSuccess: false,
      IsSwapOpen: false
    }
  }

  componentDidMount () {
    this.GetInvestors();
    this.getMaster();
  }
  getMaster = () =>{
    const params = {
      isInvestorType: true
    }
    apiGet({ url: API_URL.masterData , params}).then((data) => {
    if(data && (data.investorType.success)) {      
      this.setState({ investorTypeList: data.investorType.data });
    }
    });
  }
  GetInvestors = () => {
    const {investorNameValue, k1referenceName,active,status, email} = this.state
    apiGet({ url: API_URL.GetInvestors }).then((data) => {
      if (data.success) {
          var masterInvestorData =  data.data;
          for (var i in  masterInvestorData) {
            let users = []
            let userName = masterInvestorData[i].Username != null ? masterInvestorData[i].Username.split(",") : [];
            let userApprovalStatus = masterInvestorData[i].UserApprovalStatus != null ? masterInvestorData[i].UserApprovalStatus.split(",") : [];
            let userEmail = masterInvestorData[i].Useremail != null ? masterInvestorData[i].Useremail.split(",") : [];
            let isActiveUser = masterInvestorData[i].IsActiveUser != null ? masterInvestorData[i].IsActiveUser.split(",") : [];
            for (let i=0;i< userName.length;i++) {
              users.push({
                      userName:  userName[i] ? userName[i].trim() : '', 
                      isActive: isActiveUser[i] ? isActiveUser[i].trim() : '', 
                      status: userApprovalStatus[i] ? userApprovalStatus[i].trim() : '', 
                      email: userEmail[i] ? userEmail[i].trim() : ''
                    });
            }
            const yesNo =  masterInvestorData[i].IsDeleted ? InvestorConstant.Yes: InvestorConstant.No;
            masterInvestorData[i].Deleted = yesNo; 
            masterInvestorData[i].TotalUsers = {TotalUsers:masterInvestorData[i].TotalUsers, userData: users, InvestorName: masterInvestorData[i].K1ReferenceName }
            const status = masterInvestorData[i].IsDeleted ? Status.Removed : ''
            masterInvestorData[i].Action = {ClientId:masterInvestorData[i].ClientId, InvestorName: masterInvestorData[i].Name, ParentName: "InvestorList", Action: ["Edit", "Delete", "SendEmail", "Swap"],
                                            Active:masterInvestorData[i].IsActive, Status:status}
            masterInvestorData[i].IsActive = {IsActive:masterInvestorData[i].IsActive, ClientId:masterInvestorData[i].ClientId,InvestorName:masterInvestorData[i].Name, IsDisabled: masterInvestorData[i].IsDeleted }
            if (masterInvestorData[i].LastLogin != null) {
              var lastLogin  = new Date(masterInvestorData[i].LastLogin);
              masterInvestorData[i].LastLogin = lastLogin.toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false})
                                                   + ' ' + masterInvestorData[i].TimeZone;
            } 

          }
          const param  = {investorName: investorNameValue, k1referenceName,active, status, email}
          const filteredList = this.FilterListUsingParam(param, masterInvestorData);

          this.setState({ OriginalInvestors: data.data, FilteredInvestors: filteredList });      
      }
    });
  }

  TakeAction = (actionType, data) => {
    (data)
    if (actionType == 'Edit' ) {
      this.setState({IsOpen: true, ClientId: data.ClientId, actionType: actionType});
    } if (actionType == 'Swap' ) {
      console.log(data)
      this.setState({IsSwapOpen: true, ClientId: data.ClientId, actionType: actionType});
    } else if (actionType == 'Delete') {
      const message  = ScheduledK1Constant.DeleteInvestorMessage + ' "' + data.InvestorName + '"?'
      this.setState({isOpenConfirmationPanel: true, message: message, 
        actionType: actionType, ClientId: data.ClientId, InvestorName: data.InvestorName});
    } else if (actionType == 'SendEmail') {
      this.SendMailToInvestor(data);
    }
  }

  SendMailToInvestor(documentObj) {
    const params = {
      clientId: documentObj.ClientId,
    };
    apiGet({url: API_URL.SendEmailToInvestor,params}).then((data) => {
      if(data.success) { 
        ShowToast(data.message,  toastType.SUCCESS, 5000);
      } else {
        ShowToast(data.message,  toastType.ERROR, 5000);
      }
    });
  }

  ArchiveInvestor = () => {
    const {ClientId, InvestorName } = this.state;
    const params = {
      ClientId: ClientId
    };
    apiGet({ url: API_URL.ArchiveInvestor,params }).then((data) => {
      if(data.success) {
        this.GetInvestors();
        ShowToast(InvestorName + ScheduledK1Constant.InvestorDeleteSuccess ,  toastType.SUCCESS, 5000);
        this.setState({isOpenConfirmationPanel: false, message: "", actionType: '', K1ClientDocumentId: '', InvestorName: ''});          
      }
    });
  }
  PerformAction = (value) => {
    const {actionType, InvestorName, ClientId } = this.state;
    if (value && actionType === 'Delete' ) {
      this.ArchiveInvestor() ;
    } else if (value && (actionType === 'Activate' || actionType ===  'Deactivate')){
      const message =  InvestorName + (actionType ===  'Deactivate' ? 
                                ScheduledK1Constant.DeactivateSuccess : ScheduledK1Constant.ActivateSuccess)
      this.ActivateDeactivateInvestor(ClientId, actionType, message)
    } else {
      this.setState({isOpenConfirmationPanel: false, message: "", actionType: '', ClientId: 0, InvestorName: ''})
    }
  }
  OpenAddEditPop = ( isOpen) => {
    if (this.state.actionType === 'Swap') {
      this.setState({IsSwapOpen: isOpen, ClientId: 0, actionType: '' });
    } else {
      this.setState({IsOpen: isOpen, ClientId: 0 });
    }
  }
  ActivateOrDeactivate = (e, data) => {
    if (!e.target.checked) {
      const message  = ScheduledK1Constant.Confirmation + ' "' + data.InvestorName + '" inactive?'
       this.setState({isOpenConfirmationPanel: true, message: message, 
        actionType: 'Deactivate', ClientId: data.ClientId, InvestorName: data.InvestorName});
    } else {
     const message  = ScheduledK1Constant.Confirmation + ' "' + data.InvestorName + '" active?'  
     this.setState({isOpenConfirmationPanel: true, message: message, 
      actionType: 'Activate', ClientId: data.ClientId, InvestorName: data.InvestorName});
    }
  }
  ActivateDeactivateInvestor = (clientId, action, message) => {
    const params = {
      clientId: clientId,
      action: action
    };
    apiGet({ url: API_URL.ActivateDeactivateInvestor,params }).then((data) => {
      if(data.success) {
        this.GetInvestors();
        this.setState({isOpenConfirmationPanel: false, message: "", actionType: '', ClientId: '', InvestorName: ''})
        ShowToast(message ,  toastType.SUCCESS, 5000);
      }
    });
  }
  FilterListUsingParam = (param, originalList) => { 
    var filteredList = originalList;
     if (filteredList.length > 0 && param.investorName != "" && param.investorName.length >= 0) {
       filteredList = filteredList.filter((item) => {
         if (item.Name != null && item.Name.trim().toLowerCase().includes(param.investorName.trim().toLowerCase()))
          return item;
      });
     }
     if (filteredList.length > 0 && param.k1referenceName != "" && param.k1referenceName.length >= 0) {
      filteredList = filteredList.filter((item) => {
        if (item.K1ReferenceName != null && item.K1ReferenceName.trim().toLowerCase().includes(param.k1referenceName.trim().toLowerCase()))
         return item;
     });
    }
     if (filteredList.length > 0 && param.active !== '' && (param.active == 0 || param.active == 1 )) {
      filteredList = filteredList.filter((item) => {
        if (item.IsActive.IsActive == param.active)
           return item;
      });
    }
    if (filteredList.length > 0 && param.email !== '' && param.email !== null && param.email !== undefined) {
      filteredList = filteredList.filter((item) => {
        if (item.Email !== undefined && item.Email != null && item.Email.trim().toLowerCase().includes(param.email.trim().toLowerCase()))
          return item;
      });
    }
    if (filteredList.length > 0 && param.status != "" && param.status.length >= 0) {
      filteredList = filteredList.filter((item) => {
        if (item.InvestorType != null && item.InvestorType.trim().toLowerCase().includes(param.status.trim().toLowerCase()))
         return item;
     });
    }
    return filteredList;
  }
  FilterList = (param) => { 
    const { OriginalInvestors } = this.state;
    const filteredList = this.FilterListUsingParam(param, OriginalInvestors);
    this.setState({ FilteredInvestors: filteredList, investorNameValue: param.investorName, k1referenceName: param.k1referenceName,active: param.active,status: param.status, email: param.email });
  }
  readExcel = (files) => {
    uploadExcel(API_URL.UploadExcel, {files}).then((data) => {
      if (data.success) {
        if (data.data && data.data.length > 0) {
          const invalidRecord = [];
          data.data.forEach(x=> {
            const obj = {}
            uploadColumns.forEach(y=> {
              obj[y.key] = x[y.key];
            });
            invalidRecord.push(obj);
          });
          this.setState({invalidRecord,  openUploadFileSuccess: true});
        } else {
          ShowToast('All Investor(s) record is imported successfully.',  toastType.SUCCESS, 5000); 
        }
        this.GetInvestors() 
      } else {
        ShowToast(data.message,  toastType.ERROR, 5000);      
      }
    });
  }
  DownloadInvestor = () => {
    const {invalidRecord} = this.state;
    return (
      <Workbook
        filename={
           "Invalid Investor List.xlsx"
        }
        element={
          <ListItem
            button
            divider           
          >
            <ListItemIcon>
              {" "}
              <DownloadIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Download Invalid Record(s)" />
          </ListItem>
        }
      >
        <Workbook.Sheet data={invalidRecord} name="Invalid Record">
          {uploadColumns.map((column, index) => (
            <Workbook.Column label={column.name} value={column.key} />
          ))}
        </Workbook.Sheet>
      </Workbook>
    );
  }
  GetUploadFileMessage = () => {
    return <><p>Investor Record(s) imported successfully but there are some invalid record(s). Click on Download icon for the same. {this.DownloadInvestor()}</p></>
  }
  render () {
    const { FilteredInvestors, IsSwapOpen, IsOpen, isOpenConfirmationPanel, message, ClientId, actionType,investorTypeList, openUploadFileSuccess } = this.state
    return (
            <>
              <Table
                openPopup = {this.OpenAddEditPop}
                header = {InvestorConstant.header}
                investorList = {[]}
                statusList= {[]}
                documentTypeList = {investorTypeList}
                columns={InvestorConstant.InvestorCells}
                data={FilteredInvestors}
                performAction = {this.TakeAction}
                filterList = {this.FilterList}
                handleChange = {this.ActivateOrDeactivate}      
                readExcel = {this.readExcel}
              /> 
              <SwapInvestor actionType = {actionType} getInvestors = {this.GetInvestors} ClientId = {ClientId} IsOpen = {IsSwapOpen}  openPopup = {this.OpenAddEditPop}/>

              <AddEditInvestor actionType = {actionType} getInvestors = {this.GetInvestors} ClientId = {ClientId} IsOpen = {IsOpen}  openPopup = {this.OpenAddEditPop}/>
              <ConfirmDialog
                  IsOpen={isOpenConfirmationPanel}
                  Message={message}
                  OnHandelClick={this.PerformAction}
                  AgreeText = "OK"
                  DisAgreeText = "CANCEL"
              />
               <ConfirmDialog
                  IsOpen={openUploadFileSuccess}
                  Message={this.GetUploadFileMessage()}
                  OnHandelClick={() => {this.setState({openUploadFileSuccess: false})}}
                  AgreeText = "Ok"
                  hideDisAgree = {true}
                  header = "Invalid Investor Record(s)"
              />
            </>
    )
  }
}

export default Investor
