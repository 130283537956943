import store from '../../../store/appStore'

export function IsAdmin () {
  const { configuredUser } = store.getState()

  if ((configuredUser || {}).userData) {
    const userConfiguration = configuredUser.userData || {}
    if (
      (
        (((configuredUser.userData || {}).groups || [])[0] || {}).groupName || ''
      ).toLowerCase() === 'k1_administrator'
    ) { return true }
  }
  return false
}

function GetUserConfiguration () {
  const { configuredUser } = store.getState()
  return configuredUser.userData || {}
}

function GetSectionPermission (parentSectionId, subSectionId = 0) {
  const { configuredUser } = store.getState()
  const permission = ((configuredUser.userData || {}).userPermissions || []).find(
    (x) =>
      x.parentSectionId == parentSectionId &&
      (x.subSectionId == subSectionId ||
        (subSectionId == 0 && x.subSectionId == parentSectionId))
  )

  if (permission != undefined) {
    return permission
  }
  return {}
}

function IsSectionVisible (parentSectionId, subSectionId = 0) {
  const permission = GetSectionPermission(parentSectionId, subSectionId)
  if (permission != undefined) {
    if (permission.accessTypeId > 0 && permission.accessTypeId < 4) return true
  }
  return false
}

export {
  GetSectionPermission,
  IsSectionVisible,
  GetUserConfiguration
}
