import React, { Component } from 'react'
import { UserConstant,Status, UploadFileConstant } from '../../../common/constants/index'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  FormHelperText,
  Select,  
  Chip,
  MenuItem 
} from '@material-ui/core'
import { apiGetAsync, apiPostAsync, apiPutAsync } from '../../../common/component/utils/api-service'
import { API_URL } from '../../../common/constants/apiConfiguration'
import { ShowToast, toastType } from "../../../common/component/utils/toast-service";
import Loader from '../../../common/component/loader/loader'
import ConfirmDialog from '../../../common/component/dialog-box/ConfirmDialog';
import { getBackYearFromDate } from '../../../common/component/utils/date';
import Autocomplete from '../../../common/component/autocomplete/autocomplete'
import { TAX_ACCESS_TYPE } from '../../../common/constants/appConstants'


class AddEditUser extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fullWidth: true,
      maxWidth: 'md',
      firstName: '',
      lastName: '',
      email: '',
      relation: '',
      investor: '',
      taxAccessType: '',
      errors: {
        firstName: '',
        lastName: '',
        email: '',
        relation: '',
        investor: '',
        startTaxYear: '',
        endTaxYear: '',
        taxAccessType: ''
     },
     UserId: 0,
     loading: false,
     isOpenConfirmationPanel: false,
     message: '',
     isUndo: false,
     UserIdValue: 0,
     selectedInvestor: [],
     startTaxYear: '',
     endTaxYear: '',
     taxYearList: getBackYearFromDate(5),
     ClientId: 0,
     investorValue: [],
     investorName: []
    }
  }
 
  componentWillReceiveProps(newprops) {
    if(newprops.UserId!=this.state.UserId) {
      this.setState({UserId: newprops.UserId});
      this.setState({ClientId: newprops.ClientId});
      if( newprops.UserId > 0 && newprops.actionType === 'Edit')
        this.GetUserById(newprops.UserId, newprops.ClientId);
    }
  }
  GetUserById = (userId, clientId) => {
    this.setState({loading: true})
    const params = {
      UserId: userId,
      ClientId: clientId
    };
    apiGetAsync({ url: API_URL.GetUserById,params }).then((data) => {
      if(data.success) {
        const value = data.data
        this.setState({ 
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            investor: value.clientId,
            relation: value.relation,
            loading: false,
            selectedInvestor: value.investorList ? value.investorList.split('|') : [],
            startTaxYear: value.startTaxYear,
            endTaxYear: value.endTaxYear == 0 ? '' : value.endTaxYear,
            taxAccessType: value.taxAccessTypeId
         });       
      }
    });
  }
  
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  onChangeHandle = (e, isPrevent = true) => {
    let name = '', value = '';
    if (isPrevent) {
        e.preventDefault();
        name = e.target.name;
        value = e.target.value;
    } else {    
        name = e.name;
        value = e.value;       
    }
    let errors = this.state.errors;
    switch (name) {
        case 'taxAccessType': 
          errors.taxAccessType = 
            value == ''
              ? UserConstant.AccessTypeError
              : '';
          break;
        case 'firstName': 
          errors.firstName = 
            value == ''
              ? UserConstant.FirstNameError
              : '';
          break;
        case 'lastName': 
          errors.lastName = 
            value == ''
              ? UserConstant.LastNameError
              : '';
          break;
        case 'email': 
          errors.email = 
            value == ''
              ? UserConstant.EmailError
              : value != '' && this.validateEmail(value) ? '' : UserConstant.ValidEmailAddress;
          break;
        case 'relation': 
          errors.relation = 
            value == ''
              ? UserConstant.RelationError
              : '';
          break;
        case 'investor': 
          errors.investor = 
              value == ''
                ? UserConstant.InvestorError
                : '';
          break;
          case 'startTaxYear': 
          errors.startTaxYear = 
              value == ''
                ? UserConstant.StartTaxYearError
                : '';
          break;
          case 'endTaxYear': 
          errors.endTaxYear = 
            value !== '' && value < this.state.startTaxYear
                ? UserConstant.EndTaxYearError
                : '';
          break;
        default:
          break;
      }
      this.setState({errors, [name]: value});
 }
 validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}
clearField = () => {
  this.setState({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    relation: '',
    investor: '',
    errors: {},
    startTaxYear: '',
    endTaxYear: '',
    investorValue: [],
    investorName: [],
    taxAccessType: ''
  })
  this.props.openPopup(false)
}
handleSubmit = (e) => {
  e.preventDefault();  
  const {UserId} = this.state;
  this.onChangeHandle(e.target.elements.taxAccessType, false);
  this.onChangeHandle(e.target.elements.firstName, false);
  this.onChangeHandle(e.target.elements.lastName, false);
  this.onChangeHandle(e.target.elements.email, false);
  this.onChangeHandle(e.target.elements.relation, false);
  this.onChangeHandle(e.target.elements.startTaxYear, false);
  this.onChangeHandle(e.target.elements.endTaxYear, false);
  UserId <=0 ? this.SelectInvestor(this.state.investorName, this.state.investorValue) : ''
  if(this.validateForm(this.state.errors)) {
    this.SaveUser();
  } 
}
SaveUser = () => {
  this.setState({loading: true})
  const {firstName, lastName,email, relation, investor,errors, UserId, startTaxYear, endTaxYear, investorName, taxAccessType} = this.state;
  const {investorList} = this.props
  const investorId = [];
  if (UserId <= 0) {
    if (investorName.length == 1 && investorName.find(x=> x === 'All') !== undefined) {
      investorList.filter((item)=>{ 
        if (item.maskedName !== 'All') {
          investorId.push(item.clientId)
      }  });
    } else {
      for(let i=0; i< investorName.length; i++) {
        investorList.filter((item)=>{ 
            if (item.maskedName == investorName[i]) {
                investorId.push(item.clientId)
            }
        });
    }
   }
  } else {
    investorId.push(investor)
  }
 
  const postBody = { taxAccessTypeId: parseInt(taxAccessType), firstName, lastName, email,relationship: relation, ClientId: investorId.join('|'), UserId: UserId== "" ? 0 : UserId, ApproveStatus: Status.Approved, startTaxYear: parseInt(startTaxYear), endTaxYear: parseInt(endTaxYear)  };
  apiPostAsync({ url: API_URL.SaveUser, postBody }).then((data) => {
    this.setState({loading: false});
    if(data.success) {
      ShowToast(data.message,  toastType.SUCCESS, 5000);
      this.clearField();
      this.props.getUsers();
    } else {
      if (data && data.data.EmailStatus == 0) {
        errors.email = UserConstant.EmailIdAlreadyTaken;
      } else {
        if (data && data.data.PendingUserStatus == 0) {
          errors.email = UserConstant.PendUserStateMessage;
        } 
        else if (data && data.data.UserExistsStatus == 0) {
          this.setState({isOpenConfirmationPanel: true, message: UserConstant.UndoUser})
        }
      }
      this.setState({errors})
    }
  });
}
PerformAction = (value) => {
  const { isUndo } = this.state;
  if (value && !isUndo) {
    this.EnableUser();
  } else {
    this.setState({isOpenConfirmationPanel: false, message: ""})
  }
}
EnableUser = () => {
  this.setState({loading: true});
  const { email } = this.state;
  const params = {
    email: email,
    status: Status.Approved
  };
  apiPutAsync({ url: API_URL.EnableUser,params }).then((data) => {
    if(data.success) {
      ShowToast(UserConstant.UserEnable,  toastType.SUCCESS, 5000);
      this.setState({loading: false, isOpenConfirmationPanel: false, message: ""});
      this.clearField();
      this.props.getUsers();
    }
  });
}

SelectInvestor = (investorName, values, investorId) => {
  let errors = this.state.errors
    let investorList = this.props.investorList
    if(investorName === undefined || investorName.length <=0) {
      errors.investor = UserConstant.InvestorError
    } else {
      errors.investor = ''
      if(investorName.length > 1) {
        investorName = (investorName || []).filter(x => x !== 'All');
        values = (values || []).filter(x => x.maskedName !== 'All');
      }
      if (investorList.length > 1 && (investorList || []).filter(x => x.maskedName !== 'All').length === (investorName || []).length) {
        investorName = []
        investorName = ["All"];
        values = [];
        values = [{ maskedName: 'All' }]
      }
   }
  this.setState({investorName : investorName, investorValue: values, errors})
}

render () {
    const { taxAccessType, fullWidth, maxWidth, errors, firstName,lastName, email, relation,
            investor,UserId, loading, isOpenConfirmationPanel, message, selectedInvestor, startTaxYear, endTaxYear, taxYearList, investorValue } = this.state
    const { IsOpen, investorList, relationList } = this.props
    if (investorList?.length > 1 && investorList.find(x => x.maskedName === 'All') === undefined) {
      investorList.unshift({ maskedName: 'All' });
    }
    return (<>
    <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        disableBackdropClick
        open={IsOpen}
        aria-labelledby="customized-dialog-title"
        onClose={() => this.props.openPopup(false)}
    >
       <Loader loading = {loading} />
       <DialogTitle id="customized-dialog-title" className="text-blue font-weight-bold" onClose={() => this.props.openPopup(false)}>
         <span className="text-blue font-weight-bold">{UserId > 0 ? UserConstant.UpdateUser : UserConstant.AddUser}</span>
        </DialogTitle>
        <form onSubmit={this.handleSubmit} noValidate>
        <DialogContent dividers>
            <DialogContentText className="text-blue">
            <div className="row m-2">
              <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UserConstant.FirstName} <span className="text-red">*</span></label>
              <div className="col-sm-4 col-xs-12">
                  <TextField
                      variant="outlined"
                      className = 'w-100'
                      name = 'firstName'
                      value = {firstName}
                      placeholder= {UserConstant.FirstName}
                      InputProps={{
                        className: 'text-blue'
                      }}
                      onChange = {this.onChangeHandle}
                      error = {errors.firstName}
                  />
                  <FormHelperText className="text-red">
                      {errors.firstName}
                  </FormHelperText>
                </div>
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UserConstant.LastName} <span className="text-red">*</span></label>
                <div className="col-sm-4 col-xs-12">
                  <TextField
                      variant="outlined"
                      className = 'w-100'
                      name = 'lastName'
                      value = {lastName}
                      placeholder= {UserConstant.LastName}
                      InputProps={{
                        className: 'text-blue'
                      }}
                      onChange = {this.onChangeHandle}
                      error = {errors.lastName}
                  />
                  <FormHelperText className="text-red">
                      {errors.lastName}
                  </FormHelperText>
                </div>
              </div>
              <div className="row m-2">
              <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UserConstant.EmailAddress}<span className="text-red">*</span></label>
                <div className="col-sm-4 col-xs-12">
                  <TextField
                      variant="outlined"
                      className = 'w-100'
                      name = 'email'
                      value = {email}
                      placeholder= {UserConstant.EmailAddress}
                      InputProps={{
                        className: 'text-blue'
                      }}
                      onChange = {this.onChangeHandle}
                      error = {errors.email}
                  />
                  <FormHelperText className="text-red">
                      {errors.email}
                  </FormHelperText>
                </div>
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UserConstant.Relation} <span className="text-red">*</span></label>
                <div className="col-sm-4 col-xs-12">
                  <Select
                      displayEmpty
                      className="w-100 text-blue"
                      name = 'relation'
                      value = {relation}
                      onChange = {this.onChangeHandle}
                      error = {errors.relation}
                  >
                      <MenuItem className="text-blue" value=""> {UserConstant.Relation }</MenuItem>
                      {
                          relationList.map(relation => {
                              return (
                                  <MenuItem className="text-blue" key = {relation.Name} value={relation.Name}> {relation.Name}</MenuItem>
                              )
                          })
                      }
                  </Select>
                  <FormHelperText className="text-red">
                    {errors.relation}
                  </FormHelperText>
                </div>    
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UserConstant.StartTaxYear} <span className="text-red">*</span></label>
                <div className="col-sm-4 col-xs-12">
                <Select
                                displayEmpty
                                className="w-100 text-blue"
                                name = 'startTaxYear'
                                value = {startTaxYear}
                                onChange = {this.onChangeHandle}
                                error = { errors.startTaxYear}
                            >
                                <MenuItem className="text-blue" value=""> {UserConstant.StartTaxYear }</MenuItem>
                                {
                                    taxYearList.map(year => {
                                        return (
                                            <MenuItem className="text-blue" key = {year} value={year}> {year}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <FormHelperText className="text-red">
                                {errors.startTaxYear}
                            </FormHelperText>
                </div>    
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UserConstant.EndTaxYear}</label>
                <div className="col-sm-4 col-xs-12">
                <Select
                                displayEmpty
                                className="w-100 text-blue"
                                name = 'endTaxYear'
                                value = {endTaxYear}
                                onChange = {this.onChangeHandle}
                                error = { errors.endTaxYear}
                            >
                                <MenuItem className="text-blue" value=""> {UserConstant.EndTaxYear }</MenuItem>
                                {
                                    taxYearList.map(year => {
                                        return (
                                            <MenuItem className="text-blue" key = {year} value={year}> {year}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <FormHelperText className="text-red">
                                {errors.endTaxYear}
                            </FormHelperText>
                </div>          
              </div>
              {UserId > 0 ?   
                <div className="row m-2">
                  <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UserConstant.Investor}</label>
                  <div className="col-sm-4 col-xs-12">              
                   { (selectedInvestor || []).map((investor, i) => (
                        <Chip key= {i} style = {{ backgroundColor: '#d6e7f3f2', color: '#064473', margin: '3px', fontSize: '12px' }} label= {investor} />
                    )) }
                  </div> 
                  <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UserConstant.AccessType}</label>
                  <div className="col-sm-4 col-xs-12">
                  <Select
                      displayEmpty
                      className={'w-100 text-blue'}
                      name = 'taxAccessType'
                      value = {taxAccessType}
                      onChange = {this.onChangeHandle}
                      error = {errors.taxAccessType}
                  >
                      <MenuItem className="text-blue" value=""> {UploadFileConstant.SelectAccessType }</MenuItem>
                      {
                          TAX_ACCESS_TYPE.map(accessType => {
                              return (
                                  <MenuItem className="text-blue" key = {accessType.id} value={accessType.id}> {startTaxYear !== undefined && startTaxYear !== '' && startTaxYear !== null ? startTaxYear + ' and' : ''} {accessType.value}</MenuItem>
                              )
                          })
                      }
                  </Select> 

                  <FormHelperText className="text-red">
                    {errors.taxAccessType}
                  </FormHelperText>
                </div>
 
                </div>  
              :
              <>
              <div className="row m-2">
               
                <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UploadFileConstant.SelectInvestor} <span className="text-red">*</span></label>
                <div className="col-sm-10 col-xs-12">
                  {/* <Select
                      displayEmpty
                      className={UserId > 0 ? 'w-100 text-blue disable-form-control' : 'w-100 text-blue'}
                      name = 'investor'
                      value = {investor}
                      onChange = {this.onChangeHandle}
                      error = {errors.investor}
                      disabled={UserId > 0}
                  >
                      <MenuItem className="text-blue" value=""> {UploadFileConstant.SelectInvestor }</MenuItem>
                      {
                          investorList.map(client => {
                              return (
                                  <MenuItem className="text-blue" key = {client.clientId} value={client.clientId}> {client.name}</MenuItem>
                              )
                          })
                      }
                  </Select>  */}
                  <Autocomplete value = {investorValue} tagLimits = {1} placeholder={UploadFileConstant.SelectInvestor} options = {investorList} selectedValue = {this.SelectInvestor}/>

                  <FormHelperText className="text-red">
                    {errors.investor}
                  </FormHelperText>
                </div>
              </div> 
              <div className="row m-2">
              <label className="col-sm-2 col-xs-12 col-form-label text-blue font-weight-bold"> {UserConstant.AccessType} <span className="text-red">*</span></label>
                  <div className="col-sm-4 col-xs-12">
                  <Select
                      displayEmpty
                      className={'w-100 text-blue'}
                      name = 'taxAccessType'
                      value = {taxAccessType}
                      onChange = {this.onChangeHandle}
                      error = {errors.taxAccessType}
                  >
                      <MenuItem className="text-blue" value=""> {UploadFileConstant.SelectAccessType }</MenuItem>
                      {
                          TAX_ACCESS_TYPE.map(accessType => {
                              return (
                                  <MenuItem className="text-blue" key = {accessType.id} value={accessType.id}> {startTaxYear !== undefined && startTaxYear !== '' && startTaxYear !== null ? startTaxYear + ' and' : ''} {accessType.value}</MenuItem>
                              )
                          })
                      }
                  </Select> 

                  <FormHelperText className="text-red">
                    {errors.taxAccessType}
                  </FormHelperText>
                </div>
              </div>
              </> }
             </DialogContentText>
        </DialogContent>
        <DialogActions>
            {
                UserId > 0
                  ? <Button className="btn btn-sm btn-blue" type="submit">
                   {UserConstant.Update}
                 </Button>
                  : <Button className="btn btn-sm btn-blue" type="submit">
                   {UserConstant.Save}
                </Button>
            }
            <Button className="btn btn-sm btn-blue" onClick={() => { this.clearField() }} type="button">
                 {UserConstant.Cancel}
            </Button>
        </DialogActions>
        </form>
    </Dialog>
    <ConfirmDialog
        IsOpen={isOpenConfirmationPanel}
        Message={message}
        OnHandelClick={this.PerformAction}
        AgreeText = "OK"
        DisAgreeText = "CANCEL"
     />
    </>)
  }
}

export default AddEditUser
