import React, { Component } from 'react'
import LinkTab from '../../common/component/link-tab/linkTab'
import './index.less'
import K1Logo from '../../resources/images/K1Logo.png'
import ChangePassword from './change-password/changePassword'
import { Route, Switch, Redirect } from 'react-router-dom'
import ValidateOtp from './ValidateOtp/ValidateOtp'
class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      <div className="container">
          <div className="d-flex justify-content-center align-items-center " >
            <div className="col-md-12 login-box">
            <img src={K1Logo} alt="logo"/>
              <Switch>
                <Redirect exact from="/" to='/login' />
                <Route exact path='/login' component={LinkTab}/>
                <Route exact path='/change-password/:token?' component={ChangePassword}/>
                <Route exact path='/otp/:isLogin/:token?' component={ValidateOtp}/>
              </Switch>
            </div>
        </div>
      </div>
    )
  }
}
export default Login
