import React from 'react'
import {
  TextField,
  Button,
  FormHelperText
} from '@material-ui/core/'
import { ShowToast, toastType } from "../../../common/component/utils/toast-service";
import { API_HEADERS, API_URL } from '../../../common/constants/apiConfiguration';
import { apiPostAsync, apiGetAsync } from "../../../common/component/utils/api-service";
import { connect } from 'react-redux'

class ValidateOtp extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      OTP: '',
      secretKey: props.match.params.token,
      isLogin: props.match.params.isLogin,
      email: localStorage.getItem('email'),
      password: localStorage.getItem('password'),
      errors: {
        OTP: ''
      }
    }
  }
  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }
  verifyOTP = (e) => {
    e.preventDefault();

    this.onChange(e.target.elements.OTP, false);
    
    if(!this.validateForm(this.state.errors)) {
      return
     } 
    const { OTP, email, password, isLogin } = this.state;
    
    var postBody = {
      email: email,
      password: password,
      otp: OTP,
      type: isLogin === "true" ? "LOGIN" : "RESET",
      secretToken: this.state.secretKey !== null && this.state.secretKey !== undefined ? this.state.secretKey : null
    };
    apiPostAsync({
      url: API_URL.UserConfiguration.VerifyOTP,
      postBody: postBody,
    }).then((response) => {
      if (response.success == false) {
        ShowToast(response.message, toastType.ERROR, 5000, 200);
      } else if (response.success == true) {
        localStorage.removeItem("password");
        localStorage.removeItem("email");
        if (isLogin === "true") {
          ShowToast(response.message, toastType.SUCCESS, 5000, 200);
          localStorage.setItem(
            API_HEADERS.authToken,
            response.data.jwtToken
          );
          localStorage.setItem("K1LU", "/user");
          window.location = "/user";
        } else {
          ShowToast(
            "Password has been successfully updated",
            toastType.SUCCESS,
            5000,
            200
          );
         
          setTimeout(
            function() {
              window.location = "/login";
            }
            .bind(this),
            1000
          );
        }
      }
    });
  }
  resendOTP = (opt) => {
    const {email, password} = this.state
    var postBody = {
      email:email,
      password: password,
    };
    apiPostAsync({
      url: API_URL.UserConfiguration.ResendOTP,
      postBody: postBody,
    }).then((response) => {
      if (response.success == true) {
        ShowToast("OTP sent successfully", toastType.SUCCESS, 5000, 200);
      } else {
        ShowToast(
          "There is issue with sending OTP",
          toastType.SUCCESS,
          5000,
          200
        );
      }
    });
  };

  onChange = (e, isPrevent = true) => {
    let name = '', value = '';
    if (isPrevent) {
        e.preventDefault();
        name = e.target.name;
        value = e.target.value;
    } else {    
        name = e.name;
        value = e.value;       
    }
    let errors = this.state.errors;
    switch (name) {
        case 'OTP': 
            errors.OTP = 
            value == ''
              ? "OTP is required."
              : '';     
          break;
    }
    let onlyNumber = value.replace(/[^0-9]/g, '');
      if (onlyNumber.length < 5) {
        this.setState({errors, [name]: onlyNumber });
      } else {
        this.setState({errors});    
      }
  }
  
  render () {
    const  {OTP, errors} = this.state
    return (
      <form onSubmit={this.verifyOTP} noValidate>
        <div>
          <p style={{ lineHeight: '1.3' }} className='text-white'>
            {' '}
            Please enter the One Time Password (OTP) sent on your registered
            email address.
          </p>
          <label className='text-white'>One Time Password (OTP) </label>
          <br />
          <TextField
              type="text"
              variant="outlined"
              maxLength={4}
              value = {OTP}
              onInput={this.onChange}
              name="OTP"
              className = "input-style"
              onCopy="return false"
              onPaste="return false"
              error = {errors.OTP}
          />
          
          <FormHelperText className="text-red">
            {errors.OTP}
          </FormHelperText>
        </div>

        <div className = 'row m-2'>
          <Button
            className = "col-md-4 font-weight-bold btn-blue border-class"
            type = 'submit'
          >
            Validate OTP
          </Button>
          <Button
            className = "ml-2 col-md-4 font-weight-bold btn-blue border-class"
            onClick={this.resendOTP}
          >
            Resend OTP
          </Button>
        </div>
      </form>
    )
  }
}

export default ValidateOtp;