import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MoreIcon from '@material-ui/icons/MoreVert'
import './header.less'
import K1Logo from '../../../resources/images/K1Logo.png'
import AppBar from '@material-ui/core/AppBar'
import { parseJwt } from '../utils/generic'
import { GetUserConfiguration, IsSectionVisible } from '../utils/helper'
import { API_HEADERS } from '../../constants/apiConfiguration'
import { authContext } from '../../configurations/adalConfig'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}))

export default function PrimarySearchAppBar () {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleLogOut = () => {
    authContext.logOut()
    localStorage.removeItem[API_HEADERS.authToken]
    localStorage.removeItem(API_HEADERS.EMAIL)
    localStorage.removeItem(API_HEADERS.GROUPNAME)
    localStorage.setItem('UserName');
    window.location = '/'
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  let UserInitial = 'NA'
  let UserName = ''
  let emailId = ''
  let group = ''
  let groupName = ''

  if (localStorage.getItem('adal.idtoken')) {
    const getdata = parseJwt(localStorage.getItem('adal.idtoken'))
    let CreateUserName = ''
    if (getdata.given_name !== undefined) {
      CreateUserName =
        (getdata.given_name || '') + ' ' + (getdata.family_name || '')
    } else if (getdata.UserName !== undefined) {
      CreateUserName = getdata.UserName
    }
    UserName = CreateUserName
    if (UserName !== '' || UserName !== '') {
      const matches = UserName.match(/\b(\w)/g)
      UserInitial = (matches !== undefined && matches !== null) ? matches.join('') : ''
    } else {
      UserInitial = ''
    }

    emailId = getdata.email
    localStorage.setItem(API_HEADERS.EMAIL, emailId)
    localStorage.setItem('UserName', UserName)
  }

  const user = GetUserConfiguration()
  group = ((user.groups || [])[0] || {}).description
  groupName = ((user.groups || [])[0] || {}).groupName
  localStorage.setItem(API_HEADERS.GROUPNAME, groupName)

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{ marginTop: '50px' }}
    >
      <div className="user-initial-inner">{UserInitial}</div>
      <div className="user-details-inner">{UserName}</div>
      <div className="user-details-inner">{emailId}</div>
      <div className="user-details-inner">{group}</div>
      <MenuItem
        onClick={(handleMenuClose, handleLogOut)}
        style={{ paddingLeft: '35%', textAlign: 'center', color: '#07406d' }}
      >
        Sign Out
      </MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">

        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Sign Out</p>
      </MenuItem>
    </Menu>
  )

  return (
    <div className={classes.grow + ' user-avatar'}>
      <AppBar position="static" className={'appbar-background'}>
       <Toolbar>
          <img src={K1Logo} alt="logo"/>
          <div
            className={classes.grow}
            style={{
              fontSize: '20px',
              textAlign: 'center',
              textTransform: 'uppercase',
              fontWeight: '500',
              color: '#ffffff'
            }}
          >
            {group}
          </div>
          <div className={classes.sectionDesktop}>
            <div>
              <IconButton
                edge="end"
                aria-label="User Profile"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                size="medium"
                className="iconbutton-extend"
              >
                <span className="user-initial">{UserInitial}</span>
              </IconButton>
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      {renderMobileMenu}
      {renderMenu}
      </AppBar>
    </div>
  )
}
