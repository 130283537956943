export default {
  UserCells: [
    { key: 'InvestorName', name: 'Investor' },
    { key: 'Name', name: 'User Name' },
    { key: 'Relation', name: 'Relation' },
    { key: 'Email', name: 'User Email' },
    { key: 'CreatedDate', name: 'Created On' },
    { key: 'StartTaxYear', name: 'Start Tax Year' },
    { key: 'EndTaxYear', name: 'End Tax Year' },
    { key: 'TaxAccessTypeName', name: 'Access Type' },
    { key: 'LastLogin', name: 'Last Login' },
    { key: 'Status', name: 'Status' },
    { key: 'IsActive', name: 'Active' },
    { key: 'Action', name: 'Actions' }
  ],
  PendingUserCells: [
    { key: 'Investor', name: 'Investor' },
    { key: 'Name', name: 'User Name' },
    { key: 'Email', name: 'User Email' },
    { key: 'ModifiedDate', name: 'Requested On' },
    { key: 'TaxYear', name: 'Applicable Tax Year' },
    { key: 'RequestType', name: 'Request Type' },
    { key: 'Action', name: 'Actions' }
  ],
  ActionList: [
    { key: 'Invite', name: 'Invite' },
    { key: 'Uninvite', name: 'Uninvite' }
  ],
  AccessType: 'Access Type',
  Investor: 'Investor',
  CreatedOn: 'Created On',
  LastLogin: 'Last Login',
  header: 'Users',
  pendingHeader: 'Pending Requests',
  Save: 'Save',
  Update: 'Update',
  Cancel: 'Cancel',
  AddUser: 'Add User',
  UpdateUser: 'Update User',
  FirstName: 'First Name',
  LastName: 'Last Name',
  EmailAddress: 'Email Address',
  StartTaxYear: 'Applicable Start Tax Year',
  EndTaxYear: 'Applicable End Tax Year',
  Role: 'Role',
  Relation: 'Relation',
  User: 'User Name',
  UserEmail: 'User Email',
  PopupHeader: 'Confirm',
  AdditionalComment: 'Additional Comments',
  Approve: 'Approve',
  Reject: 'Reject',
  SendApprovalConfirmationToInvestor: 'Send Approval confirmation to Investor?',
  Clear: 'Clear',
  SendInvite: 'Send Invite',
  SubmitRequest: 'Submit Request',
  Action: 'Action',
  AccessTypeError: 'Access type is required.',
  FirstNameError: 'First name is required.',
  LastNameError: 'Last name is required.',
  EmailError: 'Email address is required.',
  ValidEmailAddress: 'Please enter valid email address.',
  RoleError: 'Role is required.',
  RelationError: 'Relation is required.',
  ActionError: 'Action is required.',
  ApplicableStartTaxYearError: 'Applicable Start Tax Year is required.',
  ApplicableEndTaxYearError: 'Applicable End Tax Year is required.',
  InvestorError: 'Investor is required.',
  StartTaxYearError: 'Start Tax Year is required.',
  EndTaxYearError: 'End Tax Year should be greater than or equal to the Start Tax Year.',
  EmailIdAlreadyTaken: 'Email Id already present in the system.',
  Confirmation: 'Are you sure you want to make ',
  DeactivateSuccess: ' is deactivated successfully.',
  ActivateSuccess: ' is activated successfully.',
  DeleteUserMessage: 'Are you sure you want to delete the user ',
  UserDeleteSuccess: ' is removed successfully from portal.',
  AdditionalCommentError: 'Additional comments is required.',
  ApproveSuccess: 'Request is approved successfully.',
  RejectSuccess: 'Request is rejected successfully.',
  UserExistWithDifferentInvestor: 'User exists with this email for another investor. Please confirm to continue with the same user.',
  UndoUser: 'This email is already in use with a deleted/rejected User. Please confirm to proceed ahead to re-enable that user?',
  UserEnable: 'User is enabled successfully.',
  UserMapping: 'User invited successfully.',
  PendUserStateMessage: 'User with same email already exists and is pending for your approvals.',
  RequestOn: 'Requested On'
}
