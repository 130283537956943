import React, { Component } from 'react';
import Table from '../../common/component/table/table'
import {InvestorConstant, Status, UserConstant} from '../../common/constants/index';
import { apiGet } from "../../common/component/utils/api-service";
import { API_URL } from '../../common/constants/apiConfiguration';
import InviteUser from './invite-user/invite-user'
import { connect } from 'react-redux'


class InviteHistoryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          OriginalInvitation: [],
          FilteredInvitation: [],
          statusList: [],
          clientId: this.props.showAll.userData.userInfo.clientId,
          requestTypeList: []
        }
    }
    componentDidMount() {
      this.getInvitationHistory();
      // this.getMaster();
    }
    getMaster() {
      const params = {
        isStatus: true
      }
      apiGet({ url: API_URL.masterData , params}).then((data) => {
      if(data && (data.status.success)) {
        const status = data.status.data.filter((item) => {
          if (item.Name !== Status.Completed && item.Name !== Status.Unmatched)
           return item;
       });

        this.setState({ statusList: statusList});
      }
      });
   }
    getInvitationHistory = () => {
      const {clientId} = this.state
      const params = {
        clientId: clientId
      }
      apiGet({ url: API_URL.GetInvitationHistoryList,params }).then((data) => {
        if(data.success) {
          var inviteData =  data.data;
          for (var i in  inviteData) {
            var actionDate  = new Date(inviteData[i].InvitedOn);
            inviteData[i].InvitedOn = actionDate.toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false})
                                                  + ' ' + inviteData[i].TimeZone;
                    }
          // var filteredArray = inviteData.filter(function(item){
          //     if (item.Status == Status.Pending)
          //     return item;
          // });
          this.setState({ OriginalInvitation: data.data, FilteredInvitation: inviteData });
          this.setState({ requestTypeList: UserConstant.ActionList});
        }
      });
    }

    FilterList = (param) => {
      const { OriginalInvitation } = this.state;
      var filteredList = OriginalInvitation;
       if (filteredList.length > 0 && param.userName != "" && param.userName.length >= 0) {
         filteredList = filteredList.filter((item) => {
           if (item.Name != null && item.Name.trim().toLowerCase().includes(param.userName.trim().toLowerCase()))
            return item;
        });
       }
       if (filteredList.length > 0 && param.investorName != "" && param.investorName.length >= 0) {
          filteredList = filteredList.filter((item) => {
            if (item.Email != null && item.Email.trim().toLowerCase().includes(param.investorName.trim().toLowerCase()))
             return item;
         });
        }
        if (filteredList.length > 0 && param.requestTypeValue !== '') {
          filteredList = filteredList.filter((item) => {
            if (item.RequestType != null && item.RequestType.trim().toLowerCase() == param.requestTypeValue.trim().toLowerCase())
               return item;
          });
        }
        if (filteredList.length > 0 && param.showRevised) {
          filteredList = filteredList.filter((item) => {
            if (item.Status != null && item.Status != undefined && item.Status.trim() !== Status.Removed && item.Status.trim() !== Status.Rejected){
              return item;
            }
          });
         }
       this.setState({ FilteredInvitation: filteredList });
    }
    render() {
        const {FilteredInvitation, requestTypeList, clientId} = this.state
        return(
            <>
             <InviteUser clientId = {clientId} getInvitationHistory= {this.getInvitationHistory} getPendingUser = {this.props.getPendingUser}/>
              <Table 
                requestTypeList= {requestTypeList}
                header = {InvestorConstant.InvitationHeader} 
                columns={InvestorConstant.InvitationCells} 
                data={FilteredInvitation}
                filterList = {this.FilterList}
              />
            </>
        ); 
    }
}


const mapStateToProps = (state) => {
  
  return {
    showAll: state.configuredUser
  }
}

export default
connect(
  mapStateToProps
)(InviteHistoryList)
