import {
  isFirefox,
  isEdge,
  isChrome,
  isSafari,
  isMobile
} from 'react-device-detect'
import { runWithAdal } from 'react-adal'
import { authContext } from './common/configurations/adalConfig'
import { API_HEADERS } from './common/constants/apiConfiguration'

const getIsAuthenticated = () => {
  if (
    localStorage.getItem('adal.idtoken') == '' ||
    localStorage.getItem('adal.idtoken') == null ||
    localStorage.getItem('adal.idtoken') == undefined ||
    localStorage.getItem('adal.idtoken') == 'undefined'
  ) {
    localStorage.removeItem('adal.idtoken')
    localStorage.removeItem('userConfiguration')
    localStorage.removeItem(API_HEADERS.EMAIL)
    return false
  } else {
    return true
  }
}

let DO_NOT_LOGIN = true
getIsAuthenticated()
if (window.location.pathname == '/k1-user' && getIsAuthenticated() == true) {
  window.location = '/'
} else if (window.location.pathname == '/k1-user') {
  DO_NOT_LOGIN = false
}
if (window.location.pathname == '/user' && getIsAuthenticated() == true) {
  window.location = '/'
} else if (window.location.pathname == '/user') {
  DO_NOT_LOGIN = true
}

const isSupportToBrowser = (!isMobile && (isChrome || isEdge || isFirefox || isSafari)) || false
const isSupportMobileBrowser = (isMobile && (isChrome || isSafari)) || false

if (!isSupportToBrowser && !isSupportMobileBrowser)
// require("./views/browserNotSupport/browserNotSupport.js");
{ console.log('Browser not supported') } else {
  runWithAdal(
    authContext,
    () => {
      document.documentElement.setAttribute('data-theme', 'Default')
      require('./indexApp.js')
    },
    DO_NOT_LOGIN
  )
}
