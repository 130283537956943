import React, { Component } from 'react';
import Table from '../../common/component/table/table'
import {ScheduledK1Constant} from '../../common/constants/index';
import { apiGet} from "../../common/component/utils/api-service";
import { API_URL } from '../../common/constants/apiConfiguration';
import { connect } from 'react-redux'
import moment from 'moment';
export class UserUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FilteredUserUsageData : []
        }
    }

    componentDidMount() {
    const params = {
        fromDate: new moment()
                     .startOf('month')
                    .format('MM/DD/YYYY'),
        toDate: new moment()
                    .endOf('month')
                    .format('MM/DD/YYYY'),
        }
      this.GetUserUsage(params);
    }

    
    GetUserUsage = (params) => {
      apiGet({ url: API_URL.GetUserActivity, params }).then((data) => {
        if(data.success) {
          var userActivityData =  data.data;
          for (var i in  userActivityData) {
            var activityDate  = new moment(userActivityData[i].MONTHYEAR).format('YYYY/MM/DD') ;
            userActivityData[i].MONTHYEAR = activityDate  + ' ' + userActivityData[i].TimeZone;
          }
          this.setState({ FilteredUserUsageData: data.data });
        }
      });
    }
    FilterList = (param) => {
      const params = {
        fromDate:  moment(param.startDate).format('YYYY/MM/DD') ,
        toDate:  moment(param.endDate).format('YYYY/MM/DD') 
      }
      this.GetUserUsage(params)
    }

    render() {

        const { FilteredUserUsageData} = this.state;
        return(
            <>
              <Table 
                header = {''}
                investorList = {[]}
                statusList= {[]}
                columns={ScheduledK1Constant.UserUsageCell} 
                data={FilteredUserUsageData}
                filterList = {this.FilterList}
              />
            </>
        ); 
    }
}


