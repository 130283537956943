
import React from 'react'
import {
  Checkbox,
  withStyles
} from '@material-ui/core/'

export const BlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#064473'
    },
    '&$disabled': {
      color: '#eaeef1',
      backgroundColor: '#eaeef1',
      cursor: 'not-allowed'
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />)
