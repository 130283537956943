import React, { useState } from 'react'
import {
  makeStyles,
  Select,
  MenuItem,
  Button,
  TextField,
  IconButton,
  Grid
} from '@material-ui/core'
import './searchFilter.less'
import DatePickerRange from '../datepicker-range/datepickerRange'
import { getBackYearFromDate } from '../utils/date'
import { UploadFileConstant, InvestorConstant, UserConstant, Status } from '../../constants/index'
import Autocomplete from '../autocomplete/autocomplete'
import { BlueCheckbox } from '../check-box/checkBox'
import store from '../../../store/appStore'
import * as searchFilterActionType from '../../../store/search-filter/actionTypes'
import { IsSectionVisible } from '../utils/helper'
import { SECTIONS } from '../../constants/appConstants'
import Switch from '../switch-toggle/switch'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import ClearIcon from '@material-ui/icons/Clear'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  }
}))

export default function SearchFilter (props) {
  const { header, statusList, documentTypeList, requestTypeList } = props
  const classes = useStyles()
  const [startDate, setStartDate] = useState(UploadFileConstant.UploadHistory === header
    ? ''
    : new moment()
      .startOf('month')
      .format('MM/DD/YYYY'))
  const [endDate, setEndDate] = useState(UploadFileConstant.UploadHistory === header
    ? ''
    : new moment()
      .endOf('month')
      .format('MM/DD/YYYY'))
  const [fiscalYear, setFiscalYear] = useState('')
  const [investorName, setInvestorName] = useState('')
  const [investorValue, setInvestorValue] = useState([])
  const [active, setActive] = useState('')
  const [status, setStatus] = useState('')
  const [masterFileName, setMasterFileName] = useState('')
  const [userName, setUserName] = useState('')
  const [k1referenceName, SetK1referenceName] = useState('')
  const [showRevised, SetRevised] = useState(false)
  const [documentTypeValue, setDocumentType] = useState('')
  const [requestTypeValue, setRequestType] = useState('')
  const [email, SetEmail] = useState('')

  const onRequestType = (e) => {
    setRequestType(e.target.value)
  }
  const onDocumentType = (e) => {
    setDocumentType(e.target.value)
  }
  const onSwitchClick = (e) => {
    SetRevised(e.target.checked)
  }
  const SetK1referenceNameValue = (e) => {
    SetK1referenceName(e.target.value)
  }

  const SetEmailValue = (e) => {
    SetEmail(e.target.value)
  }
  const SetUserNameValue = (e) => {
    setUserName(e.target.value)
  }
  const SetMasterFileName = (e) => {
    setMasterFileName(e.target.value)
  }
  const onStatus = (e) => {
    setStatus(e.target.value)
  }
  const onActiveValue = (e) => {
    setActive(e.target.value)
  }
  const onFiscalYear = (e) => {
    setFiscalYear(e.target.value)
  }
  const SetInvestorNameValue = (e) => {
    setInvestorName(e.target.value)
  }
  const onInvestorSelect = (name, value) => {
    // console.log(name, value)
    setInvestorName(name)
    setInvestorValue(value)
  }
  const SearchRecord = (props) => {
    const inputParam = {
      startDate, endDate, investorName, fiscalYear, active, status, masterFileName, userName, k1referenceName, showRevised, documentTypeValue, requestTypeValue, email
    }
    props.filterList(inputParam)
  }
  const onDateRange = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }
  const ClearFilter = () => {
    setFiscalYear('')
    setInvestorName([])
    setStartDate(UploadFileConstant.UploadHistory === header
      ? ''
      : new moment()
        .startOf('month')
        .format('MM/DD/YYYY'))
    setEndDate(UploadFileConstant.UploadHistory === header
      ? ''
      : new moment()
        .endOf('month')
        .format('MM/DD/YYYY'))
    setInvestorValue([])
    setActive('')
    // setStatus(InvestorConstant.InvitationHeader === header ? Status.Pending : '')
    setStatus('')
    setMasterFileName('')
    setUserName('')
    SetK1referenceName('')
    SetRevised(false)
    setDocumentType('')
    setRequestType('')
    SetEmail('')
    const inputParam = {
      startDate: UploadFileConstant.UploadHistory === header
        ? ''
        : new moment()
          .startOf('month')
          .format('MM/DD/YYYY'),
      endDate: UploadFileConstant.UploadHistory === header
        ? ''
        : new moment()
          .endOf('month')
          .format('MM/DD/YYYY'),
      investorName: '',
      fiscalYear: '',
      active: '',
      status: '',
      masterFileName: '',
      userName: '',
      k1referenceName: '',
      showRevised: false,
      documentTypeValue: '',
      requestTypeValue: '',
      email: ''
    }
    props.filterList(inputParam)
  }
  return (
    <div className={classes.container}>
    {
      header === ''
        ? <>
          <div className="col-sm-4 col-xs-12 mb-2">
            <DatePickerRange
                onDateChange={onDateRange}
                startDate={startDate}
                endDate={endDate}
            />
          </div>
        </>
        : InvestorConstant.header === header
          ? <>
      <div className="col-sm-4 col-xs-12 mb-2">
        <TextField
            variant="outlined"
            className = 'w-100'
            name = 'investorName'
            value = {investorName}
            placeholder= {InvestorConstant.InvestorName}
            InputProps={{
              className: 'text-blue'
            }}
            onChange = {SetInvestorNameValue}
        />
      </div>
      <div className="col-sm-4 col-xs-12 mb-2">
        <TextField
            variant="outlined"
            className = 'w-100'
            name = 'k1referenceName'
            value = {k1referenceName}
            placeholder= {InvestorConstant.K1referenceName}
            InputProps={{
              className: 'text-blue'
            }}
            onChange = {SetK1referenceNameValue}
        />
      </div>
      <div className="col-sm-4 col-xs-12 mb-2">
        <TextField
            variant="outlined"
            className = 'w-100'
            name = 'email'
            value = {email}
            placeholder= {InvestorConstant.Email}
            InputProps={{
              className: 'text-blue'
            }}
            onChange = {SetEmailValue}
        />
      </div>
      <div className="col-sm-4 col-xs-12 mb-2">
      <Select
          displayEmpty
          className="w-100 text-blue"
          name = 'type'
          value = {active}
          onChange = {onActiveValue}
      >
          <MenuItem className="text-blue" value=""> {InvestorConstant.Status }</MenuItem>
          {
              InvestorConstant.ActiveDropDown.map(active => {
                return (
                      <MenuItem className="text-blue" key = {active.Id} value={active.Id}> {active.Value}</MenuItem>
                )
              })
          }
      </Select>
      </div>
      <div className="col-sm-4 col-xs-12 mb-2">
      <Select
          displayEmpty
          className="w-100 text-blue"
          name = 'investorType'
          value = {status}
          onChange = {onStatus}
      >
          <MenuItem className="text-blue" value=""> {InvestorConstant.InvestorType }</MenuItem>
          {
              documentTypeList.map(investor => {
                return (
                      <MenuItem className="text-blue" key = {investor.Name} value={investor.Name}> {investor.Name}</MenuItem>
                )
              })
          }
      </Select>
      </div>
    </>
          : UploadFileConstant.UploadHistory === header
            ? <>
      <div className="col-sm-4 col-xs-12 mb-2">
       <DatePickerRange
           onDateChange={onDateRange}
           startDate={startDate}
           endDate={endDate}
       />
     </div>
     <div className="col-sm-4 col-xs-12  mb-2">
           <Select
               displayEmpty
               name = 'documentTypeValue'
               className = "w-100 text-blue"
               value = {documentTypeValue}
               onChange = {onDocumentType}
           >
              <MenuItem className="text-blue" value="">
                 {UploadFileConstant.SelectType}
               </MenuItem>
               {
                  documentTypeList.map(documentType => {
                    return (
                           <MenuItem className="text-blue" key = {documentType.Name} value={documentType.Name}> {documentType.Name}</MenuItem>
                    )
                  })
               }
           </Select>
       </div>
       <div className="col-sm-4 col-xs-12  mb-2">
           <Select
               displayEmpty
               name = 'fiscalYear'
               className = "w-100 text-blue"
               value = {fiscalYear}
               onChange = {onFiscalYear}
           >
              <MenuItem className="text-blue" value="">
                 {UploadFileConstant.SelectTaxYear}
               </MenuItem>
               {
                   getBackYearFromDate(5).map(year => {
                     return (
                           <MenuItem className="text-blue" key = {year} value={year}> {year}</MenuItem>
                     )
                   })
               }
           </Select>
       </div> <div className="col-sm-6 col-xs-12 mb-2">
         <Autocomplete value = {investorValue} tagLimits = {1} placeholder={UploadFileConstant.SelectInvestor} options = {props.investorList} selectedValue = {onInvestorSelect}/>
       </div></>
            : UserConstant.header === header
              ? <>
        <div className="col-sm-3 col-xs-12 mb-2">
          <TextField
              variant="outlined"
              className = 'w-100'
              name = 'userName'
              value = {userName}
              placeholder= {UserConstant.User}
              InputProps={{
                className: 'text-blue'
              }}
              onChange = {SetUserNameValue}
          />
        </div>
        <div className="col-sm-4 col-xs-12 mb-2">
        <Autocomplete value = {investorValue} tagLimits = {1} placeholder={UploadFileConstant.SelectInvestor} options = {props.investorList} selectedValue = {onInvestorSelect}/>
      </div>
      <div className="col-sm-3 col-xs-12  mb-2">
           <Select
               displayEmpty
               name = 'status'
               className = "w-100 text-blue"
               value = {status}
               onChange = {onStatus}
           >
              <MenuItem className="text-blue" value="">
                 {UploadFileConstant.SelectStatus}
               </MenuItem>
               {
                   statusList.map(status => {
                     return (
                           <MenuItem className="text-blue" key = {status.Name} value={status.Name}> {status.Name}</MenuItem>
                     )
                   })
               }
           </Select>
       </div></>
              : InvestorConstant.InvitationHeader === header
                ? <>
          <div className="col-sm-3 col-xs-12 mb-2">
            <TextField
                variant="outlined"
                className = 'w-100'
                name = 'userName'
                value = {userName}
                placeholder= {UserConstant.User}
                InputProps={{
                  className: 'text-blue'
                }}
                onChange = {SetUserNameValue}
            />
          </div>
          <div className="col-sm-3 col-xs-12 mb-2">
          <TextField
              variant="outlined"
              className = 'w-100'
              name = 'investorName'
              value = {investorName}
              placeholder= {InvestorConstant.EmailAddress}
              InputProps={{
                className: 'text-blue'
              }}
              onChange = {SetInvestorNameValue}
          />
        </div>
        <div className="col-sm-2 col-xs-12  mb-2">
            <Select
                displayEmpty
                name = 'requestTypeValue'
                className = "w-100 text-blue"
                value = {requestTypeValue}
                onChange = {onRequestType}
            >
                <MenuItem className="text-blue" value="">
                  {UploadFileConstant.SelectRequestType}
                </MenuItem>
                {
                    requestTypeList.map(requestType => {
                      return (
                            <MenuItem className="text-blue" key = {requestType.name} value={requestType.name}> {requestType.name}</MenuItem>
                      )
                    })
                }
            </Select>
        </div>
        <div className="col-sm-2 col-xs-12  mb-2">
           <span className = 'test-size'>Show Active User</span>
          <BlueCheckbox name="showRevised" checked ={showRevised} onChange= {onSwitchClick}/>
        </div></>
                : header === undefined
                  ? (IsSectionVisible(SECTIONS.SCHEDULED_K1, SECTIONS.APPROVE_K1)
                      ? <>
     <div className="col-sm-3 col-xs-12 mb-2">
      <TextField
          variant="outlined"
          className = 'w-100'
          name = 'masterFileName'
          value = {masterFileName}
          placeholder= {InvestorConstant.MasterFileName}
          InputProps={{
            className: 'text-blue'
          }}
          onChange = {SetMasterFileName}
      />
     </div>
       <div className="col-sm-3 col-xs-12  mb-2">
           <Select
               displayEmpty
               name = 'fiscalYear'
               className = "w-100 text-blue"
               value = {fiscalYear}
               onChange = {onFiscalYear}
           >
              <MenuItem className="text-blue" value="">
                 {UploadFileConstant.SelectTaxYear}
               </MenuItem>
               {
                   getBackYearFromDate(5).map(year => {
                     return (
                           <MenuItem className="text-blue" key = {year} value={year}> {year}</MenuItem>
                     )
                   })
               }
           </Select>
       </div>
       <div className="col-sm-3 col-xs-12  mb-2">
           <Select
               displayEmpty
               name = 'status'
               className = "w-100 text-blue"
               value = {status}
               onChange = {onStatus}
           >
              <MenuItem className="text-blue" value="">
                 {UploadFileConstant.SelectStatus}
               </MenuItem>
               {
                   statusList.map(status => {
                     return (
                           <MenuItem className="text-blue" key = {status.Name} value={status.Name}> {status.Name}</MenuItem>
                     )
                   })
               }
           </Select>
       </div>
       <div className="col-sm-6 col-xs-12 mb-2">
         <Autocomplete value = {investorValue} tagLimits = {1} placeholder={UploadFileConstant.SelectInvestor} options = {props.investorList} selectedValue = {onInvestorSelect}/>
       </div>
       <div className="col-sm-3 col-xs-12  mb-2">
         <span className = 'test-size'>Is Revised</span>
         <div className = 'switch-align'>
         <Switch
            isChecked={showRevised}
            handleChange={onSwitchClick}
          />

         </div>
       </div>
      </>
                      : <>
       <div className="col-sm-3 col-xs-12  mb-2">
           <Select
               displayEmpty
               name = 'fiscalYear'
               className = "w-100 text-blue"
               value = {fiscalYear}
               onChange = {onFiscalYear}
           >
              <MenuItem className="text-blue" value="">
                 {UploadFileConstant.SelectTaxYear}
               </MenuItem>
               {
                   getBackYearFromDate(5).map(year => {
                     return (
                           <MenuItem className="text-blue" key = {year} value={year}> {year}</MenuItem>
                     )
                   })
               }
           </Select>
       </div>
       <div className="col-sm-6 col-xs-12 mb-2">
         <Autocomplete value = {investorValue} tagLimits = {1} placeholder={'Select K-1 Recipient'} options = {props.investorList} selectedValue = {onInvestorSelect}/>
       </div>
              </>)
                  : ''}
      { header !== UserConstant.pendingHeader
        ? <div className="text-right">
      <Button variant="contained" className="btn btn-sm btn-blue w-40" onClick={() => { SearchRecord(props) }}>{UploadFileConstant.Search}</Button>
      <Button variant="contained" className="btn btn-sm m-1 btn-blue w-40" onClick={() => { ClearFilter() }}>{UploadFileConstant.Clear}</Button>
      </div>
        : '' }
    </div>
  )
}
