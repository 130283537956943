import React, { Component } from 'react'
import { LoginModel } from '../../../common/constants/index'
import { 
  TextField,
  Link,
  Select,
  MenuItem,
  FormHelperText,
  Button
} from '@material-ui/core/'
import { API_URL } from '../../../common/constants/apiConfiguration';
import { ShowToast, toastType } from "../../../common/component/utils/toast-service";
import { apiPostAsync , apiGet} from "../../../common/component/utils/api-service";
import { GetUserConfiguration } from '../../../common/component/utils/helper'
import Autocomplete from '../../../common/component/autocomplete/autocomplete'


class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
        email: localStorage.getItem('Email'),
        name: localStorage.getItem('UserName'),
        investorName: [],
        questionOrFeedback: null,
        investorList: [],
        group: '',
        user: GetUserConfiguration(),
        investorValue: [],
        errors: {
          name: '',
          email: '',
          questionOrFeedback: ''
       },
    };
  }
  
  componentDidMount() {
    this.getInvestorMaster();
    const user = this.state.user;
    this.setState ({
      group: ((user.groups || [])[0] || {}).description
    })
  }
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  onChangeOfField = (e, isPrevent = true) => {
    let name = '', value = '';
    if (isPrevent) {
        e.preventDefault();
        name = e.target.name;
        value = e.target.value;
    } else {    
        name = e.name;
        value = e.value;       
    }
    let errors = this.state.errors;
    switch (name) {
        case 'email': 
            errors.email = 
            value == ''
              ? LoginModel.EmailError
              : value != '' && this.validateEmail(value) ? '' : LoginModel.ValidEmailAddress;     
          break;
        case 'name': 
          errors.name = 
            value == ''
              ? LoginModel.NameError
              : '';
          break;
        case 'questionOrFeedback': 
            errors.questionOrFeedback = 
                value == ''
                    ? LoginModel.QuestionOrFeedbackError
                    : '';
          break;        
        default:
          break;
      }
      this.setState({errors, [name]: value});
}

  getInvestorMaster = () =>  {
    const params = {
      isInvestors: true,
    }
    apiGet({ url: API_URL.masterData, params }).then((data) => {
      if(data && data.client.success) {
        this.setState({ investorList: data.client.data });
      }
    });
  }
  
  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
}
  submitRequest = (e) => {
    e.preventDefault();
    this.onChangeOfField(e.target.elements.email, false);
    this.onChangeOfField(e.target.elements.name, false);
    this.onChangeOfField(e.target.elements.questionOrFeedback, false);
    
    const {email, name, investorName, questionOrFeedback, investorList, group, user} = this.state;
    let investorId = '';
    let investorEmail = '';
    for(let i=0; i< investorName.length; i++) {
      investorList.filter((item)=>{ 
           if (item.maskedName == investorName[i]) {
              investorId = investorId != '' ? investorId + '|' + item.clientId : item.clientId;
              investorEmail = investorEmail != '' ? investorEmail + ',' + item.email : item.email
           }
      });
     }
     if (group == 'Investor') {
      investorId = user.userInfo.clientId.toString();
      investorList.filter((item)=>{ 
        if (item.clientId == parseInt(investorId)) {
          investorName.push(item.name)
          investorEmail = investorEmail != '' ? investorEmail + ',' + item.email : item.email
        }
      });
     } else {
      investorId = investorId.toString();
     }
    
    var postBody = {
      email: email,
      name: name,
      investorName: investorName != '' && investorName != null && investorName != undefined ? investorName.join(' | ') : '',
      question: questionOrFeedback,
      clientId: investorId,
      investorEmail: investorEmail,
      type: group
    };
  //  console.log(postBody)
    if(!this.validateForm(this.state.errors)) {
      return
     } 
    apiPostAsync({
      url: API_URL.UserConfiguration.ContactUs,
      postBody: postBody,
    }).then((response) => {
      if (response.success == false) {
        ShowToast(response.message, toastType.ERROR, 5000, 200);
      } else if (response.success == true) {
        ShowToast(response.message, toastType.SUCCESS, 5000, 200);
        this.resetFields();
      }
    });
  };

  resetFields = () => {
    this.setState({investorName: [], questionOrFeedback: '',investorValue : [], errors: {
      name: '',
      email: '',
      questionOrFeedback: ''
   }});
 } 

 SelectInvestor = (investorName, values) => {
  this.setState({investorName : investorName, investorValue: values})
}
  
  render() {

    const {email, name, investorName, questionOrFeedback, investorList, group, investorValue, errors} = this.state;
    return (
      <form onSubmit={this.submitRequest} noValidate>

      <div className="m-4 border shadow-sm border-theme-color background-theme-color rounded">

          <div className="m-2 mt-4 row">
            <label className = "col-xs-12 col-sm-3 text-blue font-weight-bold"> {LoginModel.userEmail} <span className="text-red">*</span></label>
            <div className="col-xs-12 col-sm-6">
              <TextField 
                      placeholder={LoginModel.userEmail}
                      type="text"
                      variant="outlined"
                      maxLength={50}
                      value={email}
                      onChange={this.onChangeOfField}
                      name="email"
                      className = "input-style text-blue w-100"
                      InputProps={{
                        readOnly: true, 
                        style: { backgroundColor: "#eaeef1"  },  
                        className: "text-blue"               
                    }}
                    error = {errors.email}
                  />
                 <FormHelperText className="text-red">
                    {errors.email}
                  </FormHelperText>
            </div>
        </div>
      <div className="m-2 row">
            <label className = "col-xs-12 col-sm-3 text-blue font-weight-bold">{LoginModel.name} <span className="text-red">*</span></label>
            <div className="col-xs-12 col-sm-6">
              <TextField
                    placeholder={LoginModel.name}
                    variant="outlined"
                    type="text"
                    maxLength={50}
                    value={name}
                    onChange={this.onChangeOfField}
                    name="name"
                    className = "col-xs-12 col-sm-12 input-style text-blue w-100"
                    InputProps={{
                      readOnly: true, 
                      style: { backgroundColor: "#eaeef1"  },  
                      className: "text-blue"               
                  }}
                  error = {errors.name}
                  />
                 <FormHelperText className="text-red">
                    {errors.name}
                  </FormHelperText>
            </div>           
      </div>
      {group !== 'Investor'  ?
      <div className="row m-2">
            <label className = "col-xs-12 col-sm-3 text-blue font-weight-bold">{LoginModel.investorName}</label>
            <div className="col-xs-12 col-sm-6">
              <Autocomplete
                value = {investorValue} 
                className = 'col-xs-12 col-sm-6'
                tagLimits = {1} 
                placeholder={LoginModel.investorName} 
                options = {investorList} 
                selectedValue = {this.SelectInvestor}/>                
            </div>           
      </div> : '' }
      <div className="row m-2">
            <label className = "col-xs-12 col-sm-3 text-blue font-weight-bold">{LoginModel.questionOrFeedback} <span className="text-red">*</span></label>
            <div className="col-xs-12 col-sm-6">
              <TextField
                    placeholder={LoginModel.questionOrFeedback}
                    variant="outlined"
                    type="text"
                    maxLength={200}
                    value={questionOrFeedback}
                    onChange={this.onChangeOfField}
                    name="questionOrFeedback"
                    className = "col-xs-12 col-sm-12 input-style text-blue w-100"
                    InputProps={{
                      className: "text-blue"               
                  }}
                   error = {errors.questionOrFeedback}
                />
               <FormHelperText className="text-red">
                  {errors.questionOrFeedback}
                </FormHelperText>
            </div>           
      </div>
        
      <div className="row m-2 pb-1">
            <div className="col-xs-12 col-sm-3"></div>
            <div className="col-xs-12 col-sm-9">
            <Button 
              className="btn btn-blue"  type="submit">
             {LoginModel.submitRequest}
            </Button>
            </div>
      </div>
    
    </div>
    </form>
    );
  }
}

export default ContactUs;
