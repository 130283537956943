import * as actionType from './actionTypes'

const initialState = {
  requestCount: 0
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_VISIBILITY: {
      let count = state.requestCount
      if (action.payload === true) {
        count++
      } else {
        count--
      }
      return {
        ...state,
        requestCount: count
      }
    }
    default:
      return state
  }
}

export default reducer
