import React, { Component } from 'react'
import './linkTab.less';
import SignIn from '../../../container/login/sign-in/signIn'
import ContactUs from '../../../container/login/contact-us/contactUs'
class LinkTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginTab: [{ label: 'Login', id: 1, children: <SignIn/> }],
            selectedTab: 1,
            children: <SignIn/>
        };
    }

    setTabSelection = (key,children) => {
        this.setState({ selectedTab: key , children: children });
    }

    render() {
        const { selectedTab,children,loginTab } = this.state;
        return (
            <div className= "link-tab mt-3">
                {
                    loginTab.map(btn => {
                        return (
                            <span
                                className={"pr-3 font-weight-bold" + (btn.id == selectedTab ? " selected-span" : " ")}
                                key={`btn_${btn.label}`}
                                onClick={() => this.setTabSelection(btn.id, btn.children)}
                            >
                                {btn.label}
                            </span>
                        )
                    })
                }
                 <div>{children}</div>
            </div>
            );
    }
}

export default LinkTab;