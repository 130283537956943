import React from "react";
import IdleTimer from "react-idle-timer";
import { authContext } from "../../configurations/adalConfig";
import { IDLE_TIME } from "../../constants/appConstants";

export default function IdleSessionTimer() {

	function onIdle() {
		authContext.logOut();
	}

	return (
		<IdleTimer
			// ref={ref => {
			// 	this.idleTimer = ref;
			// }}
			element={document}
			// onActive={onActive}
			onIdle={onIdle}
			// onAction={onAction}
			debounce={250}
			timeout={IDLE_TIME} // After 59 minutes it auto logg off
		/>
	);
}
