import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const CustomAutocomplete = withStyles({
  tag: {
    backgroundColor: '#d6e7f3f2',
    position: 'relative',
    zIndex: 0,
    color: '#064473',
    '& .MuiChip-label': {
      color: '#064473'
    },
    '& .MuiChip-deleteIcon': {
      color: '#064473'
    },
    '&:after': {
      content: '""',
      right: 10,
      top: 6,
      width: 12,
      position: 'absolute',
      backgroundColor: '#d6e7f3f2',
      zIndex: -1
    }
  }
})(Autocomplete)
const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(3)
    }
  }
}))

export default function LimitTags (props) {
  const classes = useStyles()

  const handleClick = (data) => {
    if (data.length > 0) {
      let newList = []
      if (data[value.length] !== undefined) {
        const maskedName = data[value.length].maskedName
        newList = value.length > 0 && value.some(client => client.maskedName === maskedName) ? '' : value.concat({ maskedName })
      } else {
        for (let i = 0; i < data.length; i++) {
          if (value.some(client => client.maskedName === data[i].maskedName)) {
            newList.push(data[i])
          }
        }
      }
      newList !== '' ? props.selectedValue(newList.map(a => a.maskedName), newList) : ''
    } else {
      props.selectedValue([], [])
    }
  }
  const { placeholder, options, tagLimits, value, error } = props
  return (
    <div className={classes.root}>
      <CustomAutocomplete
        error = {error}
        value = {value}
        onChange={(event, value) => handleClick(value) }
        className="w-100"
        multiple
        limitTags={tagLimits}
        options={options}
        getOptionLabel={(option) => option.maskedName}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder= {placeholder} />
        )}
      />
    </div>
  )
}
