import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core'

export default function ModalPopup ({ IsOpen, Header, Body, OnHandelClick, Close = 'Close' }) {
  return (
    <Dialog
        disableBackdropClick
        open={IsOpen}
        onClose={() => OnHandelClick(false)}
        aria-labelledby="customized-dialog-title"
    >
       <DialogTitle id="customized-dialog-title" className="text-blue" onClose={() => OnHandelClick(false)}>
         {Header}
        </DialogTitle>
        <DialogContent dividers>
            <DialogContentText className="text-blue">
                {Body}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => { OnHandelClick(false) }} className="btn btn-sm btn-blue">
                {Close}
            </Button>
        </DialogActions>
   </Dialog>
  )
}
