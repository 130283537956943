import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {  
  TextField
} from "@material-ui/core";
import 'bootstrap-daterangepicker/daterangepicker.css';
import {convertDateToFormat} from '../utils/date';
 
export default class DatePickerRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CustStartDate:  '',
      CustEndDate: '',
      DateSrting: '',
    };
  }
  componentWillReceiveProps(newprops)
  {
    if(newprops.startDate!=this.state.CustStartDate || newprops.endDate !=this.state.CustEndDate)
    {
      this.setState({
        CustStartDate: newprops.startDate,
        CustEndDate: newprops.endDate,
        DateString:  newprops.startDate != '' &&  newprops.endDate != '' ?
                     newprops.startDate + " - " + newprops.endDate : '',
      }, ()=> {
        this.forceUpdate()
      });
    }
  }

  handleEvent = (event, picker) => {
    let FormatedStartDate = convertDateToFormat(picker.startDate._d);
    let FormatedEndDate = convertDateToFormat(picker.endDate._d);
    let StartEndDate = FormatedStartDate + " - " + FormatedEndDate;
    this.setState({
      CustStartDate: FormatedStartDate,
      CustEndDate: FormatedEndDate,
      DateString: StartEndDate,
    });
    this.props.onDateChange(FormatedStartDate, FormatedEndDate);
  }
  render() {
    return (
      <DateRangePicker
        startDate={this.state.CustStartDate}
        endDate={this.state.CustEndDate}
        onEvent={this.handleEvent}
        showDropdowns={true}
        className="w-100"
      >
         <TextField  
          variant="outlined"
          type="text"
          name="daterange"
          className="date-picker-range"
          placeholder="Select Date Range"
          value={this.state.DateString}
          InputProps={{
            className: "text-blue"               
          }}
         />
      </DateRangePicker>
    );
  }
}