export default {
  ClientDocumentCells: [
    { key: 'InvestorName', name: 'Investor' },
    { key: 'K1ReferenceName', name: 'Tax ID', type: 'Hidden' },
    { key: 'DocumentName', name: 'Master File Name' },
    { key: 'DocumentTypeName', name: 'Document Type Name' },
    { key: 'FileName', name: 'K-1 File Name' },
    { key: 'FiscalYear', name: 'Tax Year' },
    { key: 'LastProcessed', name: 'Last Processed' },
    { key: 'IsRevised', name: 'K-1 Status' },
    { key: 'ApproveStatus', name: 'Status' },
    { key: 'Action', name: 'Actions' }
  ],
  ClientInvestorUserDocumentCells: [
    { key: 'InvestorName', name: 'Investor' },
    { key: 'K1ReferenceName', name: 'Tax ID', type: 'Hidden' },
    { key: 'FileName', name: 'K-1 File Name' },
    { key: 'FiscalYear', name: 'Tax Year' },
    { key: 'LastProcessed', name: 'Last Processed' },
    { key: 'IsRevised', name: 'K-1 Status' },
    { key: 'Action', name: 'Actions' }
  ],
  DocumentTypeName: 'Document Type Name',
  LastProcessed: 'LastProcessed',
  FileName: 'FileName',
  K1ReferenceName: 'K1ReferenceName',
  FiscalYear: 'FiscalYear',
  Name: 'Name',
  DocumentName: 'DocumentName',
  ApproveSuccess: 'K-1 form has been approved for ',
  DeleteForSuccess: 'K-1 form has been deleted for ',
  DeleteSuccess: 'K-1 form has been deleted',
  RejectSuccess: 'K-1 form has been rejected for ',
  InvestorDeleteSuccess: ' is removed successfully from portal.',
  DeactivateSuccess: ' is deactivated successfully from portal.',
  ActivateSuccess: ' is activated successfully from portal.',
  Confirmation: 'Are you sure you want to make ',
  DeleteInvestorMessage: 'Are you sure you want to delete the investor',
  DeleteInvestorK1Message: 'Are you sure you want to remove the K-1 file for',
  DeleteK1Message: 'Are you sure you want to remove the K-1 file?',
  ApproveK1Message: 'Are you sure you want to approve the K-1 file for',
  RejectK1Message: 'Are you sure you want to reject the K-1 file for',
  ViewTaxId: 'View Entire Tax Id',
  HideTaxId: 'Hide Tax Id',
  Revised: 'Revised',
  Original: 'Original',
  UserUsageCell: [
    { key: 'UserEmail', name: 'Email' },
    { key: 'MONTHYEAR', name: 'Activity Date' },
    { key: 'NumberOfLogin', name: 'Number Of Login' }
  ]
}
