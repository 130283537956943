import React, { Component } from 'react'
import './app.less'
import '../styles/index.less'
import Layout from '../container/layout/layout'

class App extends Component {
  render () {
    return (
      <div className="app-container">
        <Layout></Layout>
      </div>
    )
  }
}

export default App
