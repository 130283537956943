export default {
  userName: 'User Name',
  password: 'Password',
  forgotPassword: 'Forgot Password?',
  loginInStreamDomain: 'Login as Stream Employees',
  loginIn: 'Login As External Investor/User',
  resetPassword: 'Reset Password',
  userEmail: 'User Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  investorName: 'Investment Name',
  submitRequest: 'Submit Request',
  name: 'Name',
  questionOrFeedback: 'Question/Feedback',
  submit: 'Submit',
  email: 'Email',
  confirmPassword: 'Confirm Password',
  back: 'Back',
  EmailError: 'Email is required',
  NameError: 'Name is required',
  QuestionOrFeedbackError: 'Question Or Feedback is required.',
  ValidEmailAddress: 'Please enter valid email address.'
}
